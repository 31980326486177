import { useGetLoyaltyByPeriod } from "api/loyalty/mappers/useGetLoyaltyByPeriod";
import { useGetMultipackByPeriod } from "api/multipack/mappers/useGetMultipackByPeriod";
import { useGetReceiptsByPeriod } from "api/receipts/mappers/useGetReceiptsByPeriod";
import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";
import { useGetSharesByPeriod } from "api/shares/mappers/useGetSharesByPeriod";

import { useBarChartTableMetrics } from "./metrics";
import { createBarChartTableHook } from "./utils";

export const useSellBarChartTable = createBarChartTableHook({
  reportType: "sell",
  hiddenLinesType: "other",
  useData: useGetSellsByPeriod,
  dataSelector: data => data?.data.data ?? [],
  useMetrics: () => useBarChartTableMetrics("sell")
});

export const useMultipackBarChartTable = createBarChartTableHook({
  reportType: "multipack",
  hiddenLinesType: "other",
  useData: useGetMultipackByPeriod,
  dataSelector: data => data?.data.data ?? [],
  useMetrics: () => useBarChartTableMetrics("multipack")
});

export const useSharesBarChartTable = createBarChartTableHook({
  reportType: "shares",
  hiddenLinesType: "other",
  useData: useGetSharesByPeriod,
  dataSelector: data => data?.currentPeriod ?? [],
  useMetrics: () => useBarChartTableMetrics("shares")
});

export const useReceiptsBarChartTable = createBarChartTableHook({
  reportType: "receipts",
  hiddenLinesType: "other",
  useData: useGetReceiptsByPeriod,
  dataSelector: data => data?.currentPeriod ?? [],
  useMetrics: () => useBarChartTableMetrics("receipts")
});

export const useLoyaltyBarChartTable = createBarChartTableHook({
  reportType: "loyalty",
  hiddenLinesType: "other",
  useData: useGetLoyaltyByPeriod,
  dataSelector: data => data?.currentPeriod ?? [],
  useMetrics: () => useBarChartTableMetrics("loyalty")
});
