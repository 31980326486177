import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { SummaryItem } from "api/types";
import { useActiveCompanyName } from "api/utils/useActiveCompanyName";
import { format } from "date-fns";

import { Heading, Text } from "components/atoms";
import { SummaryCard, SummarySwitch } from "components/molecules";
import { isHistoricalDataEnabledSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { IDS } from "components/organisms/Tour";
import {
  compareDateFromQPSelector,
  compareDateToQPSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { DATE_FNS_DOT_FORMAT, HEADER_TYPES } from "utils";
import { QP } from "utils/defaultQueryParams";

import {
  useSummaryClientTabs,
  useSummaryTabs,
  useSummaryWarning
} from "./hooks";
import s from "./reportSummary.module.scss";
import { SummaryColumn } from "./SummaryColumn";

interface Props {
  summary: SummaryItem[];
  isFetching: boolean;
}

const formatDate = (dateStr: string) => {
  return format(newDateByTimezone(dateStr), DATE_FNS_DOT_FORMAT);
};

const Divider = ({ bottom }: { bottom: number }) => {
  const isHistoricalDataEnabled = useSelector(isHistoricalDataEnabledSelector);
  const compareDateFromQP = useSelector(compareDateFromQPSelector);
  const compareDateToQP = useSelector(compareDateToQPSelector);

  const label = useMemo(() => {
    if (!isHistoricalDataEnabled || !compareDateFromQP || !compareDateToQP) {
      return "Poprzedni okres R/R";
    }
    const dates = {
      from: formatDate(String(compareDateFromQP)),
      to: formatDate(String(compareDateToQP))
    };
    return `Okres porównawczy (${dates.from} - ${dates.to})`;
  }, [compareDateFromQP, compareDateToQP, isHistoricalDataEnabled]);

  return (
    <div className={s.divider} style={{ bottom }}>
      <p className={s.dividerLabel}>{label}</p>
      <span className={s.dividerLine} />
    </div>
  );
};

export const ReportSummary: React.FC<Props> = ({ isFetching, summary }) => {
  const client = useSummaryClientTabs();
  const { primary, secondary } = useSummaryTabs();
  const warnings = useSummaryWarning();
  const activeCompany = useActiveCompanyName();

  return (
    <SummaryCard className={s.summaryCard} isLoading={isFetching}>
      <div className={s.headingWrapper} data-testid="report-summary-header">
        <Heading type={HEADER_TYPES.H4}>
          Podsumowanie - dane własne
          {activeCompany && ` (${activeCompany})`}
        </Heading>
        <div className={s.warningsWrapper}>
          {warnings
            ? warnings.map(text => (
                <Text
                  key={`warning-${text}`}
                  className={s.warning}
                  testId="summary-warning-text"
                >
                  {text}
                </Text>
              ))
            : null}
        </div>
      </div>
      <>
        {client.tabs.length > 0 && (
          <SummarySwitch
            buttonsId={IDS.SUMMARY_TRANSACTION}
            label="Rodzaj transakcji:"
            isSecondary={false}
            tabParam={QP.ZAPPKA_SUMMARY_TYPE}
            visibleTab={client.active}
            tabs={client.tabs}
          />
        )}
        {primary.tabs.length > 0 && (
          <SummarySwitch
            label="Pokaż:"
            isSecondary={client.tabs.length > 0}
            tabParam={primary.param}
            visibleTab={primary.active}
            tabs={primary.tabs}
          />
        )}
        {secondary.tabs.length > 0 && (
          <SummarySwitch
            isSecondary
            tabParam={secondary.param}
            visibleTab={secondary.active}
            tabs={secondary.tabs}
          />
        )}
        <div className={s.summaryContent} data-testid="report-summary-content">
          {summary.map((data, index) => (
            <SummaryColumn
              key={`summary-column-${index}`}
              label={data.label}
              tooltip={data.tooltipText}
              primary={{
                first: data.firstValue,
                second: data.secondValue,
                third: data.thirdValue,
                change: data.change
              }}
              secondary={{
                first: data.firstValueBottom ?? "",
                second: data.secondValueBottom ?? null,
                third: data.thirdValueBottom ?? "",
                change: data.changeBottom ?? 0
              }}
            />
          ))}
        </div>
        {summary.length > 0 && (
          <Divider bottom={summary[0]?.firstValueBottom ? 68 : 54} />
        )}
      </>
    </SummaryCard>
  );
};
