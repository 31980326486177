import { useMemo } from "react";
import { useSelector } from "react-redux";

import { sortBarChartData } from "api/utils";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { hiddenLinesSelector } from "pages/Reports/redux/reducers/chartReducer";
import { getLegendItemColor } from "pages/Reports/sections/utils/color";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { valueByDataType } from "pages/Reports/utils";
import { getIsPercentageType } from "pages/Reports/utils/formatTooltipValue";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { getPolishLabelForTooltip } from "utils";
import { isBarChartTableEnabled } from "utils/constants";

import { ReportType, useBarChartTableStore } from "./state";

type Type = "absolute" | "percentage";

export const createBarChartTableHook = <T>(config: {
  reportType: ReportType;
  hiddenLinesType: "other";
  useData: () => { data: T };
  dataSelector: (data: T) => any[];
  useMetrics: () => string[];
}) => {
  return () => {
    const isPowerUser = useSelector(isPowerUserSelector);
    const hiddenLines = useSelector(hiddenLinesSelector)[
      config.hiddenLinesType
    ];
    const [state] = useBarChartTableStore();
    const metrics = config.useMetrics();
    const { both: isBothDatasets } = useCurrentDataset();
    const { isLineChart } = useChartTypeBoolean();
    const contentNames = useLegendContentNames();
    const barChartSorting = useBarChartSorting();
    const { data } = config.useData();

    const tableData = useMemo(() => {
      if (
        !isBarChartTableEnabled ||
        !isPowerUser ||
        !isBothDatasets ||
        !isLineChart ||
        !data
      ) {
        return null;
      }

      const dataArray = config.dataSelector(data);
      if (!dataArray.length || dataArray[0].timeline.length !== 1) {
        return null;
      }

      const dataset = dataArray
        // apply color corresponding to the legend item
        .map((item, index) => {
          return {
            ...item,
            color: getLegendItemColor({
              vendorId: item.vendorId,
              resultId: item.resultId,
              index
            })
          };
        })
        // filter out metrics with no data
        .filter(data => {
          return state.metrics[config.reportType].some(dataType => {
            return (
              valueByDataType(dataType, data.timeline[0]) ||
              valueByDataType(`${dataType}_LAST_YEAR`, data.timeline[0])
            );
          });
        })
        // filter out hidden items
        .filter(({ vendorId, resultId, type }) => {
          const label = getChartLabel({ vendorId, resultId, type });
          const id = createLegendId(label, contentNames, type);
          return !hiddenLines.includes(id);
        })
        // sort according to the sidebar order
        .sort(
          sortBarChartData(barChartSorting.dataKey, barChartSorting.isDesc)
        );

      if (state.columns === "materials") {
        return {
          head: dataset.map(item => {
            return {
              label: item.resultId,
              color: item.color
            };
          }),
          body: state.metrics[config.reportType].map(dataType => {
            const isPercentage = getIsPercentageType(dataType);
            return {
              label: getPolishLabelForTooltip(dataType),
              values: dataset.map(item => {
                const changeKey = isPercentage
                  ? `${dataType}_CHANGE`
                  : `${dataType}_CHANGE_PERCENTAGE`;
                return {
                  base: valueByDataType(dataType, item.timeline[0]),
                  change: valueByDataType(changeKey, item.timeline[0]),
                  type: (isPercentage ? "percentage" : "absolute") as Type
                };
              }),
              color: undefined
            };
          })
        };
      }

      if (state.columns === "metrics") {
        return {
          head: state.metrics[config.reportType].map(dataType => {
            return {
              label: getPolishLabelForTooltip(dataType),
              color: undefined
            };
          }),
          body: dataset.map(item => {
            return {
              label: item.resultId,
              values: state.metrics[config.reportType].map(dataType => {
                const isPercentage = getIsPercentageType(dataType);
                const changeKey = isPercentage
                  ? `${dataType}_CHANGE`
                  : `${dataType}_CHANGE_PERCENTAGE`;
                return {
                  base: valueByDataType(dataType, item.timeline[0]),
                  change: valueByDataType(changeKey, item.timeline[0]),
                  type: (isPercentage ? "percentage" : "absolute") as Type
                };
              }),
              color: item.color
            };
          })
        };
      }

      return { head: [], body: [] };
    }, [
      barChartSorting.dataKey,
      barChartSorting.isDesc,
      contentNames,
      data,
      hiddenLines,
      isBothDatasets,
      isLineChart,
      isPowerUser,
      state.columns,
      state.metrics
    ]);

    return {
      reportType: config.reportType,
      metrics,
      tableData
    };
  };
};
