import { PROMOTION_BACKEND_TYPES } from "pages/Reports/redux/utils/transformPromotionApiData";
import { CHART_DATA_TYPE } from "utils/constants";

// this "prettier-ignore" is needed to keep map keys in a single line for better readability
// prettier-ignore
const keysMap = {
  [CHART_DATA_TYPE.VALUE]: "Wartość",
  [CHART_DATA_TYPE.VALUE_PER_SHOP]: "Wartość per sklep",
  [CHART_DATA_TYPE.TRANSACTIONS]: "Transakcje",
  [CHART_DATA_TYPE.QUANTITY]: "Sztuki",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP]: "Sztuki per sklep",
  [CHART_DATA_TYPE.SHOPS]: "Sklepy",
  [CHART_DATA_TYPE.AVG_PRICE]: "Średnia cena",
  [CHART_DATA_TYPE.VALUE_SHARES]: "Udziały wartościowo",
  [CHART_DATA_TYPE.QUANTITY_SHARES]: "Udziały ilościowo",
  [CHART_DATA_TYPE.VALUE_DYNAMICS]: "Dynamika wartości",
  [CHART_DATA_TYPE.QUANTITY_DYNAMICS]: "Dynamika sztuk",
  [CHART_DATA_TYPE.ITEMS_PER_RECEIPT]: "Średnia liczba szt. / 1 paragon",
  [CHART_DATA_TYPE.WORTH_PER_RECEIPT]: "Średnia wartość / 1 paragon",
  [CHART_DATA_TYPE.RECEIPTS_PER_SHOP]: "Średnia liczba paragonów / 1 sklep",
  [CHART_DATA_TYPE.RECEIPTS_QUANTITY]: "Liczba paragonów",
  [CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY]: "Udział w liczbie paragonów w wybranych kategoriach (%)",
  [CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP]: "Udział w liczbie paragonów total Żabka (%)",
  [CHART_DATA_TYPE.VALUE_PERCENT]: "% sprzedaży",
  [CHART_DATA_TYPE.QUANTITY_SUM]: "Ilość",
  [CHART_DATA_TYPE.QUANTITY_SUM_PERCENT]: "% ilościowy",
  [CHART_DATA_TYPE.CALENDAR_DATES]: "Dni kalendarzowe",
  [CHART_DATA_TYPE.WEEK_DATES]: "Dni tygodnia",
  [CHART_DATA_TYPE.CALENDAR_AND_WEEK_DATES]: "Dni tygodnia i kalendarzowe",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT]: "Liczba sklepów",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT]: "Liczba sklepów planogramowych",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE]: "Dystrybucja %",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE]: "Dystrybucja % planogramowa",

  [CHART_DATA_TYPE.TOTAL_SUPPLY]: "Zapas total",
  [CHART_DATA_TYPE.SHOPS_SUPPLY]: "Zapas sklepy",
  [CHART_DATA_TYPE.WPZ_SHOPS_7]: "WPZ Sklepy 7",
  [CHART_DATA_TYPE.INCOMING_SUPPLY]: "Zapas w drodze",
  [CHART_DATA_TYPE.CL_TOTAL_SUPPLY]: "Zapas CL",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_7]: "WPZ CL 7",
  [CHART_DATA_TYPE.BED_SUPPLY]: "Zapas BĘD",
  [CHART_DATA_TYPE.WPZ_BED_7]: "WPZ BĘD 7",
  [CHART_DATA_TYPE.NAD_SUPPLY]: "Zapas NAD",
  [CHART_DATA_TYPE.WPZ_NAD_7]: "WPZ NAD 7",
  [CHART_DATA_TYPE.PLE_SUPPLY]: "Zapas PLE",
  [CHART_DATA_TYPE.WPZ_PLE_7]: "WPZ PLE 7",
  [CHART_DATA_TYPE.SZA_SUPPLY]: "Zapas SZA",
  [CHART_DATA_TYPE.WPZ_SZA_7]: "WPZ SZA 7",
  [CHART_DATA_TYPE.TYCH_SUPPLY]: "Zapas TYCH",
  [CHART_DATA_TYPE.WPZ_TYCH_7]: "WPZ TYCH 7",
  [CHART_DATA_TYPE.TYN_SUPPLY]: "Zapas TYN",
  [CHART_DATA_TYPE.RADZ_SUPPLY]: "Zapas RADZ",
  [CHART_DATA_TYPE.KM_SUPPLY]: "Zapas KM",
  [CHART_DATA_TYPE.WPZ_TYN_7]: "WPZ TYN 7",
  [CHART_DATA_TYPE.WPZ_RADZ_7]: "WPZ RADZ 7",
  [CHART_DATA_TYPE.WPZ_KM_7]: "WPZ KM 7",
  [CHART_DATA_TYPE.WPZ_SHOPS_30]: "WPZ Sklepy 30",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_30]: "WPZ CL 30",
  [CHART_DATA_TYPE.WPZ_BED_30]: "WPZ BĘD 30",
  [CHART_DATA_TYPE.WPZ_NAD_30]: "WPZ NAD 30",
  [CHART_DATA_TYPE.WPZ_PLE_30]: "WPZ PLE 30",
  [CHART_DATA_TYPE.WPZ_SZA_30]: "WPZ SZA 30",
  [CHART_DATA_TYPE.WPZ_TYCH_30]: "WPZ TYCH 30",
  [CHART_DATA_TYPE.WPZ_TYN_30]: "WPZ TYN 30",
  [CHART_DATA_TYPE.WPZ_RADZ_30]: "WPZ RADZ 30",
  [CHART_DATA_TYPE.WPZ_KM_30]: "WPZ KM 30",
  [CHART_DATA_TYPE.WPZ_SHOPS_90]: "WPZ Sklepy 90",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_90]: "WPZ CL 90",
  [CHART_DATA_TYPE.WPZ_BED_90]: "WPZ BĘD 90",
  [CHART_DATA_TYPE.WPZ_NAD_90]: "WPZ NAD 90",
  [CHART_DATA_TYPE.WPZ_PLE_90]: "WPZ PLE 90",
  [CHART_DATA_TYPE.WPZ_SZA_90]: "WPZ SZA 90",
  [CHART_DATA_TYPE.WPZ_TYCH_90]: "WPZ TYCH 90",
  [CHART_DATA_TYPE.WPZ_TYN_90]: "WPZ TYN 90",
  [CHART_DATA_TYPE.WPZ_RADZ_90]: "WPZ RADZ 90",
  [CHART_DATA_TYPE.WPZ_KM_90]: "WPZ KM 90",
  [CHART_DATA_TYPE.KWRO_SUPPLY]: "Zapas KWRO",
  [CHART_DATA_TYPE.WPZ_KWRO_7]: "WPZ KWRO 7",
  [CHART_DATA_TYPE.WPZ_KWRO_30]: "WPZ KWRO 30",
  [CHART_DATA_TYPE.WPZ_KWRO_90]: "WPZ KWRO 90",

  [CHART_DATA_TYPE.NET_QUANTITY_SALES]: "Sprzedaż w jn",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP]: "Jn per sklep",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE]: "Udział w jn",
  [CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE]: "Dynamika w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_PER_RECEIPT]: "Średnia liczba jn na 1 par.",

  [CHART_DATA_TYPE.AVG_TEMP]: "Średnia temperatura",
  [CHART_DATA_TYPE.AVG_RAINFALL]: "Średnie opady (mm)",

  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT]: "Liczba nowych klientów",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT]: "% nowych klientów",
  [CHART_DATA_TYPE.ALL_RETURNS_COUNT]: "Liczba wszystkich powrotów",
  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT]: "% wszystkich powrotów",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT]: "% powrotów w 1. tyg.",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT]: "% powrotów w 2. tyg.",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT]: "% powrotów w 3-4 tyg.",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT]: "% powrotów w 5-6 tyg.",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT]: "% powrotów w 7-8 tyg.",

  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE]: "Liczba powrotów w 1. tyg.",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE]: "Liczba powrotów w 2. tyg.",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE]: "Liczba powrotów w 3-4 tyg.",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE]: "Liczba powrotów w 5-6 tyg.",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE]: "Liczba powrotów w 7-8 tyg.",

  [CHART_DATA_TYPE.VALUE_WS]: "Wartość WS",
  [CHART_DATA_TYPE.VALUE_WSO]: "Wartość WSO",
  [CHART_DATA_TYPE.VALUE_WSA]: "Wartość WSA",
  [CHART_DATA_TYPE.VALUE_WS_WSO]: "Wartość WS+WSO",
  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA]: "Wartość WS+WSO+WSA",
  [CHART_DATA_TYPE.QUANTITY_WS]: "Sztuki WS",
  [CHART_DATA_TYPE.QUANTITY_WSO]: "Sztuki WSO",
  [CHART_DATA_TYPE.QUANTITY_WSA]: "Sztuki WSA",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO]: "Sztuki WS+WSO",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA]: "Sztuki WS+WSO+WSA",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS]: "Wartość WS per sklep",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO]: "Wartość WSO per sklep",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA]: "Wartość WSA per sklep",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO]: "Wartość WS+WSO per sklep",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA]: "Wartość WS+WSO+WSA per sklep",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS]: "Sztuki WS per sklep",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO]: "Sztuki WSO per sklep",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA]: "Sztuki WSA per sklep",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO]: "Sztuki WS+WSO per sklep",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA]: "Sztuki WS+WSO+WSA per sklep",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS]: "Udział ilościowy WS w sprzedaży total",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO]: "Udział ilościowy WSO w sprzedaży total",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA]: "Udział ilościowy WSA w sprzedaży total",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO]: "Udział ilościowy WS+WSO w sprzedaży total",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA]: "Udział ilościowy WS+WSO+WSA w sprzedaży total",
  [CHART_DATA_TYPE.VALUE_SHARES_WS]: "Udział wartościowy WS w sprzedaży total",
  [CHART_DATA_TYPE.VALUE_SHARES_WSO]: "Udział wartościowy WSO w sprzedaży total",
  [CHART_DATA_TYPE.VALUE_SHARES_WSA]: "Udział wartościowy WSA w sprzedaży total",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO]: "Udział wartościowy WS+WSO w sprzedaży total",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA]: "Udział wartościowy WS+WSO+WSA w sprzedaży total",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS]: "Udział ilościowy WS w sprzedaży total w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO]: "Udział ilościowy WSO w sprzedaży total w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA]: "Udział ilościowy WSA w sprzedaży total w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO]: "Udział ilościowy WS+WSO w sprzedaży total w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA]: "Udział ilościowy WS+WSO+WSA w sprzedaży total w jn",
  [CHART_DATA_TYPE.PRICE_AVG_WS]: "Średnia cena WS",
  [CHART_DATA_TYPE.PRICE_AVG_WSO]: "Średnia cena WSO",
  [CHART_DATA_TYPE.PRICE_AVG_WSA]: "Średnia cena WSA",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO]: "Średnia cena WS+WSO",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA]: "Średnia cena WS+WSO+WSA",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS]: "Sprzedaż WS w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO]: "Sprzedaż WSO w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA]: "Sprzedaż WSA w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO]: "Sprzedaż WS+WSO w jn",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA]: "Sprzedaż WS+WSO+WSA w jn",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS]: "Jn WS per sklep",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO]: "Jn WSO per sklep",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA]: "Jn WSA per sklep",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO]: "Jn WS+WSO per sklep",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA]: "Jn WS+WSO+WSA per sklep",

  [CHART_DATA_TYPE.ZESTAWY]: PROMOTION_BACKEND_TYPES.ZESTAWY,
  [CHART_DATA_TYPE.PODKLADKA_ALKOHOLOWA]: PROMOTION_BACKEND_TYPES.PODKLADKA_ALKOHOLOWA,
  [CHART_DATA_TYPE.ODLICZANIE]: PROMOTION_BACKEND_TYPES.ODLICZANIE,
  [CHART_DATA_TYPE.ODLICZANIE_WSO]: PROMOTION_BACKEND_TYPES.ODLICZANIE_WSO,
  [CHART_DATA_TYPE.MENUBOARD]: PROMOTION_BACKEND_TYPES.MENUBOARD,
  [CHART_DATA_TYPE.KATALOG]: PROMOTION_BACKEND_TYPES.KATALOG,
  [CHART_DATA_TYPE.KATALOG_WSO]: PROMOTION_BACKEND_TYPES.KATALOG_WSO,
  [CHART_DATA_TYPE.HAPPY_HOURS]: PROMOTION_BACKEND_TYPES.HAPPY_HOURS,
  [CHART_DATA_TYPE.GAZETKA]: PROMOTION_BACKEND_TYPES.GAZETKA,
  [CHART_DATA_TYPE.GAZETKA_WSO]: PROMOTION_BACKEND_TYPES.GAZETKA_WSO,
  [CHART_DATA_TYPE.APLIKACJA]: PROMOTION_BACKEND_TYPES.APLIKACJA,
  [CHART_DATA_TYPE.AKCJA_POLECAMY]: PROMOTION_BACKEND_TYPES.AKCJA_POLECAMY,
  [CHART_DATA_TYPE.WIELOSZTUKA]: PROMOTION_BACKEND_TYPES.WIELOSZTUKA,
  [CHART_DATA_TYPE.WIELOSZTUKA_SUPER_OFERTA]: PROMOTION_BACKEND_TYPES.WIELOSZTUKA_SUPER_OFERTA,
  [CHART_DATA_TYPE.WIELOSZTUKA_APLIKACYJNA]: PROMOTION_BACKEND_TYPES.WIELOSZTUKA_APLIKACYJNA,
  [CHART_DATA_TYPE.APLIKACJA_WSO]: PROMOTION_BACKEND_TYPES.APLIKACJA_WSO,
  [CHART_DATA_TYPE.APLIKACJA_WSO_MULTIPACK]: PROMOTION_BACKEND_TYPES.APLIKACJA_WSO_MULTIPACK,
  [CHART_DATA_TYPE.ZESTAWY_APLIKACJA]: PROMOTION_BACKEND_TYPES.ZESTAWY_APLIKACJA,
  [CHART_DATA_TYPE.UNKNOWN_PROMOTION]: "Pozostałe"
};

export const getPolishLabelForChartHeader = (name: string): string => {
  if (!(name in keysMap)) return "";
  return keysMap[name];
};
