import { createSelector } from "reselect";

import { FILTERS } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { AppState } from "store";
import {
  isPowerUserSelector,
  tierSelector,
  userSelector
} from "store/reducers/userReducer";
import {
  aggregatedQueryParamSelector,
  chartPeriodSelector,
  countiesQueryParamSelector,
  pathnameSelector,
  productsQueryParamSelector
} from "store/selectors/routerSelectors";
import { toArray } from "store/utils";
import { isThisPage } from "utils";
import {
  APP_PATHS,
  INSIGHTS_ALLOWED_TIERS,
  PERIOD_TYPE,
  USER_ROLES
} from "utils/constants";
import { RESTRICTIONS } from "utils/restrictions";

const {
  SELL_PATH,
  SHARES_PATH,
  LOCATION_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  LOGISTICS_PATH,
  MULTIPACK_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  PROMOTIONS_PATH
} = REPORTS_FULL_PATHS;

const getIsRestricted = (warehouseId: string, pathname: string) => {
  const restrictions = RESTRICTIONS.find(({ id }) => id === warehouseId);
  if (!restrictions) {
    return false;
  }
  if (restrictions.enabled.paths.length) {
    return !restrictions.enabled.paths.includes(pathname);
  }
  if (restrictions.disabled.paths.length) {
    return restrictions.disabled.paths.includes(pathname);
  }
  return false;
};

export const reportsSelector = (state: AppState) => state.reports;

export const promotionsSelector = createSelector(
  reportsSelector,
  reports => reports.promotions
);

export const isPageAccessibleSelector = createSelector(
  pathnameSelector,
  tierSelector,
  userSelector,
  (pathname, tier, user) => {
    const isRestricted = getIsRestricted(
      user.company?.warehouseId ?? "",
      pathname
    );
    const isLogisticUser = user.role === USER_ROLES.LOGISTIC;

    switch (pathname) {
      case APP_PATHS.EXPORT:
        return !isRestricted && !isLogisticUser && [1, 2, 3, 4].includes(tier);

      case APP_PATHS.INSIGHTS:
        return (
          !isRestricted &&
          !isLogisticUser &&
          INSIGHTS_ALLOWED_TIERS.includes(tier)
        );

      default:
        return false;
    }
  }
);

export const isReportAccessibleSelector = createSelector(
  pathnameSelector,
  tierSelector,
  userSelector,
  (pathname, tier, user) => {
    const isRestricted = getIsRestricted(
      user.company?.warehouseId ?? "",
      pathname
    );
    const isLogisticUser = user.role === USER_ROLES.LOGISTIC;

    if (isThisPage(pathname, LOCATION_PATH)) {
      return !isRestricted && !isLogisticUser && [3, 4].includes(tier);
    }

    switch (pathname) {
      case SELL_PATH:
      case SEGMENTS_PATH:
        return !isRestricted && !isLogisticUser && [1, 2, 3, 4].includes(tier);

      case SHARES_PATH:
      case DYNAMICS_PATH:
        return !isRestricted && !isLogisticUser && [2, 3, 4].includes(tier);

      case LOGISTICS_PATH:
        return !isRestricted && [3, 4].includes(tier);

      case MULTIPACK_PATH:
      case RECEIPTS_PATH:
      case HOURS_PATH:
      case LOYALTY_PATH:
      case PROMOTIONS_PATH:
        return !isRestricted && !isLogisticUser && [3, 4].includes(tier);

      default:
        return false;
    }
  }
);

export const isDistributionDisabledSelector = createSelector(
  aggregatedQueryParamSelector,
  productsQueryParamSelector,
  countiesQueryParamSelector,
  (aggregatedQP, productsQP, countiesQP) => {
    const aggregated = aggregatedQP.toLowerCase() === AggregationTypes.total;
    const products = toArray(productsQP);
    const counties = toArray(countiesQP);

    // disable distribution in case data is aggregated and more than one product is selected
    if (aggregated && products.length > 1) return true;

    // disable distribution in case any of counties are selected
    if (counties.length > 0) return true;

    // disable distribution in case products are not selected
    if (!products.length) return true;

    return false;
  }
);

export const isCompaniesDropdownMultiChoiceSelector = createSelector(
  pathnameSelector,
  isPowerUserSelector,
  (pathname, isPowerUser) =>
    isThisPage(pathname, APP_PATHS.EXPORT) ? isPowerUser : false
);

export const hasWeatherDataSelector = createSelector(
  pathnameSelector,
  tierSelector,
  (pathname, tier) =>
    [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      SEGMENTS_PATH,
      HOURS_PATH
    ].includes(pathname) && [3, 4].includes(tier)
);

export const hasSidebarSelector = createSelector(pathnameSelector, pathname =>
  [
    SELL_PATH,
    MULTIPACK_PATH,
    LOCATION_PATH,
    SHARES_PATH,
    DYNAMICS_PATH,
    RECEIPTS_PATH,
    LOGISTICS_PATH,
    LOYALTY_PATH
  ].some(url => isThisPage(pathname, url))
);

export const hasNaturalUnitsSelector = createSelector(
  pathnameSelector,
  pathname =>
    [SELL_PATH, SHARES_PATH, DYNAMICS_PATH, RECEIPTS_PATH].some(url =>
      isThisPage(pathname, url)
    )
);

export const showMegaDropdownSelector = createSelector(
  pathnameSelector,
  pathname =>
    [
      SELL_PATH,
      MULTIPACK_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      SEGMENTS_PATH,
      LOYALTY_PATH,
      LOGISTICS_PATH
    ].some(url => isThisPage(pathname, url))
);

export const showAggregationDropdownSelector = createSelector(
  pathnameSelector,
  tierSelector,
  (pathname, tier) =>
    tier > 2 &&
    [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH
    ].some(url => isThisPage(pathname, url))
);

export const isClientTypeMultiChoiceSelector = createSelector(
  tierSelector,
  pathnameSelector,
  chartPeriodSelector,
  (tier, pathname, period) => {
    const tiers =
      FILTERS.find(filter => filter.name === "clientType")?.accessLevels || [];
    if (!tiers.includes(tier)) return false;

    // on sales enable for all periods (including ranking)
    if (isThisPage(pathname, REPORTS_FULL_PATHS.SELL_PATH)) {
      return true;
    }

    // on other reports enable only for non ranking periods
    return (
      period !== PERIOD_TYPE.RANKING &&
      [
        REPORTS_FULL_PATHS.SHARES_PATH,
        REPORTS_FULL_PATHS.DYNAMICS_PATH,
        REPORTS_FULL_PATHS.RECEIPTS_PATH,
        REPORTS_FULL_PATHS.SEGMENTS_PATH
      ].some(url => isThisPage(pathname, url))
    );
  }
);

export const isShowDatasetSelectEnabledSelector = createSelector(
  pathnameSelector,
  pathname => {
    return [
      REPORTS_FULL_PATHS.SELL_PATH,
      REPORTS_FULL_PATHS.MULTIPACK_PATH,
      REPORTS_FULL_PATHS.LOCATION_PATH,
      REPORTS_FULL_PATHS.SHARES_PATH,
      REPORTS_FULL_PATHS.RECEIPTS_PATH,
      REPORTS_FULL_PATHS.SEGMENTS_PATH,
      REPORTS_FULL_PATHS.LOYALTY_PATH
    ].some(url => isThisPage(pathname, url));
  }
);

export const isSidebarMetricEnabledSelector = createSelector(
  isPowerUserSelector,
  pathnameSelector,
  (isPowerUser, pathname) => {
    return isPowerUser
      ? [
          REPORTS_FULL_PATHS.SELL_PATH,
          REPORTS_FULL_PATHS.MULTIPACK_PATH,
          REPORTS_FULL_PATHS.LOCATION_PATH,
          REPORTS_FULL_PATHS.SHARES_PATH,
          REPORTS_FULL_PATHS.DYNAMICS_PATH,
          REPORTS_FULL_PATHS.RECEIPTS_PATH,
          REPORTS_FULL_PATHS.LOYALTY_PATH,
          REPORTS_FULL_PATHS.LOGISTICS_PATH
        ].some(url => isThisPage(pathname, url))
      : false;
  }
);
