import { useMemo } from "react";

import {
  addDays,
  differenceInDays,
  getISOWeek,
  getQuarter,
  getWeek,
  getYear,
  isSameMonth
} from "date-fns";

import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE, PROMO_WEEK_START_DAY } from "utils/constants";

type Ticks = {
  dates: Date[];
  items: { value: string; x: number; fill: string; weight: number }[];
};

const GRAY = "#888888";
const TICKS_LIMIT = 31;

function skipLabel(size: number, index: number, value: string) {
  return size > 24 ? (index % 3 === 0 ? value : "") : value; // show every third label to prevent overlapping
}

function getMonthTicks(period: string, dates: Date[], width: number) {
  const uniqueMonths: Date[] = [];
  let currentMonth: Date | null = null;
  for (const date of dates) {
    if (!currentMonth || !isSameMonth(currentMonth, date)) {
      currentMonth = date;
      uniqueMonths.push(date);
    }
  }

  if (
    period === PERIOD_TYPE.DAYS ||
    period === PERIOD_TYPE.WEEKS ||
    period === PERIOD_TYPE.PROMO_WEEKS
  ) {
    const isDayPeriod = period === PERIOD_TYPE.DAYS;
    const totalDays =
      differenceInDays(dates[dates.length - 1], dates[0]) +
      (isDayPeriod ? 1 : 0);
    const totalUnits = isDayPeriod ? totalDays : Math.ceil(totalDays / 7) + 1;
    const unitWidth = width / totalUnits;
    const monthsData = uniqueMonths.map(month => {
      const monthDates = dates.filter(date => isSameMonth(date, month));
      return {
        month,
        unitsCount: monthDates.length
      };
    });
    let currentPosition = 0;

    return {
      dates: monthsData.map(data => data.month),
      items: monthsData.map((data, index) => {
        const monthName = data.month.toLocaleString("pl-PL", {
          month: "short"
        });
        const position = currentPosition;
        const timepoint = data.unitsCount * unitWidth;
        currentPosition += timepoint;

        return {
          value: skipLabel(uniqueMonths.length, index, monthName),
          x: position + timepoint / 2,
          fill: GRAY,
          weight: 400
        };
      })
    };
  } else {
    // Default case - equal spacing
    const timepoint = width / uniqueMonths.length;

    return {
      dates: uniqueMonths,
      items: uniqueMonths.map((date, index) => {
        const monthName = date.toLocaleString("pl-PL", { month: "short" });
        return {
          value: skipLabel(uniqueMonths.length, index, monthName),
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        };
      })
    };
  }
}

function getMonthSubTicks(ticks: Ticks) {
  const months: { value: string; x: number }[] = [];
  let currentMonth: Date | null = null;

  for (const [index, date] of Object.entries(ticks.dates)) {
    const month = date.toLocaleString("pl-PL", { month: "short" });
    if (!currentMonth || !isSameMonth(currentMonth, date)) {
      currentMonth = date;
      months.push({
        value: month,
        x: ticks.items[Number(index)].x
      });
    }
  }

  return months;
}

function getYearSubTicks(ticks: Ticks) {
  const years: { value: string; x: number }[] = [];
  let currentYear: number | null = null;

  for (const [index, date] of Object.entries(ticks.dates)) {
    const year = getYear(date);
    if (currentYear !== year) {
      currentYear = year;
      years.push({
        value: String(year),
        x: ticks.items[Number(index)].x
      });
    }
  }

  return years;
}

export const BottomAxis = ({
  height,
  width,
  period,
  domain
}: {
  height: number;
  width: number;
  period: string;
  domain: string[];
}) => {
  const dates = domain.map(newDateByTimezone);

  const ticks = useMemo(() => {
    if (period === PERIOD_TYPE.DAYS) {
      if (dates.length > TICKS_LIMIT) {
        return getMonthTicks(period, dates, width);
      }

      const timepoint = width / dates.length;

      return {
        dates,
        items: dates.map((date, index) => {
          const isSunday = date.getDay() === 0;
          return {
            value: String(date.getDate()),
            x: timepoint * index + timepoint / 2,
            fill: isSunday ? "#fb4934" : GRAY,
            weight: isSunday ? 600 : 400
          };
        })
      };
    }

    if (period === PERIOD_TYPE.WEEKS || period === PERIOD_TYPE.PROMO_WEEKS) {
      const endOfWeekDates = dates.map(date => addDays(date, 6));

      if (endOfWeekDates.length > TICKS_LIMIT) {
        return getMonthTicks(period, endOfWeekDates, width); // for month ticks use end of week dates
      }

      // for week ticks use regular dates
      const weeks = dates.map(date => {
        return period === PERIOD_TYPE.PROMO_WEEKS
          ? getWeek(date, { weekStartsOn: PROMO_WEEK_START_DAY })
          : getISOWeek(date);
      });
      const timepoint = width / weeks.length;

      return {
        dates: endOfWeekDates, // for sub ticks use end of week dates
        items: weeks.map((week, index) => ({
          value: String(week),
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        }))
      };
    }

    if (period === PERIOD_TYPE.MONTHS) {
      return getMonthTicks(period, dates, width);
    }

    if (period === PERIOD_TYPE.QUARTER) {
      const quarters = dates.map(date => getQuarter(date));
      const timepoint = width / quarters.length;

      return {
        dates,
        items: quarters.map((quarter, index) => ({
          value: `Q${quarter}`,
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        }))
      };
    }

    if (period === PERIOD_TYPE.YEAR) {
      const years = dates.map(date => date.getFullYear());
      const timepoint = width / years.length;

      return {
        dates,
        items: years.map((year, index) => ({
          value: String(year),
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        }))
      };
    }

    if (period === PERIOD_TYPE.TOTAL) {
      return {
        dates,
        items: [{ value: "Total", x: width / 2, fill: GRAY, weight: 400 }]
      };
    }

    return { dates: [], items: [] };
  }, [dates, period, width]);

  const subTicks = useMemo(() => {
    let months: ReturnType<typeof getMonthSubTicks> = [];
    let years: ReturnType<typeof getYearSubTicks> = [];

    if (
      period === PERIOD_TYPE.DAYS ||
      period === PERIOD_TYPE.WEEKS ||
      period === PERIOD_TYPE.PROMO_WEEKS
    ) {
      if (dates.length <= TICKS_LIMIT) {
        months = getMonthSubTicks(ticks);
      }
      years = getYearSubTicks(ticks);
    } else if (
      period === PERIOD_TYPE.MONTHS ||
      period === PERIOD_TYPE.QUARTER
    ) {
      years = getYearSubTicks(ticks);
    }

    return { months, years };
  }, [dates.length, period, ticks]);

  return (
    <g transform={`translate(0, ${height})`}>
      {ticks.items.map((tick, index) => (
        <text
          key={`tick-${index}`}
          transform={`translate(${tick.x}, 20)`}
          fill={tick.fill}
          fontSize={12}
          fontWeight={tick.weight}
          textAnchor="middle"
        >
          {tick.value}
        </text>
      ))}
      {subTicks.months.map((tick, index) => (
        <text
          key={`month-tick-${index}`}
          transform={`translate(${tick.x}, 30)`}
          fill="#74859f"
          fontSize={12}
          textAnchor="middle"
        >
          {tick.value}
        </text>
      ))}
      {subTicks.years.map((tick, index) => {
        const y = subTicks.months.length > 0 ? 42 : 32;
        return (
          <text
            key={`year-tick-${index}`}
            transform={`translate(${tick.x}, ${y})`}
            fill="#515151"
            fontSize={12}
            fontWeight={600}
            textAnchor="middle"
          >
            {tick.value}
          </text>
        );
      })}
    </g>
  );
};
