import {
  Logistics,
  LogisticsDataApi
} from "pages/Reports/sections/Logistics/types";
import {
  ExistingLogisticsMetricsApi,
  LogisticsMetricsApi
} from "pages/Reports/types/metrics";
import { newDateByTimezone } from "store/utils";
import { TimelineApi } from "store/utils/types";
import { standarizeTimepointValue } from "utils";

const standarizeExistingMetrics = (metrics: ExistingLogisticsMetricsApi) => ({
  totalSupply: metrics?.total_supply || false,
  shopsSupply: metrics?.shops_supply || false,
  wpzShops7: metrics?.wpz_shops_7 || false,
  wpzShops30: metrics?.wpz_shops_30 || false,
  wpzShops90: metrics?.wpz_shops_90 || false,
  incomingSupply: metrics?.incoming_supply || false,
  clTotalSupply: metrics?.cl_total_supply || false,
  wpzClTotal7: metrics?.wpz_cl_total_7 || false,
  wpzClTotal30: metrics?.wpz_cl_total_30 || false,
  wpzClTotal90: metrics?.wpz_cl_total_90 || false,
  bedSupply: metrics?.bed_supply || false,
  wpzBed7: metrics?.wpz_bed_7 || false,
  wpzBed30: metrics?.wpz_bed_30 || false,
  wpzBed90: metrics?.wpz_bed_90 || false,
  nadSupply: metrics?.nad_supply || false,
  wpzNad7: metrics?.wpz_nad_7 || false,
  wpzNad30: metrics?.wpz_nad_30 || false,
  wpzNad90: metrics?.wpz_nad_90 || false,
  pleSupply: metrics?.ple_supply || false,
  wpzPle7: metrics?.wpz_ple_7 || false,
  wpzPle30: metrics?.wpz_ple_30 || false,
  wpzPle90: metrics?.wpz_ple_90 || false,
  szaSupply: metrics?.sza_supply || false,
  wpzSza7: metrics?.wpz_sza_7 || false,
  wpzSza30: metrics?.wpz_sza_30 || false,
  wpzSza90: metrics?.wpz_sza_90 || false,
  tychSupply: metrics?.tych_supply || false,
  wpzTych7: metrics?.wpz_tych_7 || false,
  wpzTych30: metrics?.wpz_tych_30 || false,
  wpzTych90: metrics?.wpz_tych_90 || false,
  tynSupply: metrics?.tyn_supply || false,
  wpzTyn7: metrics?.wpz_tyn_7 || false,
  wpzTyn30: metrics?.wpz_tyn_30 || false,
  wpzTyn90: metrics?.wpz_tyn_90 || false,
  radzSupply: metrics?.radz_supply || false,
  wpzRadz7: metrics?.wpz_radz_7 || false,
  wpzRadz30: metrics?.wpz_radz_30 || false,
  wpzRadz90: metrics?.wpz_radz_90 || false,
  kmSupply: metrics?.km_supply || false,
  wpzKm7: metrics?.wpz_km_7 || false,
  wpzKm30: metrics?.wpz_km_30 || false,
  wpzKm90: metrics?.wpz_km_90 || false,
  kwroSupply: metrics?.kwro_supply || false,
  wpzKwro7: metrics?.wpz_kwro_7 || false,
  wpzKwro30: metrics?.wpz_kwro_30 || false,
  wpzKwro90: metrics?.wpz_kwro_90 || false,
  avgShopsCount: metrics?.avg_shops_count || false,
  avgPlanogramShopsCount: metrics?.avg_planogram_shops_count || false
});

const standardizeTimeline = (timeline: TimelineApi<LogisticsMetricsApi>[]) =>
  timeline.map(timeline => ({
    date: timeline.date,
    totalSupply: standarizeTimepointValue(timeline.total_supply),
    shopsSupply: standarizeTimepointValue(timeline.shops_supply),
    wpzShops7: standarizeTimepointValue(timeline.wpz_shops_7),
    wpzShops30: standarizeTimepointValue(timeline.wpz_shops_30),
    wpzShops90: standarizeTimepointValue(timeline.wpz_shops_90),
    incomingSupply: standarizeTimepointValue(timeline.incoming_supply),
    clTotalSupply: standarizeTimepointValue(timeline.cl_total_supply),
    wpzClTotal7: standarizeTimepointValue(timeline.wpz_cl_total_7),
    wpzClTotal30: standarizeTimepointValue(timeline.wpz_cl_total_30),
    wpzClTotal90: standarizeTimepointValue(timeline.wpz_cl_total_90),
    bedSupply: standarizeTimepointValue(timeline.bed_supply),
    wpzBed7: standarizeTimepointValue(timeline.wpz_bed_7),
    wpzBed30: standarizeTimepointValue(timeline.wpz_bed_30),
    wpzBed90: standarizeTimepointValue(timeline.wpz_bed_90),
    nadSupply: standarizeTimepointValue(timeline.nad_supply),
    wpzNad7: standarizeTimepointValue(timeline.wpz_nad_7),
    wpzNad30: standarizeTimepointValue(timeline.wpz_nad_30),
    wpzNad90: standarizeTimepointValue(timeline.wpz_nad_90),
    pleSupply: standarizeTimepointValue(timeline.ple_supply),
    wpzPle7: standarizeTimepointValue(timeline.wpz_ple_7),
    wpzPle30: standarizeTimepointValue(timeline.wpz_ple_30),
    wpzPle90: standarizeTimepointValue(timeline.wpz_ple_90),
    szaSupply: standarizeTimepointValue(timeline.sza_supply),
    wpzSza7: standarizeTimepointValue(timeline.wpz_sza_7),
    wpzSza30: standarizeTimepointValue(timeline.wpz_sza_30),
    wpzSza90: standarizeTimepointValue(timeline.wpz_sza_90),
    tychSupply: standarizeTimepointValue(timeline.tych_supply),
    wpzTych7: standarizeTimepointValue(timeline.wpz_tych_7),
    wpzTych30: standarizeTimepointValue(timeline.wpz_tych_30),
    wpzTych90: standarizeTimepointValue(timeline.wpz_tych_90),
    tynSupply: standarizeTimepointValue(timeline.tyn_supply),
    wpzTyn7: standarizeTimepointValue(timeline.wpz_tyn_7),
    wpzTyn30: standarizeTimepointValue(timeline.wpz_tyn_30),
    wpzTyn90: standarizeTimepointValue(timeline.wpz_tyn_90),
    radzSupply: standarizeTimepointValue(timeline.radz_supply),
    wpzRadz7: standarizeTimepointValue(timeline.wpz_radz_7),
    wpzRadz30: standarizeTimepointValue(timeline.wpz_radz_30),
    wpzRadz90: standarizeTimepointValue(timeline.wpz_radz_90),
    kmSupply: standarizeTimepointValue(timeline.km_supply),
    wpzKm7: standarizeTimepointValue(timeline.wpz_km_7),
    wpzKm30: standarizeTimepointValue(timeline.wpz_km_30),
    wpzKm90: standarizeTimepointValue(timeline.wpz_km_90),
    kwroSupply: standarizeTimepointValue(timeline.kwro_supply),
    wpzKwro7: standarizeTimepointValue(timeline.wpz_kwro_7),
    wpzKwro30: standarizeTimepointValue(timeline.wpz_kwro_30),
    wpzKwro90: standarizeTimepointValue(timeline.wpz_kwro_90),
    avgShopsCount: standarizeTimepointValue(timeline.avg_shops_count),
    avgPlanogramShopsCount: standarizeTimepointValue(
      timeline.avg_planogram_shops_count
    )
  }));

export const normalizeLogisticsResponse = (
  data: LogisticsDataApi[]
): Logistics[] =>
  data.map(data => ({
    name: data.name,
    ean: data.ean,
    vendorId: data.vendor_id || "",
    segment: data.segment || "",
    existingMetrics: standarizeExistingMetrics(data.existing_metrics),
    timeline: standardizeTimeline(data.timeline).sort(
      (a, b) =>
        newDateByTimezone(a.date).getTime() -
        newDateByTimezone(b.date).getTime()
    )
  }));
