import { ChartDataTypes } from "pages/Reports/types/chart";
import { pickWs } from "pages/Reports/utils/pickWs";
import { createDataTypeMap } from "pages/Reports/utils/valueByType/createDataTypeMap";
import { CHART_DATA_TYPE } from "utils";

// this "prettier-ignore" is needed to keep map keys in a single line for better readability
// prettier-ignore
const keysMap = {
  ...createDataTypeMap(CHART_DATA_TYPE.VALUE, "worthSum", true),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY, "quantitySum", true),
  ...createDataTypeMap(CHART_DATA_TYPE.VALUE_PER_SHOP, "worthSumPerShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY_PER_SHOP, "quantitySumPerShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.AVG_PRICE, "priceAvg"),
  ...createDataTypeMap(CHART_DATA_TYPE.NET_QUANTITY_SALES, "netQuantitySales", true),
  ...createDataTypeMap(CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP, "avgNetQuantityPerShop"),

  ...createDataTypeMap(CHART_DATA_TYPE.VALUE_SHARES, "worthShare"),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY_SHARES, "quantityShare"),
  ...createDataTypeMap(CHART_DATA_TYPE.NET_QUANTITY_SHARE, "netQuantityShare"),

  ...createDataTypeMap(CHART_DATA_TYPE.VALUE_DYNAMICS, "worthChange"),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY_DYNAMICS, "quantityChange"),
  ...createDataTypeMap(CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE, "netQuantityChange"),

  ...createDataTypeMap(CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY, "shareInReceiptsCategory"),
  ...createDataTypeMap(CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP, "shareInReceiptsTotalShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.ITEMS_PER_RECEIPT, "itemsPerReceipt"),
  ...createDataTypeMap(CHART_DATA_TYPE.WORTH_PER_RECEIPT, "worthPerReceipt"),
  ...createDataTypeMap(CHART_DATA_TYPE.RECEIPTS_PER_SHOP, "receiptsPerShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.RECEIPTS_QUANTITY, "receiptsQuantity", true),
  ...createDataTypeMap(CHART_DATA_TYPE.NET_QUANTITY_PER_RECEIPT, "netQuantityPerReceipt"),

  [CHART_DATA_TYPE.AVG_SHOPS_COUNT]: "avgShopsCount",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT_CHANGE]: "avgShopsCountChange",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT_CHANGE_PERCENTAGE]: "avgShopsCountChangePercentage",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT_LAST_YEAR]: "avgShopsCountLastYear",

  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT]: "avgPlanogramShopsCount",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT_CHANGE]: "avgPlanogramShopsCountChange",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT_CHANGE_PERCENTAGE]: "avgPlanogramShopsCountChangePercentage",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT_LAST_YEAR]: "avgPlanogramShopsCountLastYear",

  [CHART_DATA_TYPE.DISTRIBUTION_RANGE]: "distributionRange",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE_CHANGE]: "distributionRangeChange",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE_CHANGE_PERCENTAGE]: "distributionRangeChangePercentage",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE_LAST_YEAR]: "distributionRangeLastYear",

  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE]: "planogramDistributionRange",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_CHANGE]: "planogramDistributionRangeChange",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_CHANGE_PERCENTAGE]: "planogramDistributionRangeChangePercentage",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_LAST_YEAR]: "planogramDistributionRangeLastYear",

  [CHART_DATA_TYPE.TOTAL_SUPPLY]: "totalSupply",
  [CHART_DATA_TYPE.SHOPS_SUPPLY]: "shopsSupply",
  [CHART_DATA_TYPE.WPZ_SHOPS_7]: "wpzShops7",
  [CHART_DATA_TYPE.INCOMING_SUPPLY]: "incomingSupply",
  [CHART_DATA_TYPE.CL_TOTAL_SUPPLY]: "clTotalSupply",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_7]: "wpzClTotal7",
  [CHART_DATA_TYPE.BED_SUPPLY]: "bedSupply",
  [CHART_DATA_TYPE.WPZ_BED_7]: "wpzBed7",
  [CHART_DATA_TYPE.NAD_SUPPLY]: "nadSupply",
  [CHART_DATA_TYPE.WPZ_NAD_7]: "wpzNad7",
  [CHART_DATA_TYPE.PLE_SUPPLY]: "pleSupply",
  [CHART_DATA_TYPE.WPZ_PLE_7]: "wpzPle7",
  [CHART_DATA_TYPE.SZA_SUPPLY]: "szaSupply",
  [CHART_DATA_TYPE.WPZ_SZA_7]: "wpzSza7",
  [CHART_DATA_TYPE.TYCH_SUPPLY]: "tychSupply",
  [CHART_DATA_TYPE.KM_SUPPLY]: "kmSupply",
  [CHART_DATA_TYPE.WPZ_TYCH_7]: "wpzTych7",
  [CHART_DATA_TYPE.TYN_SUPPLY]: "tynSupply",
  [CHART_DATA_TYPE.RADZ_SUPPLY]: "radzSupply",
  [CHART_DATA_TYPE.WPZ_TYN_7]: "wpzTyn7",
  [CHART_DATA_TYPE.WPZ_RADZ_7]: "wpzRadz7",
  [CHART_DATA_TYPE.WPZ_KM_7]: "wpzKm7",
  [CHART_DATA_TYPE.WPZ_SHOPS_30]: "wpzShops30",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_30]: "wpzClTotal30",
  [CHART_DATA_TYPE.WPZ_BED_30]: "wpzBed30",
  [CHART_DATA_TYPE.WPZ_NAD_30]: "wpzNad30",
  [CHART_DATA_TYPE.WPZ_PLE_30]: "wpzPle30",
  [CHART_DATA_TYPE.WPZ_SZA_30]: "wpzSza30",
  [CHART_DATA_TYPE.WPZ_TYCH_30]: "wpzTych30",
  [CHART_DATA_TYPE.WPZ_TYN_30]: "wpzTyn30",
  [CHART_DATA_TYPE.WPZ_RADZ_30]: "wpzRadz30",
  [CHART_DATA_TYPE.WPZ_KM_30]: "wpzKm30",
  [CHART_DATA_TYPE.WPZ_SHOPS_90]: "wpzShops90",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_90]: "wpzClTotal90",
  [CHART_DATA_TYPE.WPZ_BED_90]: "wpzBed90",
  [CHART_DATA_TYPE.WPZ_NAD_90]: "wpzNad90",
  [CHART_DATA_TYPE.WPZ_PLE_90]: "wpzPle90",
  [CHART_DATA_TYPE.WPZ_SZA_90]: "wpzSza90",
  [CHART_DATA_TYPE.WPZ_TYCH_90]: "wpzTych90",
  [CHART_DATA_TYPE.WPZ_TYN_90]: "wpzTyn90",
  [CHART_DATA_TYPE.WPZ_RADZ_90]: "wpzRadz90",
  [CHART_DATA_TYPE.WPZ_KM_90]: "wpzKm90",
  [CHART_DATA_TYPE.KWRO_SUPPLY]: "kwroSupply",
  [CHART_DATA_TYPE.WPZ_KWRO_7]: "wpzKwro7",
  [CHART_DATA_TYPE.WPZ_KWRO_30]: "wpzKwro30",
  [CHART_DATA_TYPE.WPZ_KWRO_90]: "wpzKwro90",

  [CHART_DATA_TYPE.AVG_TEMP]: "avgTemp",
  [CHART_DATA_TYPE.AVG_TEMP_LAST_YEAR]: "avgTempLastYear",

  [CHART_DATA_TYPE.AVG_RAINFALL]: "avgRainfall",
  [CHART_DATA_TYPE.AVG_RAINFALL_LAST_YEAR]: "avgRainfallLastYear",

  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT]: "loyaltyNewClientsCount",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT_LAST_YEAR]: "loyaltyNewClientsCountLastYear",

  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT]: "loyaltyNewClientsPercent",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT_LAST_YEAR]: "loyaltyNewClientsPercentLastYear",

  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT]: "loyaltyBackPercent",
  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT_LAST_YEAR]: "loyaltyBackPercentLastYear",

  [CHART_DATA_TYPE.ALL_RETURNS_COUNT]: "loyaltyBackCount",
  [CHART_DATA_TYPE.ALL_RETURNS_COUNT_LAST_YEAR]: "loyaltyBackCountLastYear",

  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT]: "loyaltyBackShare1w",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT_LAST_YEAR]: "loyaltyBackShare1wLastYear",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT]: "loyaltyBackShare2w",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare2wLastYear",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT]: "loyaltyBackShare4w",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare4wLastYear",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT]: "loyaltyBackShare6w",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare6wLastYear",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT]: "loyaltyBackShare8w",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare8wLastYear",

  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE]: "loyaltyBackAbsolute1w",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute1wLastYear",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute2w",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute2wLastYear",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute4w",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute4wLastYear",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute6w",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute6wLastYear",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute8w",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute8wLastYear",

  [CHART_DATA_TYPE.VALUE_WS]: pickWs("worthSumWs", "worthSumWsWsa"),
  [CHART_DATA_TYPE.VALUE_WS_LAST_YEAR]: pickWs("worthSumWsLastYear", "worthSumWsWsaLastYear"),

  [CHART_DATA_TYPE.VALUE_WSO]: "worthSumWso",
  [CHART_DATA_TYPE.VALUE_WSO_LAST_YEAR]: "worthSumWsoLastYear",

  [CHART_DATA_TYPE.VALUE_WSA]: "worthSumWsa",
  [CHART_DATA_TYPE.VALUE_WSA_LAST_YEAR]: "worthSumWsaLastYear",

  [CHART_DATA_TYPE.VALUE_WS_WSO]: "worthSumWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_WS_WSO_LAST_YEAR]: "worthSumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA]: "worthSumWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA_LAST_YEAR]: "worthSumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_WS]: pickWs("quantitySumWs", "quantitySumWsWsa"),
  [CHART_DATA_TYPE.QUANTITY_WS_LAST_YEAR]: pickWs("quantitySumWsLastYear", "quantitySumWsWsaLastYear"),

  [CHART_DATA_TYPE.QUANTITY_WSO]: "quantitySumWso",
  [CHART_DATA_TYPE.QUANTITY_WSO_LAST_YEAR]: "quantitySumWsoLastYear",

  [CHART_DATA_TYPE.QUANTITY_WSA]: "quantitySumWsa",
  [CHART_DATA_TYPE.QUANTITY_WSA_LAST_YEAR]: "quantitySumWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_WS_WSO]: "quantitySumWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_LAST_YEAR]: "quantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA]: "quantitySumWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA_LAST_YEAR]: "quantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS]: pickWs("worthSumPerShopWs", "worthSumPerShopWsWsa"),
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_LAST_YEAR]: pickWs("worthSumPerShopWsLastYear", "worthSumPerShopWsWsaLastYear"),

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO]: "worthSumPerShopWso",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO_LAST_YEAR]: "worthSumPerShopWsoLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA]: "worthSumPerShopWsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA_LAST_YEAR]: "worthSumPerShopWsaLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO]: "worthSumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_LAST_YEAR]: "worthSumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA]: "worthSumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA_LAST_YEAR]: "worthSumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS]: pickWs("quantitySumPerShopWs", "quantitySumPerShopWsWsa"),
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_LAST_YEAR]: pickWs("quantitySumPerShopWsLastYear", "quantitySumPerShopWsWsaLastYear"),

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO]: "quantitySumPerShopWso",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO_LAST_YEAR]: "quantitySumPerShopWsoLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA]: "quantitySumPerShopWsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA_LAST_YEAR]: "quantitySumPerShopWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO]: "quantitySumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR]: "quantitySumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA]: "quantitySumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR]: "quantitySumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WS]: pickWs("worthShareTotalWs", "worthShareTotalWsWsa"),
  [CHART_DATA_TYPE.VALUE_SHARES_WS_LAST_YEAR]: pickWs("worthShareTotalWsLastYear", "worthShareTotalWsWsaLastYear"),

  [CHART_DATA_TYPE.VALUE_SHARES_WSO]: "worthShareTotalWso",
  [CHART_DATA_TYPE.VALUE_SHARES_WSO_LAST_YEAR]: "worthShareTotalWsoLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WSA]: "worthShareTotalWsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WSA_LAST_YEAR]: "worthShareTotalWsaLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO]: "worthShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_LAST_YEAR]: "worthShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA]: "worthShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA_LAST_YEAR]: "worthShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS]: pickWs("quantityShareTotalWs", "quantityShareTotalWsWsa"),
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_LAST_YEAR]: pickWs("quantityShareTotalWsLastYear", "quantityShareTotalWsWsaLastYear"),

  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO]: "quantityShareTotalWso",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO_LAST_YEAR]: "quantityShareTotalWsoLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA]: "quantityShareTotalWsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA_LAST_YEAR]: "quantityShareTotalWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO]: "quantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_LAST_YEAR]: "quantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA]: "quantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA_LAST_YEAR]: "quantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS]: pickWs("netQuantityShareTotalWs", "netQuantityShareTotalWsWsa"),
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_LAST_YEAR]: pickWs("netQuantityShareTotalWsLastYear", "netQuantityShareTotalWsWsaLastYear"),

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO]: "netQuantityShareTotalWso",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO_LAST_YEAR]: "netQuantityShareTotalWsoLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA]: "netQuantityShareTotalWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA_LAST_YEAR]: "netQuantityShareTotalWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO]: "netQuantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_LAST_YEAR]: "netQuantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA]: "netQuantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA_LAST_YEAR]: "netQuantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WS]: pickWs("priceAvgWs", "priceAvgWsWsa"),
  [CHART_DATA_TYPE.PRICE_AVG_WS_LAST_YEAR]: pickWs("priceAvgWsLastYear", "priceAvgWsWsaLastYear"),

  [CHART_DATA_TYPE.PRICE_AVG_WSO]: "priceAvgWso",
  [CHART_DATA_TYPE.PRICE_AVG_WSO_LAST_YEAR]: "priceAvgWsoLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WSA]: "priceAvgWsa",
  [CHART_DATA_TYPE.PRICE_AVG_WSA_LAST_YEAR]: "priceAvgWsaLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO]: "priceAvgWsWsoWsa",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_LAST_YEAR]: "priceAvgWsWsoWsaLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA]: "priceAvgWsWsoWsa",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA_LAST_YEAR]: "priceAvgWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS]: pickWs("netQuantitySumWs", "netQuantitySumWsWsa"),
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_LAST_YEAR]: pickWs("netQuantitySumWsLastYear", "netQuantitySumWsWsaLastYear"),

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO]: "netQuantitySumWso",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO_LAST_YEAR]: "netQuantitySumWsoLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA]: "netQuantitySumWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA_LAST_YEAR]: "netQuantitySumWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO]: "netQuantitySumWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_LAST_YEAR]: "netQuantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA]: "netQuantitySumWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA_LAST_YEAR]: "netQuantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS]: pickWs("avgNetQuantityPerShopWs", "avgNetQuantityPerShopWsWsa"),
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_LAST_YEAR]: pickWs("avgNetQuantityPerShopWsLastYear", "avgNetQuantityPerShopWsWsaLastYear"),

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO]: "avgNetQuantityPerShopWso",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO_LAST_YEAR]: "avgNetQuantityPerShopWsoLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA]: "avgNetQuantityPerShopWsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA_LAST_YEAR]: "avgNetQuantityPerShopWsaLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO]: "avgNetQuantityPerShopWsWsoWsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR]: "avgNetQuantityPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA]: "avgNetQuantityPerShopWsWsoWsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR]: "avgNetQuantityPerShopWsWsoWsaLastYear"
};

export const getChartYKey = (dataType: ChartDataTypes) => {
  return dataType in keysMap ? keysMap[dataType] : null;
};
