import { Step } from "react-joyride";

import chartTilesGif from "assets/images/chart-tiles.gif";
import draggableTilesGif from "assets/images/draggable-tiles.gif";
import sidebarSelectingTourImg from "assets/images/sidebar-selecting-tour.png";

import { SUMMARY_SWITCH } from "pages/Reports/utils";
import { isMac, PERIOD_TYPE } from "utils";

// IDS
export const IDS = {
  BRAND_INPUT: "brand-input",
  REF_CAT_INPUT: "ref-cat-input",
  CLIENT_INPUT: "client-input",
  AGGREGATION_WRAPPER: "aggregation-wrapper",
  ARCHIVE_MENU: "export-archive-menu",
  ARCHIVE_BUTTON: "export-archive-button",
  EXPORT_STATUS_WRAPPER: "export-status-wrapper",
  FIRST_DATA_TYPE_DROPDOWN: "first-data-type-dropdown",
  SECOND_DATA_TYPE_DROPDOWN: "second-data-type-dropdown",
  HISTORICAL_CONTAINER: "historical-container",
  DYNAMIC_PERIOD_WRAPPER: "dynamic-period-wrapper",
  SHOW_HIERARCHY_BUTTON: "show-hierarchy-button",
  SIDEBAR_RESIZE_DOTS: "sidebar-resize-dots",
  CHART_TYPE_TOGGLE: "chart-type-toggle",
  LEGEND_TABS: "legend-tabs",
  GROUP_BY_SEGMENTS: "group-by-segments",
  OWN_PRODUCT_DROPDOWN: "own-product-dropdown",
  SUMMARY_TRANSACTION: "summary-transaction",
  TRANSACTION_SELECT: "transaction-select",
  SHARES_CHECKBOX: "shares-checkbox",
  DATASET_SELECT: "dataset-select",
  PREDEFINED_OPTIONS: "predefined-options",
  FULL_SCREEN_BUTTON: "full-screen-button",
  ATTRIBUTES_INPUT: "attributes-input"
};

const SIDEBAR_SORT_SELECTOR = "button[data-testid=item-sidebar-sort-button]";

// STEPS
export const NATUAL_UNITS_STEPS: Step[] = [
  {
    target: `button[data-testid=summary-tab-${SUMMARY_SWITCH.NATURAL_UNITS}]`,
    title: "Jednostki naturalne (1/2)",
    content: (
      <>
        Interesuje Cię sprzedaż w kilogramach lub litrach? Tutaj znajdziesz
        podsumowanie sprzedaży produktów własnych w przeliczeniu na jednostki
        naturalne.
      </>
    ),
    placement: "right",
    disableBeacon: true
  },
  {
    target: `div[id=${IDS.FIRST_DATA_TYPE_DROPDOWN}]`,
    title: "Jednostki naturalne (2/2)",
    content: (
      <>
        Możesz również wybrać do pokazania na wykresie miary dot. jednostek
        naturalnych
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const WEATHER_DATA_STEPS: Step[] = [
  {
    target: `div[id=${IDS.SECOND_DATA_TYPE_DROPDOWN}]`,
    title: "Czasem słońce, czasem deszcz",
    content: (
      <>
        <p>
          Za pomocą dwóch nowych miar w portalu - opady oraz temperatura -
          sprawdzisz jak bardzo wrażliwa jest sprzedaż produktów na warunki
          atmosferyczne.
        </p>
        <p>PS. Nie tylko dla miłośników Bollywood! 😉</p>
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const BRAND_STEPS: Step[] = [
  {
    target: `div[id=${IDS.BRAND_INPUT}]`,
    title: "Filtrowanie wg marek i submarek",
    content: (
      <>
        Dzięki nowym filtrom danych możesz łatwo porównywać całe linie produktów
        własnych i konkurencji bez konieczności ręcznego wyboru pojedynczych
        produktów.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const REF_CAT_STEPS: Step[] = [
  {
    target: `div[id=${IDS.REF_CAT_INPUT}]`,
    title: "Porównanie do kategorii",
    content: (
      <>
        Chcesz sprawdzić jak wygląda sprzedaż własna lub konkurencji na tle
        kategorii? Sprawdź nową opcję dot. zawartości wykresów.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const AGGREGATION_DROPDOWN_STEPS: Step[] = [
  {
    target: `div[id=${IDS.AGGREGATION_WRAPPER}]`,
    title: "Nowe możliwości agregacji danych",
    content: "Od teraz agreguj dane własne i konkurencji na 4 różne sposoby.",
    placement: "right",
    disableBeacon: true
  }
];

export const EXPORT_STEPS: Step[] = [
  {
    target: `ul[id=${IDS.ARCHIVE_MENU}]`,
    title: "Archiwizacja eksportów (1/2)",
    content: (
      <>
        Twoja lista eksportów jest długa jak lista zadań przed urlopem? 😉 Od
        teraz każdy eksport możesz przenieść do archiwum, żeby łatwiej odnaleźć
        interesujące Cię pliki.
      </>
    ),
    placement: "left",
    disableBeacon: true
  },
  {
    target: `button[id=${IDS.ARCHIVE_BUTTON}]`,
    title: "Archiwizacja eksportów (2/2)",
    content: (
      <>
        Tutaj znajdziesz swoje zarchiwizowane pliki danych. Jeżeli potrzebujesz
        wygenerować jeszcze raz eksport danych z archiwum, to użyj opcji "Kopiuj
        filtry" dostępnej w tabeli z listą plików.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const EXPORT_REDIRECT_STEPS: Step[] = [
  {
    target: `div[id=${IDS.EXPORT_STATUS_WRAPPER}]`,
    title: "Generowanie w toku",
    content: (
      <>
        <p>
          Trwa generowanie rozszerzonego pliku z danymi, który już za chwilę
          pojawi się na liście eksportów.
        </p>
        <p>
          Otrzymasz emaila z powiadomieniem, kiedy plik będzie gotowy do
          pobrania.
        </p>
        <p>
          W międzyczasie za pomocą filtrów możesz wygenerować kolejny zestaw
          danych. Obsługa jest prosta - wystarczy wybrać filtry oraz rodzaj
          danych i kliknąć "Generuj plik".
        </p>
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const RANKING_STEPS: Step[] = [
  {
    target: `button[data-testid=period-button-${PERIOD_TYPE.RANKING}]`,
    title: "Ranking",
    content: (
      <>
        Chcesz sprawdzić które miejsce zajmuje Twój produkt lub produkt
        konkurencji pod względem sprzedaży lub innego parametru? Sprawdź nowy
        rodzaj wykresu - "Ranking".
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const TOTAL_STEPS: Step[] = [
  {
    target: `button[data-testid=period-button-${PERIOD_TYPE.TOTAL}]`,
    title: 'Agregacja "Total"',
    content: (
      <>
        Nowa agregacja czasowa pozwoli na sprawdzenie wyników wybranych
        produktów / kategorii w całym zdefiniowanym przez Ciebie zakresie czasu.
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const PROMO_WEEKS_STEPS: Step[] = [
  {
    target: `button[data-testid=period-button-${PERIOD_TYPE.PROMO_WEEKS}]`,
    title: "Tygodnie promocyjne",
    content: (
      <>
        Chcesz w prosty sposób porównać sprzedaż przed, w trakcie i po
        zakończeniu promocji? Nowy typ agregacji czasowej -{" "}
        <strong>tygodnie promocyjne</strong> - ułatwi Ci to zadanie.
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const HISTORICAL_STEPS: Step[] = [
  {
    target: `div[id=${IDS.HISTORICAL_CONTAINER}]`,
    title: "Porównanie do danych historycznych",
    content: (
      <>
        Korzystając z tej opcji możesz wybrać dodatkowy okres sprzedaży o tej
        samej długości lub krótszy - dzięki temu od razu na jednym wykresie
        zobaczysz porównanie sprzedaży r/r, m/m lub dwóch różnych zakresów dat.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const EXCLUDED_DAYS_STEPS: Step[] = [
  {
    target: "div[data-testid=exclude-days-wrapper]",
    title: "Uwzględnij dni tygodnia",
    content: (
      <>
        Zarządzaj dniami, dla których chcesz wygenerować raport. Wybierz czy ma
        to być weekend, czy dni robocze! A może tylko jeden konkretny dzień?
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const DYNAMIC_PERIOD_STEPS: Step[] = [
  {
    target: `div[id=${IDS.DYNAMIC_PERIOD_WRAPPER}]`,
    title: "Dynamiczny zakres dat",
    content: (
      <>
        <p>
          Przy wyborze jednej z powyższych możliwości ustawień domyślnych
          zaznacz tę opcję jeśli po powrocie do linku z raportem będziesz chciał
          zobaczyć zaktualizowane daty do dnia, w którym do niego powrócisz.
          Jeśli za jakiś czas będziesz chciał zobaczyć dokładnie taki sam zakres
          dat jak dziś, to odznacz tę opcję.
        </p>
        <p>
          Dzięki temu możesz zapisywać linki do raportów z najczęściej
          wykorzystywanymi filtrami, a daty będą się automatycznie aktualizować,
          co ułatwi korzystanie z nich jeszcze bardziej!
        </p>
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const PRODUCT_HIERARCHY_STEPS: Step[] = [
  {
    target: `button[data-testid=${IDS.SHOW_HIERARCHY_BUTTON}]`,
    title: "Hierarchia produktów",
    content: (
      <>
        Tutaj znajdziesz informacje o hierarchii produktowej w Żabce
        (przyporządkowaniu produktów do poszczególnych kategorii G1 / G2 / G3)
        oraz przypisanych markach i submarkach do poszczególnych SKU.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const EXPANDABLE_SIDEBAR_STEPS: Step[] = [
  {
    target: `svg[id=${IDS.SIDEBAR_RESIZE_DOTS}]`,
    title: "Rozszerzanie panelu bocznego",
    content: (
      <>
        Od teraz możesz decydować o szerokości panelu bocznego - złap myszką za
        lewą krawędź panelu bocznego i rozciągnij na tyle ile potrzebujesz.
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const SORTABLE_SIDEBAR_STEPS: Step[] = [
  {
    target: SIDEBAR_SORT_SELECTOR,
    title: "Sortuj dane w panelu bocznym według własnych upodobań",
    content: (
      <>
        Zdecyduj czy dane mają zostać posortowane alfabetycznie, wg okresu
        bazowego lub historycznego, malejąco czy też rosnąco… jak tylko dusza
        zapragnie!
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const SELECTING_SIDEBAR_STEPS: Step[] = [
  {
    target: SIDEBAR_SORT_SELECTOR,
    title: "Zarządzaj wykresem w panelu bocznym",
    content: (
      <>
        <p>
          Klikaj w poszczególne pozycje panelu bocznego i tak jak za pomocą
          legendy wyłączaj oraz włączaj je na wykresie. Jeżeli chcesz wybrać
          wiele elementów jednocześnie, przytrzymaj przycisk Shift i kliknij
          pierwszy oraz ostatni element (analogicznie jak w Excelu).
        </p>
        <p>
          Zaznacz checkbox w okienku sortowania, jeśli chcesz, aby odznaczone
          produkty automatycznie były przesuwane na sam dół, a zaznaczone
          wracały na górę panelu bocznego.
        </p>
        <img src={sidebarSelectingTourImg} alt="" />
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const SIDEBAR_CHANGE_STEPS: Step[] = [
  {
    target: SIDEBAR_SORT_SELECTOR,
    title: "Sprawdź dynamikę w panelu bocznym",
    content: (
      <>
        W panelu bocznym pojawiła się nowa kolumna, w której można sprawdzić
        dynamikę wyrażoną jako wartość bezwzględna i procentowa dla wybranych
        danych bazowych i historycznych bez konieczności ich przeliczania. Dane
        można również posortować wg dynamiki rosnącej lub malejącej.
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const CHART_TYPE_STEPS: Step[] = [
  {
    target: `ul[id=${IDS.CHART_TYPE_TOGGLE}]`,
    title: "Nowe typy wykresów",
    content: (
      <>
        <p>
          Przełączaj ikonki i decyduj o sposobie prezentowania danych na jednym
          z kilku typów wykresów!
        </p>
        <p>
          Przeglądaj dane w standardowej formie na{" "}
          <strong>Wykresie liniowym</strong> lub skorzystaj z innych opcji:
        </p>
        <p>
          <strong>Wykresu Pasmowego</strong> - aby porównać dwa zakresy czasu
          dla jednego produktu/kategorii,
        </p>
        <p>
          <strong>Wykresu Kolumnowego Grupowanego</strong> - aby porównać wiele
          produktów/kategorii jednocześnie,
        </p>
        <p>
          <strong>Wykresu Przyrostowego & Combo</strong> - aby sprawdzić wzrost
          lub spadek dla jednego produktu/kategorii rozłożone w czasie.
        </p>
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const AREA_CHART_STEPS: Step[] = [
  {
    target: `div[data-testid=${IDS.LEGEND_TABS}]`,
    title: "Wykres pasmowy",
    content: (
      <>
        Wykres pasmowy jest prezentowany dla jednej pozycji, aby zaoszczędzić
        trochę Twojego czasu wybraliśmy pierwszą pozycję z legendy, wybierz
        dowolną, dla której chcesz wygenerować wykres.
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const PRODUCT_DROPDOWN_SORTING_CHECKBOX_STEPS: Step[] = [
  {
    target: `div[data-testid=${IDS.OWN_PRODUCT_DROPDOWN}]`,
    title: "Nowe sortowanie produktów",
    content: (
      <>
        <p>
          Chcesz zobaczyć na liście w pierwszej kolejności produkty aktywne?
        </p>
        <p>Zaznacz checkbox "Przesuń produkty nieaktywne na dół" i gotowe!</p>
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const INCREMENTAL_CHART_STEPS: Step[] = [
  {
    target: `div[data-testid=${IDS.LEGEND_TABS}]`,
    title: "Rodzaje wykresów",
    content: (
      <>
        Wykres przyrostowy jest prezentowany dla jednej pozycji, aby
        zaoszczędzić trochę Twojego czasu wybraliśmy pierwszą pozycję z legendy,
        wybierz dowolną, dla której chcesz wygenerować wykres.
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const INSIGHTS_NAV_STEPS: Step[] = [
  {
    target: "a[data-testid=main-navbar-item-Insights]",
    title: "Oszczędzaj czas podczas analizy danych!",
    content: (
      <>
        Podpowiemy Tobie, na co warto zwrócić uwagę podczas przeglądania danych
        w ACIT, żebyś mógł się skupić na tworzeniu wartościowych insightów
        sprzedażowych!
      </>
    ),
    placement: "bottom",
    disableBeacon: true
  }
];

export const EXPORT_NAV_STEPS: Step[] = [
  {
    target: 'a[data-testid="main-navbar-item-Eksport danych"]',
    title: "Nowe miejsce zakładki Eksport danych",
    content: (
      <>
        Zastanawiasz się dokąd uciekła zakładka "Eksport danych"? Wskoczyła
        wyżej, aby zrobić miejsce dla kolejnych raportów! 🙂
      </>
    ),
    placement: "bottom",
    disableBeacon: true
  }
];

export const SEGMENTS_GROUP_BY_STEPS: Step[] = [
  {
    target: `div[id=${IDS.GROUP_BY_SEGMENTS}]`,
    title: "Wykres per Produkt czy per Segment?",
    content: (
      <>
        <p>
          Przełączaj się pomiędzy dwoma opcjami i oglądaj dane na wykresie w
          najodpowiedniejszej dla siebie formie!
        </p>
        <p>
          Opcja <strong>wykres per produkt</strong> to dotychczasowa forma
          prezentacji wykresów oddzielnie dla każdego produktu.
        </p>
        <p>
          Opcja <strong>wykres per segment</strong> to nowa forma prezentacji
          wykresów oddzielnie dla każdego segmentu.
        </p>
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const MULTIPACK_METRICS_STEPS: Step[] = [
  {
    target: `div[id=${IDS.FIRST_DATA_TYPE_DROPDOWN}]`,
    title: "Miary total sprzedaży ogólnej",
    content: (
      <>
        <p>Chcesz porównywać sprzedaż całkowitą ze sprzedażą wielosztuk?</p>
        <p>
          Od teraz jest taka możliwość - wystarczy wybrać odpowiednie miary i
          wszystko pokaże się na jednym wykresie.
        </p>
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const HOURS_QUANTITY_METRICS_STEPS: Step[] = [
  {
    target: `div[id=${IDS.FIRST_DATA_TYPE_DROPDOWN}]`,
    title: "Nowe miary",
    content: (
      <>
        Sprawdzaj nie tylko wartość i % sprzedaży, ale również ilość oraz %
        ilościowy sprzedaży swoich produktów w danym czasie!
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const LOGISTICS_SEGMENTS_STEPS: Step[] = [
  {
    target: "button[data-testid=shop-type-filters-section-toggler]",
    title: "Podział danych na sklepy w Logistyce",
    content: (
      <>
        Wybierz Segmenty, dla których chcesz zobaczyć dane logistyczne per
        konkretny dzień.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const LOYALTY_METRICS_STEPS: Step[] = [
  {
    target: `div[id=${IDS.FIRST_DATA_TYPE_DROPDOWN}]`,
    title: "Nowe miary w raporcie Lojalność",
    content: (
      <>
        Od teraz sprawdzisz nie tylko procent klientów dokonujących powtórnego
        zakupu danego produktu w określonym czasie, ale również poznasz ich
        dokładną ilość.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const CHART_TILES_STEPS: Step[] = [
  {
    target: `ul[id=${IDS.CHART_TYPE_TOGGLE}] li:first-child`,
    title: "Etykiety na wykresie",
    content: (
      <>
        <p>Oznaczaj poszczególne punkty w czasie na wykresach.</p>
        <p>
          {`Przytrzymaj ${
            isMac() ? "option" : "ctrl"
          } i kliknij lewym przyciskiem myszki w punkt, gdzie
          chcesz oznaczyć etykietę.`}
        </p>
        <p>
          {`Porównując dane dla okresu historycznego możesz automatycznie włączyć
          etykiety dla okresu bazowego i historycznego przytrzymując ${
            isMac() ? "option" : "ctrl"
          } + shift
          i klikając na wykres.`}
        </p>
        <p>
          Wyświetlaj i ukrywaj etykiety poprzez wybór jednej z opcji dostępnych
          w poniższej liście rozwijanej.
        </p>
        <img src={chartTilesGif} alt="" loading="lazy" />
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const DRAGGABLE_TILES_STEPS: Step[] = [
  {
    target: `ul[id=${IDS.CHART_TYPE_TOGGLE}] li:first-child`,
    title: "Ruchome etykiety",
    content: (
      <>
        <p>
          Za pomocą kursora zmień położenie etykiet tak, aby dane prezentowane
          na wykresie były jeszcze bardziej czytelne.
        </p>
        <img src={draggableTilesGif} alt="" loading="lazy" />
      </>
    ),
    placement: "left",
    disableBeacon: true
  }
];

export const CLIENT_ZAPPKA_FILTER_STEPS: Step[] = [
  {
    target: `div[id=${IDS.CLIENT_INPUT}]`,
    title: "Możliwość wyboru 2 rodzajów transakcji",
    content: (
      <>
        Wybierz 2 z 3 dostępnych rodzajów transakcji, aby móc porównać dla nich
        sprzedaż na jednym wykresie i w podsumowaniu.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const ZAPPKA_TRANSACTIONS_STEPS: Step[] = [
  {
    target: `div[id=${IDS.SUMMARY_TRANSACTION}]`,
    title: "Sprawdzaj dane dla wybranych rodzajów transakcji",
    content: (
      <>
        Przełączaj się między zakładkami w Podsumowaniu, aby porównać dane dla 2
        wybranych w filtrach rodzajów transakcji.
      </>
    ),
    placement: "right",
    disableBeacon: true,
    disableScrollParentFix: false
  },
  {
    target: `div[id=${IDS.TRANSACTION_SELECT}]`,
    title: "Porównuj sprzedaż dla 2 wybranych rodzajów transakcji",
    content: (
      <>
        <p>
          Wybierz, dla którego z wybranych rodzajów transakcji chcesz zobaczyć
          dane na wykresie i w panelu bocznym.
        </p>
        <p>
          Zarządzaj nimi poprzez wybór jednej z opcji nad wykresem "Pokaż dane
          dla transakcji".
        </p>
        <p>
          Bezpośrednio pod wykresem znajdziesz dodatkowy opis poszczególnych
          linii.
        </p>
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const ZAPPKA_SHARES_CHECKBOX_STEPS: Step[] = [
  {
    target: `div[id=${IDS.SHARES_CHECKBOX}]`,
    title: "Pokaż dane jako udział aplikacji w totalu",
    content: (
      <>
        Zaznaczając tę opcję zobaczysz na wykresie wybrane miary jako udział
        transakcji z aplikacją Żappka w sprzedaży całkowitej.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const DATASET_SELECT_STEPS: Step[] = [
  {
    target: `div[id=${IDS.DATASET_SELECT}]`,
    title: "Zarządzaj okresem wyświetlanym na wykresie",
    content: (
      <>
        Decyduj czy chcesz zobaczyć dane dla okresu bazowego, historycznego czy
        obydwu naraz.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const PREDEFINED_OPTIONS_STEPS: Step[] = [
  {
    target: `div[id=${IDS.PREDEFINED_OPTIONS}]`,
    title: "Predefiniowane okresy historyczne",
    content: (
      <>
        Wybierz jedną z dostępnych opcji, jeśli chcesz porównać się do 2, 4
        tygodni lub analogicznej długości czasu bezpośrednio przed okresem
        bazowym. Możesz także wyrównać dni tygodnia między okresami.
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];

export const FULL_SCREEN_STEPS: Step[] = [
  {
    target: `button[id=${IDS.FULL_SCREEN_BUTTON}]`,
    title: "Wykres na pełnym ekranie",
    content: (
      <>
        Użyj przycisku "Włącz tryb pełnoekranowy" jeśli chcesz zobaczyć wykres
        na całym swoim ekranie.
      </>
    ),
    placement: "bottom",
    disableBeacon: true
  }
];

export const ATTRIBUTES_STEPS: Step[] = [
  {
    target: `div[id=${IDS.ATTRIBUTES_INPUT}]`,
    title: "Atrybuty - ocechowanie produktu",
    content: (
      <>
        <p>
          Skorzystaj z dodatkowej możliwości filtrowania, aby dokonywać jeszcze
          lepszych i dokładniejszych analiz, Możesz wygenerować raport zgodnie z
          wybranymi atrybutami lub odfiltrować produkty mające przypisane
          wybrane wartości atrybutów.
        </p>
        <p>
          Hierarchię przypisań atrybutów do produktu sprawdzisz klikając w
          "Pokaż atrybuty".
        </p>
        <p>
          Dodatkowo możesz dane zagregować wg. atrybutów total lub z podziałem
          na kategorie.
        </p>
      </>
    ),
    placement: "right",
    disableBeacon: true
  }
];
