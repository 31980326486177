import {
  endOfWeek,
  format,
  getDay,
  getISOWeek,
  getMonth,
  getQuarter,
  getWeek,
  getYear
} from "date-fns";

import {
  capitalize,
  DATE_FNS_DOT_FORMAT,
  getPolishMonthName,
  getPolishWeekDayName,
  PERIOD_TYPE
} from "utils";
import { PROMO_WEEK_START_DAY } from "utils/constants";
import { Periods } from "utils/types";

const getWeekEndDate = (regularDate: Date, isPromoWeek: boolean = false) => {
  const endOfWeekDate = endOfWeek(regularDate, {
    weekStartsOn: isPromoWeek ? PROMO_WEEK_START_DAY : 1
  });

  return format(endOfWeekDate, DATE_FNS_DOT_FORMAT);
};

export const getPeriodValue = (
  date: Date,
  period: Periods,
  isTable?: boolean
) => {
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return (
        format(date, DATE_FNS_DOT_FORMAT) +
        (isTable ? "" : ` (${getPolishWeekDayName(getDay(date), true)})`)
      );

    case PERIOD_TYPE.WEEKS:
      const weekNumber = getISOWeek(date);

      const startWeekDate = format(date, DATE_FNS_DOT_FORMAT);
      const endWeekDate = getWeekEndDate(date);

      return `${startWeekDate} - ${endWeekDate} (tydz. ${weekNumber})`;

    case PERIOD_TYPE.PROMO_WEEKS:
      const promoWeekNumber = getWeek(date, {
        weekStartsOn: PROMO_WEEK_START_DAY
      });

      const startPromoWeekDate = format(date, DATE_FNS_DOT_FORMAT);
      const endPromoWeekDate = getWeekEndDate(date, true);

      return `${startPromoWeekDate} - ${endPromoWeekDate} (tydz. ${promoWeekNumber})`;

    case PERIOD_TYPE.MONTHS:
      return `${capitalize(getPolishMonthName(getMonth(date)))} ${getYear(
        date
      )}`;

    case PERIOD_TYPE.QUARTER:
      return `Q${getQuarter(date)} ${getYear(date)}`;

    case PERIOD_TYPE.YEAR:
      return String(getYear(date));

    case PERIOD_TYPE.TOTAL:
      return "Total";

    default:
      return null;
  }
};

export const getHoursPeriodValue = (
  date: Date,
  period: Periods
): string[] | null => {
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return [
        format(date, "yyyy"),
        format(date, "MM.dd"),
        `(${getPolishWeekDayName(getDay(date), true)})`
      ];

    case PERIOD_TYPE.WEEKS:
      const weekNumber = getISOWeek(date);

      const startWeekDate = format(date, DATE_FNS_DOT_FORMAT);
      const endWeekDate = getWeekEndDate(date);

      return [`${startWeekDate} ${endWeekDate} (tydz. ${weekNumber})`];

    case PERIOD_TYPE.PROMO_WEEKS:
      const promoWeekNumber = getWeek(date, {
        weekStartsOn: PROMO_WEEK_START_DAY
      });

      const startPromoWeekDate = format(date, DATE_FNS_DOT_FORMAT);
      const endPromoWeekDate = getWeekEndDate(date, true);

      return [
        `${startPromoWeekDate} ${endPromoWeekDate} (tydz. ${promoWeekNumber})`
      ];

    case PERIOD_TYPE.MONTHS:
      return [
        format(date, "yyyy"),
        `${capitalize(getPolishMonthName(getMonth(date)))}`
      ];
    case PERIOD_TYPE.YEAR:
    case PERIOD_TYPE.TOTAL:
      const value = getPeriodValue(date, period);
      return value === null ? null : [value];

    default:
      return null;
  }
};
