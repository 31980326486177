import { Action, createHook, createStore } from "react-sweet-state";

export type ReportType =
  | "sell"
  | "multipack"
  | "shares"
  | "receipts"
  | "loyalty";

type State = {
  columns: "materials" | "metrics";
  metrics: Record<ReportType, string[]>;
};

type Actions = typeof actions;

const toggleValue = (value: string, array: string[]) => {
  return array.includes(value)
    ? array.filter(selectedValue => selectedValue !== value)
    : [...array, value];
};

const actions = {
  toggleColumns: (): Action<State> => ({ getState, setState }) => {
    setState({
      columns: getState().columns === "materials" ? "metrics" : "materials"
    });
  },
  toggleSellMetric: (reportType: ReportType, value: string): Action<State> => ({
    getState,
    setState
  }) => {
    const { metrics } = getState();
    setState({
      metrics: {
        ...metrics,
        [reportType]: toggleValue(value, metrics[reportType])
      }
    });
  },
  clearSellMetrics: (reportType: ReportType): Action<State> => ({
    getState,
    setState
  }) => {
    setState({
      metrics: {
        ...getState().metrics,
        [reportType]: []
      }
    });
  }
};

const BarChartTableStore = createStore<State, Actions>({
  name: "barChartTable",
  initialState: {
    columns: "materials",
    metrics: {
      sell: [],
      multipack: [],
      shares: [],
      receipts: [],
      loyalty: []
    }
  },
  actions
});

export const useBarChartTableStore = createHook(BarChartTableStore);
