import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  addDays,
  differenceInCalendarDays,
  differenceInDays,
  differenceInMonths,
  endOfMonth,
  format,
  getDay,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  subDays
} from "date-fns";

import { useCategoryDateRange } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersSelectors";
import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { DATE_FNS_DOT_FORMAT, DEFAULT_DATE_FORMAT } from "utils";
import { Nullable } from "utils/types";

import { isHistoricalDataEnabledSelector } from "./selectors";
import {
  checkLeapYear,
  declenseDays,
  declenseMonths,
  getInitialDates,
  getInitialLeapYear,
  getYearBack,
  LeapYearStatus
} from "./utils";

export const useHistorical = (isMonthCalendar: boolean) => {
  const params = useSelector(queryParamsSelector);
  const [
    {
      calendar,
      selectedHistorical: comparePeriod,
      selectedHistoricalOpen,
      includeLeapYear
    }
  ] = CalendarHooks.useCalendar();
  const basePeriod = useMemo(() => {
    return {
      start: calendar.dateFrom?.set("hour", 0).toDate() || newDateByTimezone(),
      end: calendar.dateTo?.set("hour", 0).toDate() || newDateByTimezone()
    };
  }, [calendar]);

  const initial = getInitialDates(comparePeriod, basePeriod, isMonthCalendar);
  const initialLeapYear = getInitialLeapYear(params, includeLeapYear);

  const [isOpen, setOpen] = useState(selectedHistoricalOpen);
  const [isLeapYearChecked, setLeapYearChecked] = useState(initialLeapYear);
  const [startDate, setStartDate] = useState<Nullable<Date>>(initial.start);
  const [endDate, setEndDate] = useState<Nullable<Date>>(initial.end);
  const isEnabled = useSelector(isHistoricalDataEnabledSelector);
  const categoryDateRange = useCategoryDateRange();

  const leapYearStatus = useMemo(() => {
    const historicalPeriod = {
      start: startDate || newDateByTimezone(),
      end: endDate || newDateByTimezone()
    };

    if (
      isAfter(basePeriod.start, basePeriod.end) ||
      isAfter(historicalPeriod.start, historicalPeriod.end)
    ) {
      return LeapYearStatus.bothOrNone;
    }

    const base = checkLeapYear(basePeriod);
    const compare = checkLeapYear({
      start: historicalPeriod.start,
      end: historicalPeriod.end
    });

    if (base && compare) {
      return LeapYearStatus.bothOrNone;
    }

    if (base) {
      return LeapYearStatus.base;
    }

    if (compare) {
      return LeapYearStatus.compare;
    }

    return LeapYearStatus.bothOrNone;
  }, [basePeriod, startDate, endDate]);

  const diff = useMemo(() => {
    const diffFn = isMonthCalendar
      ? differenceInMonths
      : differenceInCalendarDays;
    const result = {
      base: diffFn(basePeriod.end, basePeriod.start),
      compare: diffFn(endDate || 0, startDate || 0)
    };

    if (isMonthCalendar || isLeapYearChecked) {
      return result;
    }

    const shift = {
      base: leapYearStatus === LeapYearStatus.base ? -1 : 0,
      compare: leapYearStatus === LeapYearStatus.compare ? -1 : 0
    };

    return {
      base: result.base + shift.base,
      compare: result.compare + shift.compare
    };
  }, [
    isMonthCalendar,
    basePeriod,
    endDate,
    startDate,
    isLeapYearChecked,
    leapYearStatus
  ]);

  const hasLeapYear = leapYearStatus !== LeapYearStatus.bothOrNone;
  const rangeStart = {
    min: categoryDateRange.start,
    max: subDays(basePeriod.start, 1)
  };
  const rangeEnd = {
    min: startDate || categoryDateRange.start,
    max: subDays(basePeriod.start, 1)
  };

  const daysShift = useMemo(() => {
    if (
      !basePeriod.start ||
      !startDate ||
      !endDate ||
      isBefore(startDate, rangeStart.min) ||
      isAfter(endDate, rangeEnd.max)
    ) {
      return null;
    }

    const baseStartDay = getDay(startOfDay(basePeriod.start));
    const compareStartDay = getDay(startOfDay(startDate));

    // calculate both forward and backward shifts
    let forwardShift = baseStartDay - compareStartDay;
    if (forwardShift < 0) forwardShift += 7;
    const backwardShift = forwardShift - 7;

    // choose the shift with smallest absolute value
    const smallestShift =
      Math.abs(forwardShift) <= Math.abs(backwardShift)
        ? forwardShift
        : backwardShift;

    return smallestShift === 0 ? null : smallestShift;
  }, [basePeriod.start, endDate, rangeEnd.max, rangeStart.min, startDate]);

  const handleDateChange = (type: "start" | "end") => (
    date: Nullable<Date>
  ) => {
    if (!date) {
      const setter = type === "start" ? setStartDate : setEndDate;
      setter(null);
      return;
    }

    if (type === "start") {
      const start = isMonthCalendar ? startOfMonth(date) : date;
      setStartDate(start);

      if (endDate && isAfter(date, endDate)) {
        setEndDate(null);
      }
    }

    if (type === "end") {
      const end = isMonthCalendar ? startOfDay(endOfMonth(date)) : date;
      setEndDate(end);
    }
  };

  const validate = () => {
    if (!isOpen || !isEnabled) {
      return "";
    }

    if (!startDate || !endDate) {
      return "Wybierz datę";
    }

    if (isBefore(endDate, startDate)) {
      return 'Data "od" musi być wcześniejsza niż "do"';
    }

    if (isBefore(startDate, rangeStart.min) || isAfter(endDate, rangeEnd.max)) {
      const start = format(rangeStart.min, DATE_FNS_DOT_FORMAT);
      const end = format(rangeEnd.max, DATE_FNS_DOT_FORMAT);

      return `Możesz wybrać zakres dat od ${start} do ${end}`;
    }

    if (diff.compare > diff.base) {
      return "Okres porównawczy nie może być dłuższy od bazowego";
    }

    return "";
  };

  const getDiffInfo = () => {
    const initial = {
      message: "",
      base: diff.base,
      compare: diff.compare
    };

    if (!startDate || !endDate) {
      return initial;
    }

    const diffCount = Math.abs(diff.base - diff.compare);
    const declension = isMonthCalendar
      ? declenseMonths(diffCount)
      : declenseDays(diffCount);

    if (diff.base > diff.compare) {
      initial.message = `Okres bazowy jest dłuższy o ${diffCount} ${declension}`;
    } else if (diff.compare > diff.base) {
      initial.message = `Okres porównawczy jest dłuższy o ${diffCount} ${declension}`;
    }

    return initial;
  };

  const getParsedValuesBeforeApprove = () => {
    const dateFrom = format(startDate!, DEFAULT_DATE_FORMAT);
    const dateTo = format(endDate!, DEFAULT_DATE_FORMAT);

    return {
      selectedItem: {
        label: `${dateFrom} - ${dateTo}`,
        value: { dateFrom, dateTo }
      },
      includeLeapYear: hasLeapYear ? isLeapYearChecked : null
    };
  };

  const onBasePeriodChange = (start: Date, end: Date) => {
    const yearBack = getYearBack({ start, end }, isMonthCalendar);
    setStartDate(yearBack.start);
    setEndDate(yearBack.end);
  };

  const handlePredefinedOption = (days: number) => {
    if (isMonthCalendar) return;
    const end = startOfDay(subDays(basePeriod.start, 1));
    if (isBefore(end, rangeEnd.min)) return;
    const start = startOfDay(subDays(end, days - 1));
    if (isBefore(start, rangeStart.min)) return;
    setStartDate(start);
    setEndDate(end);
  };

  const handleDaysShift = useCallback(() => {
    if (isMonthCalendar || !startDate || !endDate || daysShift === null) {
      return null;
    }

    const shiftedDates = {
      start: addDays(startOfDay(startDate), daysShift),
      end: addDays(startOfDay(endDate), daysShift)
    };

    // calculate weeks adjustment if dates fall outside boundaries
    let weeksDiff = 0;
    if (isBefore(shiftedDates.start, rangeStart.min)) {
      weeksDiff = Math.ceil(
        differenceInDays(rangeStart.min, shiftedDates.start) / 7
      );
    } else if (isAfter(shiftedDates.end, rangeEnd.max)) {
      weeksDiff = -Math.ceil(
        differenceInDays(shiftedDates.end, rangeEnd.max) / 7
      );
    }

    // apply the weekly adjustment to both dates
    shiftedDates.start = addDays(shiftedDates.start, weeksDiff * 7);
    shiftedDates.end = addDays(shiftedDates.end, weeksDiff * 7);

    setStartDate(shiftedDates.start);
    setEndDate(shiftedDates.end);
  }, [
    daysShift,
    endDate,
    isMonthCalendar,
    rangeEnd.max,
    rangeStart.min,
    startDate
  ]);

  return {
    isEnabled,
    isOpen,
    toggleOpen: () => setOpen(!isOpen),
    startDate,
    endDate,
    rangeStart,
    rangeEnd,
    handleDateChange,
    getParsedValuesBeforeApprove,
    onBasePeriodChange,
    diffInfo: getDiffInfo(),
    validate,
    hasLeapYear,
    isLeapYearChecked,
    toggleLeapYear: () => setLeapYearChecked(!isLeapYearChecked),
    handlePredefinedOption,
    daysShift,
    handleDaysShift
  };
};
