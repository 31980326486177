import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useDataWithChange } from "pages/Reports/partials/Chart/StandardChart/components/hooks/useDataWithChange";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { tilesCurtainId } from "pages/Reports/partials/Chart/StandardChart/Curtain";
import { getGridPosition } from "pages/Reports/partials/Chart/StandardChart/Grid";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import { getLineOpacity } from "pages/Reports/partials/Chart/StandardChart/utils/getLineOpacity";
import { useChartValueTiles } from "pages/Reports/partials/Chart/StandardChart/utils/useChartValueTiles";
import { useChartValueTilesVisibility } from "pages/Reports/partials/Chart/StandardChart/utils/useChartValueTilesSettings";
import { hoveredLineSelector } from "pages/Reports/redux/reducers/chartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import {
  areTilesHiddenSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { isThisPage, NO_DATA_SHORT } from "utils";
import { round } from "utils/round";

import { ChartValueSingleTile } from "./ChartValueSingleTile";

interface Props {
  lineChart: ChartData;
  gridWidth: number;
}

//tiles for charts with more than o;e timepoint
export const ChartValueTiles = ({ gridWidth, lineChart }: Props) => {
  const [offsets, setOffsets] = useState<{ id: string; value: number }[]>([]);
  const [tileState, tileActions] = useChartValueTiles();
  const { yScaleForGrid: yScale, yScaleRight } = useChartScales(
    lineChart,
    gridWidth
  );
  const changeData = useDataWithChange(lineChart.leftChart);
  const domainLength = lineChart.domain.length;
  const [
    isTilesSelectionEnabled,
    isBackDataVisible
  ] = useChartValueTilesVisibility();
  const { both: isBothDatasets } = useCurrentDataset();
  const areTilesHidden = useSelector(areTilesHiddenSelector);
  const pathname = useSelector(pathnameSelector);
  const isLocationPage = isThisPage(pathname, REPORTS_PATHS.LOCATION);
  const hoveredLine = useSelector(hoveredLineSelector);
  const {
    isLineChart,
    isAreaChart,
    isIncrementalChart,
    isIncrementalComboChart
  } = useChartTypeBoolean();

  const tilesToRender = useMemo(() => {
    if (!isTilesSelectionEnabled || areTilesHidden) return null;

    return tileState.tiles
      .map(tile => {
        const tileHorizonalPosition = getGridPosition(
          gridWidth,
          domainLength,
          tile.timepoint
        );
        const { isRightChart, isHistorical, isSecondary } = tile;
        const hideTile =
          (isHistorical && !(isLineChart || isAreaChart)) ||
          (isHistorical && !isBothDatasets) ||
          (!isRightChart && isIncrementalChart); // [PMD-4509]: show custom tile for bar on incremental chart

        if (hideTile) return null;

        const chartPart = isRightChart
          ? lineChart.rightChart
          : lineChart.leftChart;
        const linePoint = chartPart?.chart.find(
          line => tile.lineName === line.id
        );

        if (!linePoint || (isSecondary && !linePoint.secondaryTimeline)) {
          return null;
        }

        const timeline = (() => {
          if (isHistorical && isSecondary) {
            return linePoint.secondaryLastYearTimeline;
          }
          if (isHistorical) return linePoint.lastYearTimeline;
          if (isSecondary) return linePoint.secondaryTimeline;
          return linePoint.timeline;
        })();

        const valueY = timeline?.[tile.timepoint]?.valueY || 0;
        const valueYLastYear =
          linePoint.lastYearTimeline?.[tile.timepoint]?.valueY || 0;

        const changeValue = changeData[tile.timepoint]?.change || 0;
        const firstValue =
          isIncrementalComboChart && !isRightChart // [PMD-4710]: show change value only for incremental chart bar
            ? changeValue
            : valueY;

        const tileVerticalPosition = isRightChart
          ? yScaleRight(valueY)
          : yScale(firstValue);

        const opacity = getLineOpacity({
          id: tile.lineName,
          isLocationPage,
          hoveredLine
        });

        return {
          tileHorizonalPosition,
          backgroundColor: linePoint.color || "",
          firstValue: firstValue ? round(firstValue) : NO_DATA_SHORT,
          ...(isBackDataVisible && {
            secondValue: valueYLastYear ? round(valueYLastYear) : NO_DATA_SHORT
          }),
          tileVerticalPosition,
          tileRawData: tile,
          onClick: () => tileActions.handleTiles([tile]),
          opacity
        };
      })
      .filter(line => line !== null);
  }, [
    isTilesSelectionEnabled,
    areTilesHidden,
    tileState.tiles,
    gridWidth,
    domainLength,
    isLineChart,
    isAreaChart,
    isBothDatasets,
    isIncrementalChart,
    lineChart.rightChart,
    lineChart.leftChart,
    changeData,
    isIncrementalComboChart,
    yScaleRight,
    yScale,
    isLocationPage,
    hoveredLine,
    isBackDataVisible,
    tileActions
  ]);

  if (!tilesToRender) return null;

  return (
    <g clipPath={`url(#${tilesCurtainId})`}>
      {tilesToRender.map(tile => {
        if (!tile) return null;

        const key = [
          "tile",
          tile.tileRawData.lineName,
          tile.tileRawData.timepoint,
          tile.firstValue,
          tile.secondValue,
          tile.tileRawData.isRightChart ? "right" : "left",
          tile.tileRawData.isHistorical ? "historical" : "base",
          tile.tileRawData.isSecondary ? "secondary" : "primary"
        ].join("-");
        const height = tile.secondValue ? 40 : 20;
        const offset = offsets.find(offset => offset.id === key);

        return (
          <foreignObject
            key={key}
            x={tile.tileHorizonalPosition}
            y={tile.tileVerticalPosition}
            width={40}
            height={height}
            overflow="visible"
          >
            <Rnd
              enableResizing={false}
              dragAxis="y"
              size={{ width: 40, height }}
              position={{
                x: 0,
                y: offset?.value || 0
              }}
              onDragStop={(e, d) => {
                const updatedY = tile.tileVerticalPosition + d.y;
                if (updatedY >= -5 && updatedY <= 285) {
                  setOffsets([
                    ...offsets.filter(offset => offset.id !== key),
                    { id: key, value: d.y }
                  ]);
                }
              }}
            >
              <ChartValueSingleTile tile={tile} />
            </Rnd>
          </foreignObject>
        );
      })}
    </g>
  );
};
