import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";
import { useDebouncedCallback } from "use-debounce";

import { SidebarMultiselectHooks } from "components/atoms/SidebarData/hooks/useSidebarMultiselect";
import { MainOverlay, MainSwitch } from "components/organisms";
import { useDefaultQueryParams, useInterval } from "hooks";
import { KeyboardHooks } from "hooks/useKeyboardKeys";
import { useKeyboardListener } from "hooks/useKeyboardListener";
import {
  checkUserActivity,
  updateUserActivityTimestamp
} from "pages/Login/redux/reducer";
import { useCategoryDateRange } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersSelectors";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  adjustDateParams,
  adjustExportParams,
  adjustLflParams,
  adjustMultipackParams,
  adjustParamsByAggregation,
  adjustParamsByCountiesSelection,
  adjustParamsByLocationMapVoivodeship,
  adjustParamsByPeriod,
  adjustParamsByRole,
  adjustParamsByTier,
  adjustStoreTypes,
  adjustSummaryParams,
  adjustVendorIdParam,
  appendRequiredParams
} from "pages/Reports/utils/queryParams";
import {
  PARSE_OPTIONS,
  STRINGIFY_OPTIONS
} from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import { validateParamsPerReport } from "pages/Reports/utils/validateParams/validateParamsPerReport";
import { setResizedWindowDimensions } from "store/actions/appActions";
import { updateQueryParams } from "store/actions/routerActions";
import {
  getInitialTier,
  isLoggedInSelector,
  isPowerUserSelector,
  setCurrentTier,
  tierSelector,
  userRoleSelector,
  userSelector,
  userTiersAvailableSelector
} from "store/reducers/userReducer";
import { windowWidthSelector } from "store/selectors/appSelectors";
import {
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import {
  APP_PATHS,
  isThisPage,
  pushPageView,
  USER_ACTIVITY_EVENTS
} from "utils";
import { QP } from "utils/defaultQueryParams";
import { isTier } from "utils/types";

const { REPORTS, INSIGHTS, EXPORT } = APP_PATHS;

const MINUTE = 60 * 1000;

export const MainView: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const pathname = useSelector(pathnameSelector);
  const iterations = useRef(0);

  const [
    ,
    { clearStartEndElements }
  ] = SidebarMultiselectHooks.useSidebarMultiselect();

  const [, { setKeyDown, clearKeyDown }] = KeyboardHooks.useKeyboardKeys();

  const handleKeyDown = (event: KeyboardEvent) => {
    setKeyDown(event.key);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    clearStartEndElements();
    clearKeyDown(event.key);
  };

  useKeyboardListener({ handleKeyDown, handleKeyUp });

  const onWindowResize = useCallback(
    (width: number, height: number) => {
      dispatch(
        setResizedWindowDimensions({
          windowWidth: width,
          windowHeight: height
        })
      );
    },
    [dispatch]
  );

  const [debouncedUserActivityCallback] = useDebouncedCallback<() => void>(
    () => {
      dispatch(updateUserActivityTimestamp(Date.now()));
    },
    5000
  );

  const intervalTime = isLoggedIn ? MINUTE : null;

  useInterval(() => {
    dispatch(checkUserActivity());
  }, intervalTime);

  React.useEffect(() => {
    USER_ACTIVITY_EVENTS.forEach(eventType => {
      window.addEventListener(eventType, debouncedUserActivityCallback);
    });

    return () =>
      USER_ACTIVITY_EVENTS.forEach(eventType => {
        window.removeEventListener(eventType, debouncedUserActivityCallback);
      });
  }, [debouncedUserActivityCallback]);

  const currentTier = useSelector(tierSelector);
  const categoryDateRange = useCategoryDateRange();
  const availableTiers = useSelector(userTiersAvailableSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const currentQueryParams = useSelector(queryParamsSelector);
  const userRole = useSelector(userRoleSelector);
  const user = useSelector(userSelector);
  const isCompaniesDropdownMultiChoice = useSelector(
    isCompaniesDropdownMultiChoiceSelector
  );
  const windowWidth = useSelector(windowWidthSelector);

  const defaultQueryParams = useDefaultQueryParams();
  const queryParamsTier = getInitialTier(window.location.search);

  useEffect(() => {
    const skipEffect =
      !user.id ||
      ![REPORTS, INSIGHTS, EXPORT].some(url => isThisPage(pathname, url));

    if (skipEffect) return;

    // VALIDATE PARAMS
    const userAvailableTiers = user.tiersAccess;
    const highestUserTier = userAvailableTiers[user.tiersAccess.length - 1];

    const isQPTierValid = isTier(queryParamsTier);
    const isQPTierAccessible =
      userAvailableTiers.includes(queryParamsTier) && queryParamsTier !== 0;

    if (currentTier === 0) {
      if (isQPTierValid && isQPTierAccessible) {
        dispatch(updateQueryParams({ [QP.TIER]: String(queryParamsTier) }));
        dispatch(setCurrentTier(queryParamsTier));
      } else {
        dispatch(updateQueryParams({ [QP.TIER]: String(highestUserTier) }));
        dispatch(setCurrentTier(highestUserTier));
      }
      return;
    }
    iterations.current += 1;

    // VENDOR ID
    const paramsWithAdjustedVendorId = adjustVendorIdParam(
      currentQueryParams,
      user,
      isPowerUser,
      isCompaniesDropdownMultiChoice
    );

    // REQUIRED PARAMS
    const queryParamsWithRequiredValues = appendRequiredParams(
      paramsWithAdjustedVendorId,
      defaultQueryParams
    );

    const dateBasedParams = adjustDateParams(
      queryParamsWithRequiredValues,
      defaultQueryParams,
      categoryDateRange.start,
      iterations.current
    );

    // BASED ON ROLE
    const roleBasedParams = adjustParamsByRole(userRole, dateBasedParams);

    // BASED ON TIER
    const tierBasedQueryParams = adjustParamsByTier(
      roleBasedParams,
      currentTier
    );

    // STORE TYPE
    const paramsByStoreType = adjustStoreTypes(
      tierBasedQueryParams,
      defaultQueryParams
    );

    // BASED ON COUNTIES
    const paramsByCountiesSelection = adjustParamsByCountiesSelection(
      paramsByStoreType,
      pathname,
      isPowerUser
    );

    //BASED ON AGGREGATION
    const paramsByAggregation = adjustParamsByAggregation(
      paramsByCountiesSelection,
      pathname,
      isPowerUser
    );

    // BASED ON LOCATION MAP VOIVODESHIP
    const paramsByLocationMapVoivodeship = adjustParamsByLocationMapVoivodeship(
      paramsByAggregation,
      pathname,
      isPowerUser
    );

    //BASED ON SELECTED PERIOD
    const paramsByPeriod = adjustParamsByPeriod(paramsByLocationMapVoivodeship);

    const paramsPerReport = validateParamsPerReport(
      pathname,
      qs.stringify(paramsByPeriod, STRINGIFY_OPTIONS)
    );

    const exportParams = adjustExportParams(
      qs.parse(paramsPerReport, PARSE_OPTIONS),
      pathname,
      currentTier,
      isPowerUser
    );

    const multipackParams = adjustMultipackParams(
      exportParams,
      pathname,
      isPowerUser,
      currentTier
    );

    const summaryParams = adjustSummaryParams(multipackParams, pathname);

    const lflParams = adjustLflParams(summaryParams, pathname);

    dispatch(updateQueryParams(lflParams, "replace", true));
  }, [
    user,
    queryParamsTier,
    currentTier,
    userRole,
    dispatch,
    availableTiers,
    categoryDateRange.start,
    defaultQueryParams,
    isPowerUser,
    currentQueryParams,
    pathname,
    windowWidth,
    isCompaniesDropdownMultiChoice
  ]);

  useEffect(() => {
    if (user.id) {
      const pagePath = pathname + window.location.search;
      pushPageView({ pathname, pagePath, userType: userRole, userId: user.id });
    }
  }, [pathname, userRole, user.id]);

  useLayoutEffect(() => {
    if (!window.innerWidth || !window.innerHeight) return;

    const setWindowSize = () => {
      onWindowResize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", setWindowSize);

    return () => window.removeEventListener("resize", setWindowSize);
  }, [onWindowResize]);

  return (
    <>
      <MainSwitch />
      <MainOverlay />
    </>
  );
};
