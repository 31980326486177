import { useSelector } from "react-redux";

import {
  endOfYesterday,
  isBefore,
  startOfDay,
  startOfYesterday
} from "date-fns";
import { createSelector } from "reselect";

import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { AppState } from "store";
import { isPowerUserSelector } from "store/reducers/userReducer";

import { CategoryItemsState } from "./categoryFiltersReducer";

export const initialCategoryFiltersState: CategoryItemsState = {
  errorMessage: ""
};

// SELECTORS
const categoryFilterSelector = (state: AppState): CategoryItemsState =>
  state.reports.filters.category || initialCategoryFiltersState;

export const categoriesErrMsgSelector = createSelector(
  categoryFilterSelector,
  ({ errorMessage }) => errorMessage
);

export const useCategoryDateRange = () => {
  const isPowerUser = useSelector(isPowerUserSelector);
  const [categories] = CategoryHooks.useCategory();

  if (isPowerUser) {
    // set full date range for power users
    return { start: startOfDay(new Date("2018-01-01")), end: endOfYesterday() };
  }

  // get selected categories of a lowest level
  const selectedCategories =
    [
      categories.level3.selected,
      categories.level2.selected,
      categories.level1.selected
    ].find(level => level.length > 0) ?? [];

  if (!selectedCategories.length) {
    return { start: startOfYesterday(), end: endOfYesterday() };
  }

  let minStartDate = startOfYesterday();
  selectedCategories.forEach(category => {
    const date = startOfDay(new Date(category.startDate));
    if (isBefore(date, minStartDate)) minStartDate = date;
  });

  return { start: minStartDate, end: endOfYesterday() };
};
