import { useMemo } from "react";

import { useGetDisabledDataTypeTooltip } from "components/molecules/ChartCheckboxDropdown/hooks";
import {
  MegaDropdownLevels,
  useMegaDropdown
} from "components/molecules/ChartHeader/hooks/useMegaDropdown";
import { useMultipackDropdown } from "components/molecules/ChartHeader/hooks/useMultipackDropdown";

import { ReportType } from "./state";

export const useBarChartTableMetrics = (reportType: ReportType) => {
  const metrics1 = useMegaDropdown(MegaDropdownLevels.first);
  const metrics2 = useMegaDropdown(MegaDropdownLevels.second);
  const metrics3 = useMegaDropdown(MegaDropdownLevels.third);
  const multipack = useMultipackDropdown();
  const getDisabledTooltip = useGetDisabledDataTypeTooltip();

  return useMemo(() => {
    let items = [metrics1.items, metrics2.items];
    if (reportType === "loyalty") {
      items = [metrics1.items, metrics2.items, metrics3.items];
    } else if (reportType === "multipack") {
      items = multipack.map(({ metrics }) => metrics.map(({ value }) => value));
    }
    return items
      .flat()
      .filter(
        metric => metric.length > 0 && !getDisabledTooltip(metric).isDisabled
      );
  }, [
    getDisabledTooltip,
    metrics1.items,
    metrics2.items,
    metrics3.items,
    multipack,
    reportType
  ]);
};
