import { Checkbox } from "components/molecules";
import { getPolishLabelForChartHeader, NO_DATA_SHORT } from "utils";
import { Nullable } from "utils/types";

import s from "./barChartTable.module.scss";
import { ReportType, useBarChartTableStore } from "./hooks/state";
import { MultiSelect } from "./MultiSelect";

type TableData = {
  head: {
    label: string;
    color: string | undefined;
  }[];
  body: {
    label: string;
    values: {
      base: Nullable<number>;
      change: Nullable<number>;
      type: "absolute" | "percentage";
    }[];
    color: string | undefined;
  }[];
};

export const formatValue = (
  value: Nullable<number>,
  options?: { decimals?: number; suffix?: string }
) => {
  if (value === null) {
    return NO_DATA_SHORT;
  }

  const decimals = options?.decimals ?? 2;
  const suffix = options?.suffix ?? "";
  let divisor = 1,
    abbr = "";

  if (value >= 1_000_000) {
    divisor = 1_000_000;
    abbr = "M";
  } else if (value >= 1_000) {
    divisor = 1_000;
    abbr = "k";
  }

  const formatted = Intl.NumberFormat("pl-PL", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: 0
  }).format(value / divisor);

  return `${formatted}${abbr}${suffix}`;
};

const getChangeClassName = (value: Nullable<number>) => {
  if (value === null) return undefined;
  if (value > 0) return s.positive;
  if (value < 0) return s.negative;
  return undefined;
};

export const BarChartTable = ({
  reportType,
  metrics,
  tableData
}: {
  reportType: ReportType;
  metrics: string[];
  tableData: TableData;
}) => {
  const [state, actions] = useBarChartTableStore();
  const options = metrics.map(metric => ({
    label: getPolishLabelForChartHeader(metric),
    value: metric
  }));

  return (
    <>
      <div className={s.options}>
        <MultiSelect
          testId="bar-chart-table-multiselect"
          label="Pokaż miary"
          options={options}
          selected={state.metrics[reportType]}
          onClear={() => actions.clearSellMetrics(reportType)}
          onChange={value => actions.toggleSellMetric(reportType, value)}
        />
        <Checkbox
          className={{ checkbox: s.checkbox, label: s.label }}
          label="Pokaż miary w kolumnach"
          isChecked={state.columns === "metrics"}
          onChange={actions.toggleColumns}
        />
      </div>
      {tableData.head.length > 0 && tableData.body.length > 0 && (
        <div className={s.wrapper}>
          <table className={s.table}>
            <thead>
              <tr>
                <th className={s.sticky} />
                {tableData.head.map((col, index) => (
                  <th key={index} style={{ color: col.color }}>
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.body.map((row, index) => (
                <tr key={index}>
                  <td className={s.sticky} style={{ color: row.color }}>
                    {row.label}
                  </td>
                  {row.values.map((value, index) => (
                    <td key={index}>
                      <p className={s.value}>
                        {formatValue(value.base, {
                          decimals: value.type === "percentage" ? 1 : 2
                        })}
                      </p>
                      <p className={getChangeClassName(value.change)}>
                        {formatValue(value.change, {
                          decimals: 1,
                          suffix: value.type === "percentage" ? " p.p." : "%"
                        })}
                      </p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
