import { ChartDataTypes } from "pages/Reports/types/chart";

import { RED_BULL_PRODUCT_COLORS } from "./../pages/Reports/sections/utils/color";
import { Periods } from "./types";

export const APP_NAME = "Advanced Customer Insight Tool";

export const APP_PATHS = {
  MAIN: "/",
  REPORTS: "/reports",
  KNOWLEDGE_BASE: "/knowledge-base",
  NEWS: "/news",
  LOGIN: "/login",
  INSIGHTS: "/insights",
  EXPORT: "/export",
  ADMIN: "/admin",
  LOGOUT: "/logout",
  STORAGE_SET: "/storage-set"
} as const;

export const PRIMARY_NAVBAR_HEIGHT = 84;
export const SECONDARY_NAVBAR_HEIGHT = 64;
export const FULL_NAVBAR_HEIGHT =
  PRIMARY_NAVBAR_HEIGHT + SECONDARY_NAVBAR_HEIGHT;

export const TEXT_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  BRAND: "brand",
  CRITICAL: "critical",
  WHITE: "white",
  INFO: "info",
  LABEL: "label"
} as const;

export const TEXT_WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold"
} as const;

export const TEXT_SIZES = {
  NORMAL: "normal",
  SMALL: "small",
  EXTRA_SMALL: "extraSmall"
} as const;

export const TEXT_STYLES = {
  NORMAL: "normal",
  ITALIC: "italic"
} as const;

export const TEXT_WRAPPERS = {
  P: "p",
  SPAN: "span",
  DIV: "div"
} as const;

export const HEADER_ELEMENTS = {
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5"
} as const;

export const HEADER_TYPES = {
  ...HEADER_ELEMENTS,
  MAIN_NUMBER: "mainNumber",
  SEMI_NUMBER: "semiNumber",
  SMALL_NUMBER: "smallNumber"
} as const;

export const ICON_SIZES = {
  EXTRA_LARGE: "extraLarge",
  LARGE: "large",
  NORMAL: "normal",
  SMALL: "small",
  EXTRA_SMALL: "extraSmall"
} as const;

export const MAXIMUM_DATASETS_ITEMS_COUNT = 30;

export const REST_API_ENDPOINTS = {
  CATEGORIES: "/categories/",
  ATTRIBUTES: "/attributes/",
  PRODUCTS: "/materials/",
  HIERARCHY_PRODUCTS: "/materials/hierarchy/",
  HIERARCHY_PRODUCTS_EXPORT: "/materials/hierarchy/export/",
  HIERARCHY_ATTRIBUTES: "/attributes/hierarchy/",
  HIERARCHY_ATTRIBUTES_EXPORT: "/attributes/hierarchy/export/",
  REPORTS: {
    SALES: "/reports/sales/",
    SALES_BY_LOCATION: "/reports/locations/",
    SHARES: "/reports/shares/",
    DYNAMIC_SALES: "/reports/dynamic/",
    RECEIPTS: "/reports/receipts/",
    SEGMENTS: "/reports/segments/",
    LOGISTICS: "/reports/logistics/",
    HOURS: "/reports/hourly/",
    LOYALTY: "/reports/loyalty/",
    MULTIPACK: "/reports/multipack/",
    SALES_EXPORT: "/reports/sales/export/",
    SALES_BY_LOCATION_EXPORT: "/reports/locations/export/",
    SHARES_EXPORT: "/reports/shares/export/",
    DYNAMICS_EXPORT: "/reports/dynamic/export/",
    RECEIPTS_EXPORT: "/reports/receipts/export/",
    LOGISTICS_EXPORT: "/reports/logistics/export/",
    SUPER_EXPORT: "/data-export/",
    GENERATE_SUPER_EXPORT: "/data-export/create/",
    UPDATE_SUPER_EXPORT: "/data-export/update/",
    PROMOTIONS: "/promotions/"
  },
  RANKINGS: {
    SALES: "/rankings/sales/",
    SHARES: "/rankings/shares/",
    RECEIPTS: "/rankings/receipts/",
    DYNAMICS: "/rankings/dynamic/",
    LOYALTY: "/rankings/loyalty/",
    MULTIPACK: "/rankings/multipack/"
  },
  COUNTIES: "/locations/counties/",
  STORE_TYPES: "/store-types/",
  BRANDS: "/brands/",
  USERS: {
    LOGIN: "/users/login/",
    LOGOUT: "/users/logout/",
    ME: "/users/me"
  },
  COMPANIES: {
    GET_POWERUSER_COMPANIES: "/companies/data-warehouse-ids-in-categories/",
    GET_COMPETITION_COMPANIES: "/companies/available-data-warehouse-ids/"
  },
  TICKETS: "/tickets/",
  NEWSLETTER: "/tickets/news",
  AGREEMENT: "/users/me/agreement/",
  ADMIN: {
    USER_MANAGEMENT: {
      BASE: "/users/",
      REACTIVATE: "/activate/",
      BLOCK: "/deactivate/",
      RESET_PASSWORD: "/reset-password/",
      COMPANIES: "/companies/"
    }
  },
  INSIGHTS: {
    GET: "/insights/", // GET endpoint type
    FAVOURITES: "/insights/favourites/", // POST and DELETE endpoint type
    DELETED: "/insights/deleted/", // POST and DELETE endpoint type
    RATE: "/insights/rate/", // POST type for thumb rating, PUT type for modal informations
    FEEDBACK_SINGLE: "/insights/feedback/", // POST endpoint type
    FEEDBACK_LIST: "/insights/feedback/list/", // POST endpoint type
    CHECK_NEW: "/insights/check_new_insights/",
    GET_SIDEBAR_DATA: "/insights/get_sidebar_data/"
  }
};

export const VENDOR_DEPENDENT_REQUESTS = [
  REST_API_ENDPOINTS.COMPANIES.GET_POWERUSER_COMPANIES,
  REST_API_ENDPOINTS.COMPANIES.GET_COMPETITION_COMPANIES,
  REST_API_ENDPOINTS.REPORTS.GENERATE_SUPER_EXPORT,
  REST_API_ENDPOINTS.RANKINGS.SALES,
  REST_API_ENDPOINTS.REPORTS.PROMOTIONS,
  REST_API_ENDPOINTS.REPORTS.SALES,
  REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION,
  REST_API_ENDPOINTS.REPORTS.SHARES,
  REST_API_ENDPOINTS.REPORTS.DYNAMIC_SALES,
  REST_API_ENDPOINTS.REPORTS.RECEIPTS,
  REST_API_ENDPOINTS.REPORTS.SEGMENTS,
  REST_API_ENDPOINTS.REPORTS.LOGISTICS,
  REST_API_ENDPOINTS.REPORTS.SALES_EXPORT,
  REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION_EXPORT,
  REST_API_ENDPOINTS.REPORTS.SHARES_EXPORT,
  REST_API_ENDPOINTS.REPORTS.DYNAMICS_EXPORT,
  REST_API_ENDPOINTS.REPORTS.RECEIPTS_EXPORT,
  REST_API_ENDPOINTS.REPORTS.LOGISTICS_EXPORT
];

export const FILTER_SECTIONS = {
  MAIN: {
    key: "main-filters-section",
    label: ""
  },
  COMPETITION: {
    key: "competition-filters-section",
    label: "Konkurencja"
  },
  REFERENCE_CATEGORIES: {
    key: "reference-categories-filters-section",
    label: "Kategorie referencyjne"
  },
  REFERENCE_BRANDS: {
    key: "reference-brands-filters-section",
    label: "Marki referencyjne"
  },
  REGION: {
    key: "region-filters-section",
    label: "Region"
  },
  SHOP_TYPE: {
    key: "shop-type-filters-section",
    label: "Rodzaje sklepów"
  },
  CLIENT_TYPE: {
    key: "client-type-filters-section",
    label: "Rodzaje transakcji"
  },
  LFL: {
    key: "lfl-filters-section",
    label: "Like For Like"
  },
  EXPORT_DATA_TYPE: {
    key: "export-data-type-filters-section",
    label: "Rodzaj danych"
  },
  SUPER_USER_SETTINGS: {
    key: "super-user-settings",
    label: "Ustawienia"
  }
};

export const CURRENCY = "PLN";

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_MOMENT_DATE_FORMAT = "yyyy-MM-DD";
export const DATE_FNS_DOT_FORMAT = "yyyy.MM.dd";
export const DATE_TIME_SECONDS_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const USER_ROLES = {
  SUPERUSER: "superUser",
  ADMIN: "admin",
  ANALYST: "analyst",
  CATMAN: "catman",
  LOGISTIC: "logistic"
} as const;

export const PROMOTION_DATA_TYPES = {
  ZESTAWY: "ZES",
  PODKLADKA_ALKOHOLOWA: "POD_ALK",
  ODLICZANIE: "ODL",
  ODLICZANIE_WSO: "ODL_WSO",
  MENUBOARD: "MEN",
  KATALOG: "KAT",
  KATALOG_WSO: "KAT_WSO",
  HAPPY_HOURS: "HAP",
  GAZETKA: "GAZ",
  GAZETKA_WSO: "GAZ_WSO",
  APLIKACJA: "APL",
  AKCJA_POLECAMY: "AKC",
  WIELOSZTUKA: "WS",
  WIELOSZTUKA_SUPER_OFERTA: "WSO",
  WIELOSZTUKA_APLIKACYJNA: "WSA",
  APLIKACJA_WSO: "APL_WSO",
  APLIKACJA_WSO_MULTIPACK: "APL_WSO_MULTIPACK",
  ZESTAWY_APLIKACJA: "CPL",
  UNKNOWN_PROMOTION: "UNK"
} as const;

const LOYALTY_DATA_TYPES = {
  LOYALTY_BACK_PERCENT: "LOYALTY_BACK_PERCENT",
  LOYALTY_BACK_PERCENT_CHANGE: "LOYALTY_BACK_PERCENT_CHANGE",
  LOYALTY_BACK_PERCENT_CHANGE_PERCENTAGE:
    "LOYALTY_BACK_PERCENT_CHANGE_PERCENTAGE",
  LOYALTY_BACK_PERCENT_LAST_YEAR: "LOYALTY_BACK_PERCENT_LAST_YEAR",
  NEW_CUSTOMERS_COUNT: "NEW_CUSTOMERS_COUNT",
  NEW_CUSTOMERS_COUNT_CHANGE: "NEW_CUSTOMERS_COUNT_CHANGE",
  NEW_CUSTOMERS_COUNT_CHANGE_PERCENTAGE:
    "NEW_CUSTOMERS_COUNT_CHANGE_PERCENTAGE",
  NEW_CUSTOMERS_COUNT_LAST_YEAR: "NEW_CUSTOMERS_COUNT_LAST_YEAR",
  NEW_CUSTOMERS_PERCENT: "NEW_CUSTOMERS_PERCENT",
  NEW_CUSTOMERS_PERCENT_CHANGE: "NEW_CUSTOMERS_PERCENT_CHANGE",
  NEW_CUSTOMERS_PERCENT_CHANGE_PERCENTAGE:
    "NEW_CUSTOMERS_PERCENT_CHANGE_PERCENTAGE",
  NEW_CUSTOMERS_PERCENT_LAST_YEAR: "NEW_CUSTOMERS_PERCENT_LAST_YEAR",
  ALL_RETURNS_PERCENT: "ALL_RETURNS_PERCENT",
  ALL_RETURNS_PERCENT_CHANGE: "ALL_RETURNS_PERCENT_CHANGE",
  ALL_RETURNS_PERCENT_CHANGE_PERCENTAGE:
    "ALL_RETURNS_PERCENT_CHANGE_PERCENTAGE",
  ALL_RETURNS_PERCENT_LAST_YEAR: "ALL_RETURNS_PERCENT_LAST_YEAR",
  ALL_RETURNS_COUNT: "ALL_RETURNS_COUNT",
  ALL_RETURNS_COUNT_CHANGE: "ALL_RETURNS_COUNT_CHANGE",
  ALL_RETURNS_COUNT_CHANGE_PERCENTAGE: "ALL_RETURNS_COUNT_CHANGE_PERCENTAGE",
  ALL_RETURNS_COUNT_LAST_YEAR: "ALL_RETURNS_COUNT_LAST_YEAR",
  RETURNS_1_WEEK_PERCENT: "RETURNS_1_WEEK_PERCENT",
  RETURNS_1_WEEK_PERCENT_CHANGE: "RETURNS_1_WEEK_PERCENT_CHANGE",
  RETURNS_1_WEEK_PERCENT_CHANGE_PERCENTAGE:
    "RETURNS_1_WEEK_PERCENT_CHANGE_PERCENTAGE",
  RETURNS_1_WEEK_PERCENT_LAST_YEAR: "RETURNS_1_WEEK_PERCENT_LAST_YEAR",
  RETURNS_2_WEEKS_PERCENT: "RETURNS_2_WEEKS_PERCENT",
  RETURNS_2_WEEKS_PERCENT_CHANGE: "RETURNS_2_WEEKS_PERCENT_CHANGE",
  RETURNS_2_WEEKS_PERCENT_CHANGE_PERCENTAGE:
    "RETURNS_2_WEEKS_PERCENT_CHANGE_PERCENTAGE",
  RETURNS_2_WEEKS_PERCENT_LAST_YEAR: "RETURNS_2_WEEKS_PERCENT_LAST_YEAR",
  RETURNS_4_WEEKS_PERCENT: "RETURNS_4_WEEKS_PERCENT",
  RETURNS_4_WEEKS_PERCENT_CHANGE: "RETURNS_4_WEEKS_PERCENT_CHANGE",
  RETURNS_4_WEEKS_PERCENT_CHANGE_PERCENTAGE:
    "RETURNS_4_WEEKS_PERCENT_CHANGE_PERCENTAGE",
  RETURNS_4_WEEKS_PERCENT_LAST_YEAR: "RETURNS_4_WEEKS_PERCENT_LAST_YEAR",
  RETURNS_6_WEEKS_PERCENT: "RETURNS_6_WEEKS_PERCENT",
  RETURNS_6_WEEKS_PERCENT_CHANGE: "RETURNS_6_WEEKS_PERCENT_CHANGE",
  RETURNS_6_WEEKS_PERCENT_CHANGE_PERCENTAGE:
    "RETURNS_6_WEEKS_PERCENT_CHANGE_PERCENTAGE",
  RETURNS_6_WEEKS_PERCENT_LAST_YEAR: "RETURNS_6_WEEKS_PERCENT_LAST_YEAR",
  RETURNS_8_WEEKS_PERCENT: "RETURNS_8_WEEKS_PERCENT",
  RETURNS_8_WEEKS_PERCENT_CHANGE: "RETURNS_8_WEEKS_PERCENT_CHANGE",
  RETURNS_8_WEEKS_PERCENT_CHANGE_PERCENTAGE:
    "RETURNS_8_WEEKS_PERCENT_CHANGE_PERCENTAGE",
  RETURNS_8_WEEKS_PERCENT_LAST_YEAR: "RETURNS_8_WEEKS_PERCENT_LAST_YEAR",

  RETURNS_1_WEEK_ABSOLUTE: "RETURNS_1_WEEK_ABSOLUTE",
  RETURNS_1_WEEK_ABSOLUTE_CHANGE: "RETURNS_1_WEEK_ABSOLUTE_CHANGE",
  RETURNS_1_WEEK_ABSOLUTE_CHANGE_PERCENTAGE:
    "RETURNS_1_WEEK_ABSOLUTE_CHANGE_PERCENTAGE",
  RETURNS_1_WEEK_ABSOLUTE_LAST_YEAR: "RETURNS_1_WEEK_ABSOLUTE_LAST_YEAR",
  RETURNS_2_WEEKS_ABSOLUTE: "RETURNS_2_WEEKS_ABSOLUTE",
  RETURNS_2_WEEKS_ABSOLUTE_CHANGE: "RETURNS_2_WEEKS_ABSOLUTE_CHANGE",
  RETURNS_2_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE:
    "RETURNS_2_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE",
  RETURNS_2_WEEKS_ABSOLUTE_LAST_YEAR: "RETURNS_2_WEEKS_ABSOLUTE_LAST_YEAR",
  RETURNS_4_WEEKS_ABSOLUTE: "RETURNS_4_WEEKS_ABSOLUTE",
  RETURNS_4_WEEKS_ABSOLUTE_CHANGE: "RETURNS_4_WEEKS_ABSOLUTE_CHANGE",
  RETURNS_4_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE:
    "RETURNS_4_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE",
  RETURNS_4_WEEKS_ABSOLUTE_LAST_YEAR: "RETURNS_4_WEEKS_ABSOLUTE_LAST_YEAR",
  RETURNS_6_WEEKS_ABSOLUTE: "RETURNS_6_WEEKS_ABSOLUTE",
  RETURNS_6_WEEKS_ABSOLUTE_CHANGE: "RETURNS_6_WEEKS_ABSOLUTE_CHANGE",
  RETURNS_6_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE:
    "RETURNS_6_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE",
  RETURNS_6_WEEKS_ABSOLUTE_LAST_YEAR: "RETURNS_6_WEEKS_ABSOLUTE_LAST_YEAR",
  RETURNS_8_WEEKS_ABSOLUTE: "RETURNS_8_WEEKS_ABSOLUTE",
  RETURNS_8_WEEKS_ABSOLUTE_CHANGE: "RETURNS_8_WEEKS_ABSOLUTE_CHANGE",
  RETURNS_8_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE:
    "RETURNS_8_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE",
  RETURNS_8_WEEKS_ABSOLUTE_LAST_YEAR: "RETURNS_8_WEEKS_ABSOLUTE_LAST_YEAR"
};

const MULTIPACK_DATA_TYPES = {
  // WS
  VALUE_WS: "VALUE_WS",
  VALUE_WS_CHANGE: "VALUE_WS_CHANGE",
  VALUE_WS_CHANGE_PERCENTAGE: "VALUE_WS_CHANGE_PERCENTAGE",
  VALUE_WS_LAST_YEAR: "VALUE_WS_LAST_YEAR",
  QUANTITY_WS: "QUANTITY_WS",
  QUANTITY_WS_CHANGE: "QUANTITY_WS_CHANGE",
  QUANTITY_WS_CHANGE_PERCENTAGE: "QUANTITY_WS_CHANGE_PERCENTAGE",
  QUANTITY_WS_LAST_YEAR: "QUANTITY_WS_LAST_YEAR",
  VALUE_PER_SHOP_WS: "VALUE_PER_SHOP_WS",
  VALUE_PER_SHOP_WS_CHANGE: "VALUE_PER_SHOP_WS_CHANGE",
  VALUE_PER_SHOP_WS_CHANGE_PERCENTAGE: "VALUE_PER_SHOP_WS_CHANGE_PERCENTAGE",
  VALUE_PER_SHOP_WS_LAST_YEAR: "VALUE_PER_SHOP_WS_LAST_YEAR",
  QUANTITY_PER_SHOP_WS: "QUANTITY_PER_SHOP_WS",
  QUANTITY_PER_SHOP_WS_CHANGE: "QUANTITY_PER_SHOP_WS_CHANGE",
  QUANTITY_PER_SHOP_WS_CHANGE_PERCENTAGE:
    "QUANTITY_PER_SHOP_WS_CHANGE_PERCENTAGE",
  QUANTITY_PER_SHOP_WS_LAST_YEAR: "QUANTITY_PER_SHOP_WS_LAST_YEAR",
  VALUE_SHARES_WS: "VALUE_SHARES_WS",
  VALUE_SHARES_WS_CHANGE: "VALUE_SHARES_WS_CHANGE",
  VALUE_SHARES_WS_CHANGE_PERCENTAGE: "VALUE_SHARES_WS_CHANGE_PERCENTAGE",
  VALUE_SHARES_WS_LAST_YEAR: "VALUE_SHARES_WS_LAST_YEAR",
  NET_QUANTITY_SUM_WS: "NET_QUANTITY_SUM_WS",
  NET_QUANTITY_SUM_WS_CHANGE: "NET_QUANTITY_SUM_WS_CHANGE",
  NET_QUANTITY_SUM_WS_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SUM_WS_CHANGE_PERCENTAGE",
  NET_QUANTITY_SUM_WS_LAST_YEAR: "NET_QUANTITY_SUM_WS_LAST_YEAR",
  AVG_NET_QUANTITY_PER_SHOP_WS: "AVG_NET_QUANTITY_PER_SHOP_WS",
  AVG_NET_QUANTITY_PER_SHOP_WS_CHANGE: "AVG_NET_QUANTITY_PER_SHOP_WS_CHANGE",
  AVG_NET_QUANTITY_PER_SHOP_WS_CHANGE_PERCENTAGE:
    "AVG_NET_QUANTITY_PER_SHOP_WS_CHANGE_PERCENTAGE",
  AVG_NET_QUANTITY_PER_SHOP_WS_LAST_YEAR:
    "AVG_NET_QUANTITY_PER_SHOP_WS_LAST_YEAR",
  QUANTITY_SHARES_WS: "QUANTITY_SHARES_WS",
  QUANTITY_SHARES_WS_CHANGE: "QUANTITY_SHARES_WS_CHANGE",
  QUANTITY_SHARES_WS_CHANGE_PERCENTAGE: "QUANTITY_SHARES_WS_CHANGE_PERCENTAGE",
  QUANTITY_SHARES_WS_LAST_YEAR: "QUANTITY_SHARES_WS_LAST_YEAR",
  NET_QUANTITY_SHARE_WS: "NET_QUANTITY_SHARE_WS",
  NET_QUANTITY_SHARE_WS_CHANGE: "NET_QUANTITY_SHARE_WS_CHANGE",
  NET_QUANTITY_SHARE_WS_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SHARE_WS_CHANGE_PERCENTAGE",
  NET_QUANTITY_SHARE_WS_LAST_YEAR: "NET_QUANTITY_SHARE_WS_LAST_YEAR",
  PRICE_AVG_WS: "PRICE_AVG_WS",
  PRICE_AVG_WS_CHANGE: "PRICE_AVG_WS_CHANGE",
  PRICE_AVG_WS_CHANGE_PERCENTAGE: "PRICE_AVG_WS_CHANGE_PERCENTAGE",
  PRICE_AVG_WS_LAST_YEAR: "PRICE_AVG_WS_LAST_YEAR",
  // WSO
  VALUE_WSO: "VALUE_WSO",
  VALUE_WSO_CHANGE: "VALUE_WSO_CHANGE",
  VALUE_WSO_CHANGE_PERCENTAGE: "VALUE_WSO_CHANGE_PERCENTAGE",
  VALUE_WSO_LAST_YEAR: "VALUE_WSO_LAST_YEAR",
  QUANTITY_WSO: "QUANTITY_WSO",
  QUANTITY_WSO_CHANGE: "QUANTITY_WSO_CHANGE",
  QUANTITY_WSO_CHANGE_PERCENTAGE: "QUANTITY_WSO_CHANGE_PERCENTAGE",
  QUANTITY_WSO_LAST_YEAR: "QUANTITY_WSO_LAST_YEAR",
  VALUE_PER_SHOP_WSO: "VALUE_PER_SHOP_WSO",
  VALUE_PER_SHOP_WSO_CHANGE: "VALUE_PER_SHOP_WSO_CHANGE",
  VALUE_PER_SHOP_WSO_CHANGE_PERCENTAGE: "VALUE_PER_SHOP_WSO_CHANGE_PERCENTAGE",
  VALUE_PER_SHOP_WSO_LAST_YEAR: "VALUE_PER_SHOP_WSO_LAST_YEAR",
  QUANTITY_PER_SHOP_WSO: "QUANTITY_PER_SHOP_WSO",
  QUANTITY_PER_SHOP_WSO_CHANGE: "QUANTITY_PER_SHOP_WSO_CHANGE",
  QUANTITY_PER_SHOP_WSO_CHANGE_PERCENTAGE:
    "QUANTITY_PER_SHOP_WSO_CHANGE_PERCENTAGE",
  QUANTITY_PER_SHOP_WSO_LAST_YEAR: "QUANTITY_PER_SHOP_WSO_LAST_YEAR",
  VALUE_SHARES_WSO: "VALUE_SHARES_WSO",
  VALUE_SHARES_WSO_CHANGE: "VALUE_SHARES_WSO_CHANGE",
  VALUE_SHARES_WSO_CHANGE_PERCENTAGE: "VALUE_SHARES_WSO_CHANGE_PERCENTAGE",
  VALUE_SHARES_WSO_LAST_YEAR: "VALUE_SHARES_WSO_LAST_YEAR",
  NET_QUANTITY_SUM_WSO: "NET_QUANTITY_SUM_WSO",
  NET_QUANTITY_SUM_WSO_CHANGE: "NET_QUANTITY_SUM_WSO_CHANGE",
  NET_QUANTITY_SUM_WSO_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SUM_WSO_CHANGE_PERCENTAGE",
  NET_QUANTITY_SUM_WSO_LAST_YEAR: "NET_QUANTITY_SUM_WSO_LAST_YEAR",
  AVG_NET_QUANTITY_PER_SHOP_WSO: "AVG_NET_QUANTITY_PER_SHOP_WSO",
  AVG_NET_QUANTITY_PER_SHOP_WSO_CHANGE: "AVG_NET_QUANTITY_PER_SHOP_WSO_CHANGE",
  AVG_NET_QUANTITY_PER_SHOP_WSO_CHANGE_PERCENTAGE:
    "AVG_NET_QUANTITY_PER_SHOP_WSO_CHANGE_PERCENTAGE",
  AVG_NET_QUANTITY_PER_SHOP_WSO_LAST_YEAR:
    "AVG_NET_QUANTITY_PER_SHOP_WSO_LAST_YEAR",
  QUANTITY_SHARES_WSO: "QUANTITY_SHARES_WSO",
  QUANTITY_SHARES_WSO_CHANGE: "QUANTITY_SHARES_WSO_CHANGE",
  QUANTITY_SHARES_WSO_CHANGE_PERCENTAGE:
    "QUANTITY_SHARES_WSO_CHANGE_PERCENTAGE",
  QUANTITY_SHARES_WSO_LAST_YEAR: "QUANTITY_SHARES_WSO_LAST_YEAR",
  NET_QUANTITY_SHARE_WSO: "NET_QUANTITY_SHARE_WSO",
  NET_QUANTITY_SHARE_WSO_CHANGE: "NET_QUANTITY_SHARE_WSO_CHANGE",
  NET_QUANTITY_SHARE_WSO_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SHARE_WSO_CHANGE_PERCENTAGE",
  NET_QUANTITY_SHARE_WSO_LAST_YEAR: "NET_QUANTITY_SHARE_WSO_LAST_YEAR",
  PRICE_AVG_WSO: "PRICE_AVG_WSO",
  PRICE_AVG_WSO_CHANGE: "PRICE_AVG_WSO_CHANGE",
  PRICE_AVG_WSO_CHANGE_PERCENTAGE: "PRICE_AVG_WSO_CHANGE_PERCENTAGE",
  PRICE_AVG_WSO_LAST_YEAR: "PRICE_AVG_WSO_LAST_YEAR",
  // WSA
  VALUE_WSA: "VALUE_WSA",
  VALUE_WSA_CHANGE: "VALUE_WSA_CHANGE",
  VALUE_WSA_CHANGE_PERCENTAGE: "VALUE_WSA_CHANGE_PERCENTAGE",
  VALUE_WSA_LAST_YEAR: "VALUE_WSA_LAST_YEAR",
  QUANTITY_WSA: "QUANTITY_WSA",
  QUANTITY_WSA_CHANGE: "QUANTITY_WSA_CHANGE",
  QUANTITY_WSA_CHANGE_PERCENTAGE: "QUANTITY_WSA_CHANGE_PERCENTAGE",
  QUANTITY_WSA_LAST_YEAR: "QUANTITY_WSA_LAST_YEAR",
  VALUE_PER_SHOP_WSA: "VALUE_PER_SHOP_WSA",
  VALUE_PER_SHOP_WSA_CHANGE: "VALUE_PER_SHOP_WSA_CHANGE",
  VALUE_PER_SHOP_WSA_CHANGE_PERCENTAGE: "VALUE_PER_SHOP_WSA_CHANGE_PERCENTAGE",
  VALUE_PER_SHOP_WSA_LAST_YEAR: "VALUE_PER_SHOP_WSA_LAST_YEAR",
  QUANTITY_PER_SHOP_WSA: "QUANTITY_PER_SHOP_WSA",
  QUANTITY_PER_SHOP_WSA_CHANGE: "QUANTITY_PER_SHOP_WSA_CHANGE",
  QUANTITY_PER_SHOP_WSA_CHANGE_PERCENTAGE:
    "QUANTITY_PER_SHOP_WSA_CHANGE_PERCENTAGE",
  QUANTITY_PER_SHOP_WSA_LAST_YEAR: "QUANTITY_PER_SHOP_WSA_LAST_YEAR",
  VALUE_SHARES_WSA: "VALUE_SHARES_WSA",
  VALUE_SHARES_WSA_CHANGE: "VALUE_SHARES_WSA_CHANGE",
  VALUE_SHARES_WSA_CHANGE_PERCENTAGE: "VALUE_SHARES_WSA_CHANGE_PERCENTAGE",
  VALUE_SHARES_WSA_LAST_YEAR: "VALUE_SHARES_WSA_LAST_YEAR",
  NET_QUANTITY_SUM_WSA: "NET_QUANTITY_SUM_WSA",
  NET_QUANTITY_SUM_WSA_CHANGE: "NET_QUANTITY_SUM_WSA_CHANGE",
  NET_QUANTITY_SUM_WSA_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SUM_WSA_CHANGE_PERCENTAGE",
  NET_QUANTITY_SUM_WSA_LAST_YEAR: "NET_QUANTITY_SUM_WSA_LAST_YEAR",
  AVG_NET_QUANTITY_PER_SHOP_WSA: "AVG_NET_QUANTITY_PER_SHOP_WSA",
  AVG_NET_QUANTITY_PER_SHOP_WSA_CHANGE: "AVG_NET_QUANTITY_PER_SHOP_WSA_CHANGE",
  AVG_NET_QUANTITY_PER_SHOP_WSA_CHANGE_PERCENTAGE:
    "AVG_NET_QUANTITY_PER_SHOP_WSA_CHANGE_PERCENTAGE",
  AVG_NET_QUANTITY_PER_SHOP_WSA_LAST_YEAR:
    "AVG_NET_QUANTITY_PER_SHOP_WSA_LAST_YEAR",
  QUANTITY_SHARES_WSA: "QUANTITY_SHARES_WSA",
  QUANTITY_SHARES_WSA_CHANGE: "QUANTITY_SHARES_WSA_CHANGE",
  QUANTITY_SHARES_WSA_CHANGE_PERCENTAGE:
    "QUANTITY_SHARES_WSA_CHANGE_PERCENTAGE",
  QUANTITY_SHARES_WSA_LAST_YEAR: "QUANTITY_SHARES_WSA_LAST_YEAR",
  NET_QUANTITY_SHARE_WSA: "NET_QUANTITY_SHARE_WSA",
  NET_QUANTITY_SHARE_WSA_CHANGE: "NET_QUANTITY_SHARE_WSA_CHANGE",
  NET_QUANTITY_SHARE_WSA_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SHARE_WSA_CHANGE_PERCENTAGE",
  NET_QUANTITY_SHARE_WSA_LAST_YEAR: "NET_QUANTITY_SHARE_WSA_LAST_YEAR",
  PRICE_AVG_WSA: "PRICE_AVG_WSA",
  PRICE_AVG_WSA_CHANGE: "PRICE_AVG_WSA_CHANGE",
  PRICE_AVG_WSA_CHANGE_PERCENTAGE: "PRICE_AVG_WSA_CHANGE_PERCENTAGE",
  PRICE_AVG_WSA_LAST_YEAR: "PRICE_AVG_WSA_LAST_YEAR",
  // WS + WSO
  VALUE_WS_WSO: "VALUE_WS_WSO",
  VALUE_WS_WSO_CHANGE: "VALUE_WS_WSO_CHANGE",
  VALUE_WS_WSO_CHANGE_PERCENTAGE: "VALUE_WS_WSO_CHANGE_PERCENTAGE",
  VALUE_WS_WSO_LAST_YEAR: "VALUE_WS_WSO_LAST_YEAR",
  QUANTITY_WS_WSO: "QUANTITY_WS_WSO",
  QUANTITY_WS_WSO_CHANGE: "QUANTITY_WS_WSO_CHANGE",
  QUANTITY_WS_WSO_CHANGE_PERCENTAGE: "QUANTITY_WS_WSO_CHANGE_PERCENTAGE",
  QUANTITY_WS_WSO_LAST_YEAR: "QUANTITY_WS_WSO_LAST_YEAR",
  VALUE_PER_SHOP_WS_WSO: "VALUE_PER_SHOP_WS_WSO",
  VALUE_PER_SHOP_WS_WSO_CHANGE: "VALUE_PER_SHOP_WS_WSO_CHANGE",
  VALUE_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE:
    "VALUE_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE",
  VALUE_PER_SHOP_WS_WSO_LAST_YEAR: "VALUE_PER_SHOP_WS_WSO_LAST_YEAR",
  QUANTITY_PER_SHOP_WS_WSO: "QUANTITY_PER_SHOP_WS_WSO",
  QUANTITY_PER_SHOP_WS_WSO_CHANGE: "QUANTITY_PER_SHOP_WS_WSO_CHANGE",
  QUANTITY_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE:
    "QUANTITY_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE",
  QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR: "QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR",
  VALUE_SHARES_WS_WSO: "VALUE_SHARES_WS_WSO",
  VALUE_SHARES_WS_WSO_CHANGE: "VALUE_SHARES_WS_WSO_CHANGE",
  VALUE_SHARES_WS_WSO_CHANGE_PERCENTAGE:
    "VALUE_SHARES_WS_WSO_CHANGE_PERCENTAGE",
  VALUE_SHARES_WS_WSO_LAST_YEAR: "VALUE_SHARES_WS_WSO_LAST_YEAR",
  NET_QUANTITY_SUM_WS_WSO: "NET_QUANTITY_SUM_WS_WSO",
  NET_QUANTITY_SUM_WS_WSO_CHANGE: "NET_QUANTITY_SUM_WS_WSO_CHANGE",
  NET_QUANTITY_SUM_WS_WSO_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SUM_WS_WSO_CHANGE_PERCENTAGE",
  NET_QUANTITY_SUM_WS_WSO_LAST_YEAR: "NET_QUANTITY_SUM_WS_WSO_LAST_YEAR",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO: "AVG_NET_QUANTITY_PER_SHOP_WS_WSO",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_CHANGE:
    "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_CHANGE",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE:
    "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR:
    "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR",
  QUANTITY_SHARES_WS_WSO: "QUANTITY_SHARES_WS_WSO",
  QUANTITY_SHARES_WS_WSO_CHANGE: "QUANTITY_SHARES_WS_WSO_CHANGE",
  QUANTITY_SHARES_WS_WSO_CHANGE_PERCENTAGE:
    "QUANTITY_SHARES_WS_WSO_CHANGE_PERCENTAGE",
  QUANTITY_SHARES_WS_WSO_LAST_YEAR: "QUANTITY_SHARES_WS_WSO_LAST_YEAR",
  NET_QUANTITY_SHARE_WS_WSO: "NET_QUANTITY_SHARE_WS_WSO",
  NET_QUANTITY_SHARE_WS_WSO_CHANGE: "NET_QUANTITY_SHARE_WS_WSO_CHANGE",
  NET_QUANTITY_SHARE_WS_WSO_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SHARE_WS_WSO_CHANGE_PERCENTAGE",
  NET_QUANTITY_SHARE_WS_WSO_LAST_YEAR: "NET_QUANTITY_SHARE_WS_WSO_LAST_YEAR",
  PRICE_AVG_WS_WSO: "PRICE_AVG_WS_WSO",
  PRICE_AVG_WS_WSO_CHANGE: "PRICE_AVG_WS_WSO_CHANGE",
  PRICE_AVG_WS_WSO_CHANGE_PERCENTAGE: "PRICE_AVG_WS_WSO_CHANGE_PERCENTAGE",
  PRICE_AVG_WS_WSO_LAST_YEAR: "PRICE_AVG_WS_WSO_LAST_YEAR",
  // WS + WSO + WSA
  VALUE_WS_WSO_WSA: "VALUE_WS_WSO_WSA",
  VALUE_WS_WSO_WSA_CHANGE: "VALUE_WS_WSO_WSA_CHANGE",
  VALUE_WS_WSO_WSA_CHANGE_PERCENTAGE: "VALUE_WS_WSO_WSA_CHANGE_PERCENTAGE",
  VALUE_WS_WSO_WSA_LAST_YEAR: "VALUE_WS_WSO_WSA_LAST_YEAR",
  QUANTITY_WS_WSO_WSA: "QUANTITY_WS_WSO_WSA",
  QUANTITY_WS_WSO_WSA_CHANGE: "QUANTITY_WS_WSO_WSA_CHANGE",
  QUANTITY_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "QUANTITY_WS_WSO_WSA_CHANGE_PERCENTAGE",
  QUANTITY_WS_WSO_WSA_LAST_YEAR: "QUANTITY_WS_WSO_WSA_LAST_YEAR",
  VALUE_PER_SHOP_WS_WSO_WSA: "VALUE_PER_SHOP_WS_WSO_WSA",
  VALUE_PER_SHOP_WS_WSO_WSA_CHANGE: "VALUE_PER_SHOP_WS_WSO_WSA_CHANGE",
  VALUE_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "VALUE_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE",
  VALUE_PER_SHOP_WS_WSO_WSA_LAST_YEAR: "VALUE_PER_SHOP_WS_WSO_WSA_LAST_YEAR",
  QUANTITY_PER_SHOP_WS_WSO_WSA: "QUANTITY_PER_SHOP_WS_WSO_WSA",
  QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE: "QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE",
  QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE",
  QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR:
    "QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR",
  VALUE_SHARES_WS_WSO_WSA: "VALUE_SHARES_WS_WSO_WSA",
  VALUE_SHARES_WS_WSO_WSA_CHANGE: "VALUE_SHARES_WS_WSO_WSA_CHANGE",
  VALUE_SHARES_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "VALUE_SHARES_WS_WSO_WSA_CHANGE_PERCENTAGE",
  VALUE_SHARES_WS_WSO_WSA_LAST_YEAR: "VALUE_SHARES_WS_WSO_WSA_LAST_YEAR",
  NET_QUANTITY_SUM_WS_WSO_WSA: "NET_QUANTITY_SUM_WS_WSO_WSA",
  NET_QUANTITY_SUM_WS_WSO_WSA_CHANGE: "NET_QUANTITY_SUM_WS_WSO_WSA_CHANGE",
  NET_QUANTITY_SUM_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SUM_WS_WSO_WSA_CHANGE_PERCENTAGE",
  NET_QUANTITY_SUM_WS_WSO_WSA_LAST_YEAR:
    "NET_QUANTITY_SUM_WS_WSO_WSA_LAST_YEAR",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA: "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE:
    "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE",
  AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR:
    "AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR",
  QUANTITY_SHARES_WS_WSO_WSA: "QUANTITY_SHARES_WS_WSO_WSA",
  QUANTITY_SHARES_WS_WSO_WSA_CHANGE: "QUANTITY_SHARES_WS_WSO_WSA_CHANGE",
  QUANTITY_SHARES_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "QUANTITY_SHARES_WS_WSO_WSA_CHANGE_PERCENTAGE",
  QUANTITY_SHARES_WS_WSO_WSA_LAST_YEAR: "QUANTITY_SHARES_WS_WSO_WSA_LAST_YEAR",
  NET_QUANTITY_SHARE_WS_WSO_WSA: "NET_QUANTITY_SHARE_WS_WSO_WSA",
  NET_QUANTITY_SHARE_WS_WSO_WSA_CHANGE: "NET_QUANTITY_SHARE_WS_WSO_WSA_CHANGE",
  NET_QUANTITY_SHARE_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "NET_QUANTITY_SHARE_WS_WSO_WSA_CHANGE_PERCENTAGE",
  NET_QUANTITY_SHARE_WS_WSO_WSA_LAST_YEAR:
    "NET_QUANTITY_SHARE_WS_WSO_WSA_LAST_YEAR",
  PRICE_AVG_WS_WSO_WSA: "PRICE_AVG_WS_WSO_WSA",
  PRICE_AVG_WS_WSO_WSA_CHANGE: "PRICE_AVG_WS_WSO_WSA_CHANGE",
  PRICE_AVG_WS_WSO_WSA_CHANGE_PERCENTAGE:
    "PRICE_AVG_WS_WSO_WSA_CHANGE_PERCENTAGE",
  PRICE_AVG_WS_WSO_WSA_LAST_YEAR: "PRICE_AVG_WS_WSO_WSA_LAST_YEAR"
};

export const CHART_DATA_TYPE = {
  VALUE: "VALUE",
  VALUE_CHANGE: "VALUE_CHANGE",
  VALUE_CHANGE_PERCENTAGE: "VALUE_CHANGE_PERCENTAGE",
  VALUE_LAST_YEAR: "VALUE_LAST_YEAR",

  VALUE_PERCENT: "VALUE_PERCENT",
  VALUE_PERCENT_LAST_YEAR: "VALUE_PERCENT_LAST_YEAR",

  CALENDAR_DATES: "CALENDAR_DATES",
  CALENDAR_DATES_LAST_YEAR: "CALENDAR_DATES_LAST_YEAR",

  WEEK_DATES: "WEEK_DATES",
  CALENDAR_AND_WEEK_DATES: "CALENDAR_AND_WEEK_DATES",
  WEEK_DATES_LAST_YEAR: "WEEK_DATES_LAST_YEAR",

  QUANTITY: "QUANTITY",
  QUANTITY_CHANGE: "QUANTITY_CHANGE",
  QUANTITY_CHANGE_PERCENTAGE: "QUANTITY_CHANGE_PERCENTAGE",
  QUANTITY_LAST_YEAR: "QUANTITY_LAST_YEAR",

  VALUE_PER_SHOP: "VALUE_PER_SHOP",
  VALUE_PER_SHOP_CHANGE: "VALUE_PER_SHOP_CHANGE",
  VALUE_PER_SHOP_CHANGE_PERCENTAGE: "VALUE_PER_SHOP_CHANGE_PERCENTAGE",
  VALUE_PER_SHOP_LAST_YEAR: "VALUE_PER_SHOP_LAST_YEAR",

  QUANTITY_PER_SHOP: "QUANTITY_PER_SHOP",
  QUANTITY_PER_SHOP_CHANGE: "QUANTITY_PER_SHOP_CHANGE",
  QUANTITY_PER_SHOP_CHANGE_PERCENTAGE: "QUANTITY_PER_SHOP_CHANGE_PERCENTAGE",
  QUANTITY_PER_SHOP_LAST_YEAR: "QUANTITY_PER_SHOP_LAST_YEAR",

  QUANTITY_SUM: "QUANTITY_SUM",
  QUANTITY_SUM_PERCENT: "QUANTITY_SUM_PERCENT",

  TRANSACTIONS: "TRANSACTIONS",
  TRANSACTIONS_LAST_YEAR: "TRANSACTIONS_LAST_YEAR",

  AVG_PRICE: "AVG-PRICE",
  AVG_PRICE_CHANGE: "AVG-PRICE_CHANGE",
  AVG_PRICE_CHANGE_PERCENTAGE: "AVG-PRICE_CHANGE_PERCENTAGE",
  AVG_PRICE_LAST_YEAR: "AVG-PRICE_LAST_YEAR",

  SHOPS: "SHOPS",
  SHOPS_LAST_YEAR: "SHOPS_LAST_YEAR",

  VALUE_SHARES: "VALUE_SHARES",
  VALUE_SHARES_CHANGE: "VALUE_SHARES_CHANGE",
  VALUE_SHARES_CHANGE_PERCENTAGE: "VALUE_SHARES_CHANGE_PERCENTAGE",
  VALUE_SHARES_LAST_YEAR: "VALUE_SHARES_LAST_YEAR",

  VALUE_DYNAMICS: "DYNAMICS_VALUE",
  VALUE_DYNAMICS_LAST_YEAR: "DYNAMICS_VALUE_LAST_YEAR",

  QUANTITY_DYNAMICS: "DYNAMICS_QUANTITY",
  QUANTITY_DYNAMICS_LAST_YEAR: "DYNAMICS_QUANTITY_LAST_YEAR",

  QUANTITY_SHARES: "QUANTITY_SHARES",
  QUANTITY_SHARES_CHANGE: "QUANTITY_SHARES_CHANGE",
  QUANTITY_SHARES_CHANGE_PERCENTAGE: "QUANTITY_SHARES_CHANGE_PERCENTAGE",
  QUANTITY_SHARES_LAST_YEAR: "QUANTITY_SHARES_LAST_YEAR",

  ITEMS_PER_RECEIPT: "ITEMS_PER_RECEIPT",
  ITEMS_PER_RECEIPT_CHANGE: "ITEMS_PER_RECEIPT_CHANGE",
  ITEMS_PER_RECEIPT_CHANGE_PERCENTAGE: "ITEMS_PER_RECEIPT_CHANGE_PERCENTAGE",
  ITEMS_PER_RECEIPT_LAST_YEAR: "ITEMS_PER_RECEIPT_LAST_YEAR",

  WORTH_PER_RECEIPT: "WORTH_PER_RECEIPT",
  WORTH_PER_RECEIPT_CHANGE: "WORTH_PER_RECEIPT_CHANGE",
  WORTH_PER_RECEIPT_CHANGE_PERCENTAGE: "WORTH_PER_RECEIPT_CHANGE_PERCENTAGE",
  WORTH_PER_RECEIPT_LAST_YEAR: "WORTH_PER_RECEIPT_LAST_YEAR",

  RECEIPTS_PER_SHOP: "RECEIPTS_PER_SHOP",
  RECEIPTS_PER_SHOP_CHANGE: "RECEIPTS_PER_SHOP_CHANGE",
  RECEIPTS_PER_SHOP_CHANGE_PERCENTAGE: "RECEIPTS_PER_SHOP_CHANGE_PERCENTAGE",
  RECEIPTS_PER_SHOP_LAST_YEAR: "RECEIPTS_PER_SHOP_LAST_YEAR",

  RECEIPTS_QUANTITY: "RECEIPTS_QUANTITY",
  RECEIPTS_QUANTITY_CHANGE: "RECEIPTS_QUANTITY_CHANGE",
  RECEIPTS_QUANTITY_CHANGE_PERCENTAGE: "RECEIPTS_QUANTITY_CHANGE_PERCENTAGE",
  RECEIPTS_QUANTITY_LAST_YEAR: "RECEIPTS_QUANTITY_LAST_YEAR",

  SHARE_IN_RECEIPTS_CATEGORY: "SHARE_IN_RECEIPTS_CATEGORY",
  SHARE_IN_RECEIPTS_CATEGORY_CHANGE: "SHARE_IN_RECEIPTS_CATEGORY_CHANGE",
  SHARE_IN_RECEIPTS_CATEGORY_CHANGE_PERCENTAGE:
    "SHARE_IN_RECEIPTS_CATEGORY_CHANGE_PERCENTAGE",
  SHARE_IN_RECEIPTS_CATEGORY_LAST_YEAR: "SHARE_IN_RECEIPTS_CATEGORY_LAST_YEAR",

  SHARE_IN_RECEIPTS_TOTAL_SHOP: "SHARE_IN_RECEIPTS_TOTAL_SHOP",
  SHARE_IN_RECEIPTS_TOTAL_SHOP_CHANGE: "SHARE_IN_RECEIPTS_TOTAL_SHOP_CHANGE",
  SHARE_IN_RECEIPTS_TOTAL_SHOP_CHANGE_PERCENTAGE:
    "SHARE_IN_RECEIPTS_TOTAL_SHOP_CHANGE_PERCENTAGE",
  SHARE_IN_RECEIPTS_TOTAL_SHOP_LAST_YEAR:
    "SHARE_IN_RECEIPTS_TOTAL_SHOP_LAST_YEAR",

  NONE: "",

  AVG_SHOPS_COUNT: "AVG_SHOPS_COUNT",
  AVG_SHOPS_COUNT_CHANGE: "AVG_SHOPS_COUNT_CHANGE",
  AVG_SHOPS_COUNT_CHANGE_PERCENTAGE: "AVG_SHOPS_COUNT_CHANGE_PERCENTAGE",
  AVG_SHOPS_COUNT_LAST_YEAR: "AVG_SHOPS_COUNT_LAST_YEAR",

  AVG_PLANOGRAM_SHOPS_COUNT: "AVG_PLANOGRAM_SHOPS_COUNT",
  AVG_PLANOGRAM_SHOPS_COUNT_CHANGE: "AVG_PLANOGRAM_SHOPS_COUNT_CHANGE",
  AVG_PLANOGRAM_SHOPS_COUNT_CHANGE_PERCENTAGE:
    "AVG_PLANOGRAM_SHOPS_COUNT_CHANGE_PERCENTAGE",
  AVG_PLANOGRAM_SHOPS_COUNT_LAST_YEAR: "AVG_PLANOGRAM_SHOPS_COUNT_LAST_YEAR",

  DISTRIBUTION_RANGE: "DISTRIBUTION_RANGE",
  DISTRIBUTION_RANGE_CHANGE: "DISTRIBUTION_RANGE_CHANGE",
  DISTRIBUTION_RANGE_CHANGE_PERCENTAGE: "DISTRIBUTION_RANGE_CHANGE_PERCENTAGE",
  DISTRIBUTION_RANGE_LAST_YEAR: "DISTRIBUTION_RANGE_LAST_YEAR",

  PLANOGRAM_DISTRIBUTION_RANGE: "PLANOGRAM_DISTRIBUTION_RANGE",
  PLANOGRAM_DISTRIBUTION_RANGE_CHANGE: "PLANOGRAM_DISTRIBUTION_RANGE_CHANGE",
  PLANOGRAM_DISTRIBUTION_RANGE_CHANGE_PERCENTAGE:
    "PLANOGRAM_DISTRIBUTION_RANGE_CHANGE_PERCENTAGE",
  PLANOGRAM_DISTRIBUTION_RANGE_LAST_YEAR:
    "PLANOGRAM_DISTRIBUTION_RANGE_LAST_YEAR",

  TOTAL_SUPPLY: "TOTAL_SUPPLY",
  SHOPS_SUPPLY: "SHOPS_SUPPLY",
  WPZ_SHOPS_7: "WPZ_SHOPS_7",
  INCOMING_SUPPLY: "INCOMING_SUPPLY",
  CL_TOTAL_SUPPLY: "CL_TOTAL_SUPPLY",
  KM_SUPPLY: "KM_SUPPLY",

  WPZ_CL_TOTAL_7: "WPZ_CL_TOTAL_7",
  BED_SUPPLY: "BED_SUPPLY",
  WPZ_BED_7: "WPZ_BED_7",
  NAD_SUPPLY: "NAD_SUPPLY",
  WPZ_NAD_7: "WPZ_NAD_7",
  PLE_SUPPLY: "PLE_SUPPLY",
  WPZ_PLE_7: "WPZ_PLE_7",
  SZA_SUPPLY: "SZA_SUPPLY",
  WPZ_SZA_7: "WPZ_SZA_7",
  TYCH_SUPPLY: "TYCH_SUPPLY",
  WPZ_TYCH_7: "WPZ_TYCH_7",
  TYN_SUPPLY: "TYN_SUPPLY",
  WPZ_TYN_7: "WPZ_TYN_7",
  RADZ_SUPPLY: "RADZ_SUPPLY",
  WPZ_RADZ_7: "WPZ_RADZ_7",
  WPZ_KM_7: "WPZ_KM_7",

  WPZ_SHOPS_30: "WPZ_SHOPS_30",
  WPZ_CL_TOTAL_30: "WPZ_CL_TOTAL_30",
  WPZ_BED_30: "WPZ_BED_30",
  WPZ_NAD_30: "WPZ_NAD_30",
  WPZ_PLE_30: "WPZ_PLE_30",
  WPZ_SZA_30: "WPZ_SZA_30",
  WPZ_TYCH_30: "WPZ_TYCH_30",
  WPZ_TYN_30: "WPZ_TYN_30",
  WPZ_RADZ_30: "WPZ_RADZ_30",
  WPZ_KM_30: "WPZ_KM_30",

  WPZ_SHOPS_90: "WPZ_SHOPS_90",
  WPZ_CL_TOTAL_90: "WPZ_CL_TOTAL_90",
  WPZ_BED_90: "WPZ_BED_90",
  WPZ_NAD_90: "WPZ_NAD_90",
  WPZ_PLE_90: "WPZ_PLE_90",
  WPZ_SZA_90: "WPZ_SZA_90",
  WPZ_TYCH_90: "WPZ_TYCH_90",
  WPZ_TYN_90: "WPZ_TYN_90",
  WPZ_RADZ_90: "WPZ_RADZ_90",
  WPZ_KM_90: "WPZ_KM_90",

  KWRO_SUPPLY: "KWRO_SUPPLY",
  WPZ_KWRO_7: "WPZ_KWRO_7",
  WPZ_KWRO_30: "WPZ_KWRO_30",
  WPZ_KWRO_90: "WPZ_KWRO_90",

  NET_QUANTITY_SALES: "NET_QUANTITY_SALES",
  NET_QUANTITY_SALES_CHANGE: "NET_QUANTITY_SALES_CHANGE",
  NET_QUANTITY_SALES_CHANGE_PERCENTAGE: "NET_QUANTITY_SALES_CHANGE_PERCENTAGE",
  NET_QUANTITY_SALES_LAST_YEAR: "NET_QUANTITY_SALES_LAST_YEAR",

  AVG_NET_QUANTITY_PER_SHOP: "AVG_NET_QUANTITY_PER_SHOP",
  AVG_NET_QUANTITY_PER_SHOP_CHANGE: "AVG_NET_QUANTITY_PER_SHOP_CHANGE",
  AVG_NET_QUANTITY_PER_SHOP_CHANGE_PERCENTAGE:
    "AVG_NET_QUANTITY_PER_SHOP_CHANGE_PERCENTAGE",
  AVG_NET_QUANTITY_PER_SHOP_LAST_YEAR: "AVG_NET_QUANTITY_PER_SHOP_LAST_YEAR",

  NET_QUANTITY_SHARE: "NET_QUANTITY_SHARE",
  NET_QUANTITY_SHARE_CHANGE: "NET_QUANTITY_SHARE_CHANGE",
  NET_QUANTITY_SHARE_CHANGE_PERCENTAGE: "NET_QUANTITY_SHARE_CHANGE_PERCENTAGE",
  NET_QUANTITY_SHARE_LAST_YEAR: "NET_QUANTITY_SHARE_LAST_YEAR",

  DYNAMICS_NET_QUANTITY_CHANGE: "DYNAMICS_NET_QUANTITY_CHANGE",
  DYNAMICS_NET_QUANTITY_CHANGE_LAST_YEAR:
    "DYNAMICS_NET_QUANTITY_CHANGE_LAST_YEAR",

  NET_QUANTITY_PER_RECEIPT: "NET_QUANTITY_PER_RECEIPT",
  NET_QUANTITY_PER_RECEIPT_CHANGE: "NET_QUANTITY_PER_RECEIPT_CHANGE",
  NET_QUANTITY_PER_RECEIPT_CHANGE_PERCENTAGE:
    "NET_QUANTITY_PER_RECEIPT_CHANGE_PERCENTAGE",
  NET_QUANTITY_PER_RECEIPT_LAST_YEAR: "NET_QUANTITY_PER_RECEIPT_LAST_YEAR",

  AVG_TEMP: "AVG_TEMP",
  AVG_TEMP_CHANGE: "AVG_TEMP_CHANGE",
  AVG_TEMP_CHANGE_PERCENTAGE: "AVG_TEMP_CHANGE_PERCENTAGE",
  AVG_TEMP_LAST_YEAR: "AVG_TEMP_LAST_YEAR",

  AVG_RAINFALL: "AVG_RAINFALL",
  AVG_RAINFALL_CHANGE: "AVG_RAINFALL_CHANGE",
  AVG_RAINFALL_CHANGE_PERCENTAGE: "AVG_RAINFALL_CHANGE_PERCENTAGE",
  AVG_RAINFALL_LAST_YEAR: "AVG_RAINFALL_LAST_YEAR",

  ...LOYALTY_DATA_TYPES,
  ...PROMOTION_DATA_TYPES,
  ...MULTIPACK_DATA_TYPES
} as const;

export const isWeatherDataType = (dataType: ChartDataTypes) =>
  dataType === CHART_DATA_TYPE.AVG_RAINFALL ||
  dataType === CHART_DATA_TYPE.AVG_TEMP;

export const INTEGER_CHART_DATA_TYPES: ChartDataTypes[] = [
  CHART_DATA_TYPE.ITEMS_PER_RECEIPT,
  CHART_DATA_TYPE.RECEIPTS_QUANTITY,
  CHART_DATA_TYPE.VALUE_SHARES,
  CHART_DATA_TYPE.AVG_SHOPS_COUNT
];

export const METRIC_TYPE = {
  PP: "PP",
  PERCENT: "PERCENT",
  ABSOLUTE: "ABSOLUTE",
  PRICE: "PRICE",
  PIECE: "PIECE"
} as const;

export const DISTRIBUTION_CHART_DATA_TYPE = [
  CHART_DATA_TYPE.AVG_SHOPS_COUNT,
  CHART_DATA_TYPE.DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT
] as const;

export const WEATHER_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_TEMP,
  CHART_DATA_TYPE.AVG_RAINFALL
] as const;

export const PERIOD_TYPE = {
  DAYS: "DAY" as const,
  WEEKS: "WEEK" as const,
  PROMO_WEEKS: "PROMO_WEEK" as const,
  MONTHS: "MONTH" as const,
  QUARTER: "QUARTER" as const,
  YEAR: "YEAR" as const,
  TOTAL: "TOTAL" as const,
  RANKING: "RANKING" as const
} as const;

export const PERIOD_TYPE_PL = {
  RANKING: "Ranking",
  DAY: "Dzień",
  WEEK: "Tydzień",
  PROMO_WEEK: "Tydz. promo.",
  MONTH: "Miesiąc",
  QUARTER: "Kwartał",
  YEAR: "Rok",
  TOTAL: "Total"
};

export const LOGISTICS_DATA_TYPES_PARAMS = {
  SINGLE_DAY: "single_day",
  HISTORY: "history"
} as const;

export const LOGISTICS_DATA_TYPES = [
  { id: LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY, label: "Dzisiaj" },
  { id: LOGISTICS_DATA_TYPES_PARAMS.HISTORY, label: "Historia" }
];

export const CHART_COLORS = {
  colorChart0: "#006420",
  colorChart1: "#6d89b6",
  colorChart2: "#ffa644",
  colorChart3: "#ca1d08",
  colorChart4: "#754198",
  colorChart5: "#e167a9",
  colorChart6: "#54c4ff",
  colorChart7: "#56C35C",
  colorChart8: "#BA1400",
  colorChart9: "#207EAF"
};

const FONT_COLORS = {
  white: "#fff",
  black: "#000"
};
//these should match
export const CHART_FONT_COLORS = {
  [CHART_COLORS.colorChart0]: FONT_COLORS.white,
  [CHART_COLORS.colorChart1]: FONT_COLORS.white,
  [CHART_COLORS.colorChart2]: FONT_COLORS.black,
  [CHART_COLORS.colorChart3]: FONT_COLORS.white,
  [CHART_COLORS.colorChart4]: FONT_COLORS.white,
  [CHART_COLORS.colorChart5]: FONT_COLORS.white,
  [CHART_COLORS.colorChart6]: FONT_COLORS.black,
  [CHART_COLORS.colorChart7]: FONT_COLORS.black,
  [CHART_COLORS.colorChart8]: FONT_COLORS.white,
  [CHART_COLORS.colorChart9]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor1]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor2]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor3]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor4]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor5]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor6]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor7]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor8]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor9]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor10]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor11]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor12]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor13]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor14]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor15]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor16]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor17]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor18]: FONT_COLORS.black,
  [RED_BULL_PRODUCT_COLORS.redBullColor19]: FONT_COLORS.white,
  [RED_BULL_PRODUCT_COLORS.redBullColor20]: FONT_COLORS.white
};

export const WEATHER_CHART_COLOR = "#dbd800";

export const RANKING_CHART_COLORS = {
  OWN: "#006420",
  COMPETITOR: "#5a65d9"
};

export const CLIENT_TYPES = {
  OFFLINE: "Offline",
  ONLINE: "Online",
  ALL: "all"
};

export const VOIVODESHIPS_ITEMS = [
  {
    value: "dolnośląskie",
    label: "dolnośląskie"
  },
  {
    value: "kujawsko-pomorskie",
    label: "kujawsko-pomorskie"
  },
  {
    value: "lubelskie",
    label: "lubelskie"
  },
  {
    value: "lubuskie",
    label: "lubuskie"
  },
  {
    value: "łódzkie",
    label: "łódzkie"
  },
  {
    value: "mazowieckie",
    label: "mazowieckie"
  },
  {
    value: "małopolskie",
    label: "małopolskie"
  },
  {
    value: "opolskie",
    label: "opolskie"
  },
  {
    value: "podkarpackie",
    label: "podkarpackie"
  },
  {
    value: "podlaskie",
    label: "podlaskie"
  },
  {
    value: "pomorskie",
    label: "pomorskie"
  },
  {
    value: "śląskie",
    label: "śląskie"
  },
  {
    value: "świętokrzyskie",
    label: "świętokrzyskie"
  },
  {
    value: "warmińsko-mazurskie",
    label: "warmińsko-mazurskie"
  },
  {
    value: "wielkopolskie",
    label: "wielkopolskie"
  },
  {
    value: "zachodniopomorskie",
    label: "zachodniopomorskie"
  }
];

export const DATE_DROPDOWN_INPUT_ID = "date-dropdown-input";

// Matches "DATE_FRORM - DATE_TO" string for dates in formats:
// yyy-mm-dd, yyyy.mm.dd and yyyy/mm/dd
export const DATE_DROPDOWN_REGEXP = /^([12]\d{3}(\.|-|\/)(0[1-9]|1[0-2])(\.|-|\/)(0[1-9]|[12]\d|3[01])) - ([12]\d{3}(\.|-|\/)(0[1-9]|1[0-2])(\.|-|\/)(0[1-9]|[12]\d|3[01]))$/;

// Matches date in format yyyy-mm-dd
export const DATE_QUERY_PARAM_REGEXP = /^([12]\d{3}(-)(0[1-9]|1[0-2])(-)(0[1-9]|[12]\d|3[01]))$/;

export const ALERT_TYPES = {
  CRITICAL: "critical",
  SUCCES: "success"
} as const;

export const USER_REQUIRED_COOKIE_NAME = "ZBIcookieRequired";
export const USER_ANALYTICS_COOKIE_NAME = "ZBIcookieAnalytics";
export const REDIRECT_PATH_KEY_NAME = "ZBILoginRedirect";

export const USER_ACTIVITY_EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress"
];

export const RWD_POPUP = "RWD_POPUP";
export const MD_BREAKPOINT = 768;

export const IS_PRELOGIN_INFO_HIDDEN = "IS_PRELOGIN_INFO_HIDDEN";
export const USER_DATA = "USER_DATA";
export const KNOWLEDGE_BASE_VERSION = "KNOWLEDGE_BASE_VERSION";
export const NEWS_BASE_VERSION = "NEWS_BASE_VERSION";
export const DATE_PARAMS = "DATE_PARAMS";

export const FEATURE_LOCAL_STORAGE_KEYS = {
  SIDEBAR_INSIGHTS_KEY: "insightywszedziewidze",
  LOGISTICS_SEGMENTS: "logistykanasegmencie",
  METASEGMENTS_FILTER: "metasegmentacja",
  REFERENCE_BRANDS_FILTER: "zmarkowanereferencje",
  PROMOTIONS_REPORT: "promolove",
  MULTIPACK_WSA_METRICS: "roznicowaniecenowe",
  GROUPED_BAR_CHART_SORTING: "sortowaniewykresugrupowanego",
  BAR_CHART_TABLE: "tabelawykresuslupkowego"
};

export const MODAL_KEYS = {
  IS_LOGISTICS_BETA_VERSION_MODAL_HIDDEN:
    "IS_LOGISTICS_BETA_VERSION_MODAL_HIDDEN",
  IS_HOURS_MODAL_HIDDEN: "IS_HOURS_MODAL_HIDDEN",
  IS_INSIGHTS_MODAL_HIDDEN: "IS_INSIGHTS_MODAL_HIDDEN",
  IS_MULTIPACK_MODAL_HIDDEN: "IS_MULTIPACK_MODAL_HIDDEN",
  IS_LOYALTY_MODAL_HIDDEN: "IS_LOYALTY_MODAL_HIDDEN",
  IS_SELL_MODAL_HIDDEN: "IS_SELL_MODAL_HIDDEN"
};

export const TOURS_KEYS = {
  IS_BRAND_TOUR_HIDDEN: "IS_BRAND_TOUR_HIDDEN",
  IS_NATURAL_UNITS_TOUR_HIDDEN: "IS_NATURAL_UNITS_TOUR_HIDDEN",
  IS_WEATHER_DATA_TOUR_HIDDEN: "IS_WEATHER_DATA_TOUR_HIDDEN",
  IS_REF_CAT_TOUR_HIDDEN: "IS_REF_CAT_TOUR_HIDDEN",
  IS_AGGREGATION_DROPDOWN_TOUR_HIDDEN: "IS_AGGREGATION_DROPDOWN_TOUR_HIDDEN",
  IS_RANKING_TOUR_HIDDEN: "IS_RANKING_TOUR_HIDDEN",
  IS_TOTAL_TOUR_HIDDEN: "IS_TOTAL_TOUR_HIDDEN",
  IS_PROMO_WEEK_TOUR_HIDDEN: "IS_PROMO_WEEK_TOUR_HIDDEN",
  IS_EXPORT_ARCHIVE_TOUR_HIDDEN: "IS_EXPORT_ARCHIVE_TOUR_HIDDEN",
  IS_HISTORICAL_SECTION_TOUR_HIDDEN: "IS_HISTORICAL_SECTION_TOUR_HIDDEN",
  IS_EXCLUDED_DAYS_TOUR_HIDDEN: "IS_EXCLUDED_DAYS_TOUR_HIDDEN",
  IS_DYNAMIC_PERIOD_TOUR_HIDDEN: "IS_DYNAMIC_PERIOD_TOUR_HIDDEN",
  IS_PRODUCT_HIERARCHY_TOUR_HIDDEN: "IS_PRODUCT_HIERARCHY_TOUR_HIDDEN",
  IS_EXPANDABLE_SIDEBAR_TOUR_HIDDEN: "IS_EXPANDABLE_SIDEBAR_TOUR_HIDDEN",
  IS_SORTABLE_SIDEBAR_TOUR_HIDDEN: "IS_SORTABLE_SIDEBAR_TOUR_HIDDEN",
  IS_SELECTING_SIDEBAR_TOUR_HIDDEN: "IS_SELECTING_SIDEBAR_TOUR_HIDDEN",
  IS_SIDEBAR_CHANGE_TOUR_HIDDEN: "IS_SIDEBAR_CHANGE_TOUR_HIDDEN",
  IS_CHART_TYPE_TOUR_HIDDEN: "IS_CHART_TYPE_TOUR_HIDDEN_V2",
  IS_AREA_CHART_TOUR_HIDDEN: "IS_AREA_CHART_TOUR_HIDDEN",
  IS_INCREMENTAL_CHART_TOUR_HIDDEN: "IS_INCREMENTAL_CHART_TOUR_HIDDEN",
  IS_INSIGHTS_NAV_TOUR_HIDDEN: "IS_INSIGHTS_NAV_TOUR_HIDDEN",
  IS_EXPORT_NAV_TOUR_HIDDEN: "IS_EXPORT_NAV_TOUR_HIDDEN",
  IS_GROUP_BY_SEGMENTS_TOUR_HIDDEN: "IS_GROUP_BY_SEGMENTS_TOUR_HIDDEN",
  IS_MULTIPACK_METRICS_TOUR_HIDDEN: "IS_MULTIPACK_METRICS_TOUR_HIDDEN",
  IS_HOURS_QUANTITY_METRIC_TOUR_HIDDEN: "IS_HOURS_QUANTITY_METRIC_TOUR_HIDDEN",
  IS_LOGISTICS_SEGMENTS_TOUR_HIDDEN: "IS_LOGISTICS_SEGMENTS_TOUR_HIDDEN",
  IS_LOYALTY_METRICS_TOUR_HIDDEN: "IS_LOYALTY_METRICS_TOUR_HIDDEN",
  IS_CHART_TILES_TOUR_HIDDEN: "IS_CHART_TILES_TOUR_HIDDEN",
  IS_DRAGGABLE_TILES_TOUR_HIDDEN: "IS_DRAGGABLE_TILES_TOUR_HIDDEN",
  IS_PRODUCT_DROPDOWN_SORTING_TOUR_HIDDEN:
    "IS_PRODUCT_DROPDOWN_SORTING_TOUR_HIDDEN",
  IS_CLIENT_ZAPPKA_FILTER_TOUR_HIDDEN: "IS_CLIENT_ZAPPKA_FILTER_TOUR_HIDDEN",
  IS_ZAPPKA_TRANSACTION_TOUR_HIDDEN: "IS_ZAPPKA_TRANSACTION_TOUR_HIDDEN",
  IS_ZAPPKA_SHARES_TOUR_HIDDEN: "IS_ZAPPKA_SHARES_TOUR_HIDDEN",
  IS_DATASET_SELECT_TOUR_HIDDEN: "IS_DATASET_SELECT_TOUR_HIDDEN",
  IS_PREDEFINED_OPTIONS_TOUR_HIDDEN: "IS_PREDEFINED_OPTIONS_TOUR_HIDDEN",
  IS_FULL_SCREEN_TOUR_HIDDEN: "IS_FULL_SCREEN_TOUR_HIDDEN",
  IS_ATTRIBUTES_TOUR_HIDDEN: "IS_ATTRIBUTES_TOUR_HIDDEN"
};

export const PROMPT_KEYS = {
  IS_INCONSISTENT_DATA_PROMPT_HIDDEN: "IS_INCONSISTENT_DATA_PROMPT_HIDDEN",
  IS_REMOVE_HISTORICAL_PROMPT_HIDDEN: "IS_REMOVE_HISTORICAL_PROMPT_HIDDEN",
  IS_EXPORT_REDIRECT_PROMPT_HIDDEN: "IS_EXPORT_REDIRECT_PROMPT_HIDDEN"
};

export const REGION_TYPES = {
  VOIVODESHIP: "voivodeship",
  COUNTY: "county"
} as const;

export const REPORTS_SUMMARY_LABELS = {
  WORTH_SUM: "Wartość sprzedaży (brutto)",
  QUANTITY_COUNT: "Liczba sztuk",
  WORTH_DYNAMICS: "Dynamika sprzedaży (%)",
  QUANTITY_DYNAMICS: "Dynamika sztuk (%)",
  AVERAGE_PRICE: "Średnia cena (brutto)",
  AVERAGE_WORTH: "Średnia sprzedaż dziennie (brutto)",
  WORTH_SUM_PER_SHOP: "Wartość sprzedaży\nper sklep (brutto)",
  AVERAGE_WORTH_DAILY_PER_SHOP: "Średnia sprzedaż dziennie\nper sklep (brutto)",
  AVERAGE_QUANTITY_SUM_PER_SHOP: "Średnia liczba szt.\nper sklep",
  AVERAGE_QUANTITY_SUM_DAILY_PER_SHOP:
    "Średnia liczba szt. dziennie\nper sklep",
  SHARE_IN_RECEIPTS_TOTAL_SHOP: "Udział w liczbie\nparagonów total Żabka (%)",
  SHARE_IN_RECEIPTS_CATEGORY: "Udział w liczbie\nparagonów w kategorii (%)",
  ITEMS_PER_RECEIPT: "Średnia liczba szt. \n/ 1 paragon",
  WORTH_PER_RECEIPT: "Średnia wartość \n/ 1 paragon",
  RECEIPTS_QUANTITY: "Liczba paragonów",
  RECEIPTS_PER_SHOP: "Średnia liczba\nparagonów / 1 sklep",
  AVG_SHOPS_COUNT: "Średnia liczba sklepów",
  AVG_PLANOGRAM_SHOPS_COUNT: "Średnia liczba sklepów planogramowych",
  AVG_SHOPS_COUNT_WITH_LINEBREAK: "Średnia liczba\nsklepów",
  DISTRIBUTION_RANGE: "Dystrybucja %",
  BEST_HOUR: "Najlepsza godzina",
  WORST_HOUR: "Najgorsza godzina",
  BEST_DAY: "Najlepszy dzień tyg.",
  WORST_DAY: "Najgorszy dzień tyg.",
  BEST_DATE: "Najlepszy dzień i godz.",
  NET_QUANTITY_SALES_SUM: "Sprzedaż w jn",
  AVG_DAILY_NET_QUANTITY_SALES: "Średnia sprzedaż dziennie w jn",
  AVG_DAILY_NET_QUANTITY_PER_SHOP: "Średnia liczba jn dziennie per sklep",
  AVG_NET_QUANTITY_PER_SHOP: "Jn per sklep",
  NET_QUANTITY_PER_RECEIPT: "Średnia liczba jn na 1 par.",
  PLANOGRAM_DISTRIBUTION_RANGE: "Dystrybucja % planogramowa",

  NEW_CUSTOMERS_COUNT: "Liczba nowych klientów",
  NEW_CUSTOMERS_PERCENT: "% nowych klientów",
  ALL_RETURNS_PERCENT: "% wszystkich powrotów",
  RETURNS_1_WEEK_PERCENT: "% powrotów w 1. tyg.",
  RETURNS_2_WEEKS_PERCENT: "% powrotów w 2. tyg.",
  RETURNS_4_WEEKS_PERCENT: "% powrotów w 3-4 tyg.",
  RETURNS_6_WEEKS_PERCENT: "% powrotów w 5-6 tyg.",
  RETURNS_8_WEEKS_PERCENT: "% powrotów w 7-8 tyg.",

  RETURNS_1_WEEK_ABSOLUTE: "Liczba powrotów w 1. tyg.",
  RETURNS_2_WEEKS_ABSOLUTE: "Liczba powrotów w 2. tyg.",
  RETURNS_4_WEEKS_ABSOLUTE: "Liczba powrotów w 3-4 tyg.",
  RETURNS_6_WEEKS_ABSOLUTE: "Liczba powrotów w 5-6 tyg.",
  RETURNS_8_WEEKS_ABSOLUTE: "Liczba powrotów w 7-8 tyg."
};

export const POLISH_MONTH_NAMES = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień"
];

export const POLISH_WEEK_DAYS = [
  "niedziela",
  "poniedziałek",
  "wtorek",
  "środa",
  "czwartek",
  "piątek",
  "sobota"
];
export const POLISH_WEEK_DAYS_SHORT = [
  "niedz.",
  "pon.",
  "wt.",
  "śr.",
  "czw.",
  "pt.",
  "sob."
];

export const POLISH_ENGLISH_WEEK_DAYS_SHORT = [
  {
    polishName: "pon.",
    englishName: "mon"
  },
  {
    polishName: "wt.",
    englishName: "tue"
  },
  {
    polishName: "śr.",
    englishName: "wed"
  },
  {
    polishName: "czw.",
    englishName: "thu"
  },
  {
    polishName: "pt.",
    englishName: "fri"
  },
  {
    polishName: "sob.",
    englishName: "sat"
  },
  {
    polishName: "niedz.",
    englishName: "sun"
  }
];

export const BOOL_STRING_VALUES = {
  TRUE: "true",
  FALSE: "false"
};

export const isLogisticsSegmentsEnabled =
  localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.LOGISTICS_SEGMENTS) ===
  BOOL_STRING_VALUES.TRUE;

export const isReferenceBrandsFilterEnabled =
  localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.REFERENCE_BRANDS_FILTER) ===
  BOOL_STRING_VALUES.TRUE;

export const isMetaSegmentFilterEnabled =
  localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.METASEGMENTS_FILTER) ===
  BOOL_STRING_VALUES.TRUE;

export const isMultipackWsaEnabled =
  localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.MULTIPACK_WSA_METRICS) ===
  BOOL_STRING_VALUES.TRUE;

export const isGroupedBarChartSortingEnabled =
  localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.GROUPED_BAR_CHART_SORTING) ===
  BOOL_STRING_VALUES.TRUE;

export const isBarChartTableEnabled =
  localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.BAR_CHART_TABLE) ===
  BOOL_STRING_VALUES.TRUE;

export const TOOLTIP_ORIENTATIONS = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal"
} as const;

export const NO_DATA = "Brak danych";
export const NO_DATA_SHORT = "b/d";
export const NULL_LABEL = "brak";

// oldest day with data available
export const DATE_FROM_RESTRICTION = new Date(2018, 0, 1);

export const CONTACT_EMAIL = "acit@zabka.pl";

export const DATASET_TYPES = {
  OWN: "own",
  COMPETITOR: "competitor",
  ATTRIBUTES: "own_competitor",
  REFERENCE: "reference",
  REFERENCE_BRAND: "reference_brand"
} as const;

export const CHART_SORT_ORDER_BY_TYPE = [
  DATASET_TYPES.OWN,
  DATASET_TYPES.COMPETITOR,
  DATASET_TYPES.ATTRIBUTES,
  DATASET_TYPES.REFERENCE,
  DATASET_TYPES.REFERENCE_BRAND
] as const;

export enum CHART_TYPES {
  LINE = "line",
  AREA = "area",
  GROUPED_BAR = "grouped_bar",
  INCREMENTAL = "incremental",
  COMBO = "combo"
}

export const DEFAULT_CHART_TICKS_AMOUNT = 7;

export const INPUT_TYPE = {
  SELECT: "select",
  TEXT: "text",
  PASSWORD: "password"
};

export const PHONE_REGEXP = /^\d{7}(?:\d{2})?$/;
export const DATE_REGEXP = /\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])*/;

export const PROMOTIONS_FOR_DAY_PERIOD_ONLY =
  "Pokazywanie promocji możliwe obecnie tylko dla agregacji dziennej oraz tyg. promo.";

export const PROMOTIONS_FOR_HISTORICAL_DATA_ONLY =
  "Informacje o promocjach dostępne w ramach danych historycznych";

const STATUTE_URL = "/regulamin.pdf";
export const PRIVACY_POLICY_URL = "/polityka-prywatnosci.pdf";
export const TRUSTED_PARTNERS_URL = "/zaufani-partnerzy.pdf";
export const COOKIES_DETAILS_URL = "/szczegoly-cookies.pdf";

export const TOC_PRIVACY_POLICY_LINKS = [
  { label: "Regulamin", href: STATUTE_URL },
  {
    label: "Polityka prywatności",
    href: PRIVACY_POLICY_URL
  }
];

export const WARNINGS = {
  DISTRIBUTION_VISIBLE_FOR_OWN_DATA:
    "Dane dot. dystrybucji są pokazywane tylko dla danych własnych",
  DOUBLED_RECEIPTS:
    "Dane paragonowe są przeliczone dla pojedynczych indeksów lub grup asortymentowych - w przypadku wybrania większej liczby SKU lub kategorii G2/G3 część paragonów może być zdublowana. W celu uzyskania dodatkowych informacji prosimy o kontakt z BDA.",
  INCOMPLETE_DATA:
    "Uwaga! Informacje dot. konkurencji nie zawierają danych z ostatnich 4 tygodni!",
  RANKING_ONLY_OWN_DATA: "Uwaga! Ranking uwzględnia tylko produkty własne.",
  DYNAMICS_WEEK_DATA_WARNING:
    "Dane dot. dynamiki mogą być zaburzone ze względu na przesunięcia tygodni kalendarzowych w poszczególnych latach.",
  RANKING_UNDER_5_PERCENT_HIDDEN:
    "Ranking nie uwzględnia produktów z dystrybucją <5%",
  NO_DATA_TYPE_SELECTED:
    "Wybierz przynajmniej jedną miarę do pokazania na wykresie",
  HOURS_VOIV_SELECTED: "Dane godzinowe pokazywane są dla terenu całej Polski.",
  HOURS_LFL_SELECTED: "Dane godzinowe pokazywane są bez uwzględnienia LFL",
  HOURS_LFL_AND_VOIV_SELECTED:
    "Dane godzinowe pokazywane są dla terenu całej Polski oraz bez uwzględnienia LFL",
  LFL_SELECTED:
    "Prezentowane dane są z uwzględnieniem LFL. Pamiętaj, że LFL odnosi się do bazy sklepów rok wcześniej. Rekomendujemy weryfikację danych z uwzględnieniem LFL dla okresu maksymalnie 12 miesięcy lub sprawdzanie wyników w raporcie Dynamika.",
  SEGMENTS_REGION_SELECTED: "Dane pokazywane są dla terenu całej Polski",
  AREA_CHART: "Wybierz okres bazowy i historyczny, aby zobaczyć wykres pasmowy",
  INCREMENTAL_CHART:
    "Wybierz okres bazowy i historyczny, aby zobaczyć wykres przyrostowy",
  COMPETITION_DISABLED_ON_THIS_REPORT:
    "Dane konkurencji są niedostępne w tym raporcie",
  MAX_SHOP_COUNT:
    "Do wyliczania miar per sklep w przypadku wybrania większej liczby SKU lub kategorii G2/G3, marki/submarki bierzemy pod uwagę maksymalną liczbę sklepów. W celu uzyskania dodatkowych informacji prosimy o kontakt z BDA."
};

export const ONLY_SINGLE_SKU_VISIBLE =
  "Dane dostępne tylko dla pojedynczego SKU";

// workaround requested by client to give access to G3 category without selecting G2 for bacardi company
export const UNLOCKED_CATEGORIES_COMPANIES = ["0000000070"];

export const LOGISTICS_DATA_TYPES_ONLY_IN_HISTORICAL =
  "Opcja dostępna w ramach danych historycznych";

export const TABLE_COLUMN_ITEM_LABEL = "item";

export const DEFAULT_DATA_EXPORT_DROPDOWN_HEADER = "Wybierz dane do eksportu";
export const LOGISTICS_DATA_EXPORT_DROPDOWN_HEADER =
  "Wybierz zakres danych do eksportu (XLS)";

export const NO_COMPETITION_DATA_COPYING_ALLOWED =
  "Brak możliwości skopiowania danych konkurencji.";

export const INSIGHTS_ALLOWED_TIERS = [3, 4];

export const AGGREGATED_COMPETITION_ALLOWED_TIER = 2;

export const PROMO_WEEK_START_DAY = 3;

export const PROMOTION_COLORS = {
  [PROMOTION_DATA_TYPES.ZESTAWY]: "#ffa644",
  [PROMOTION_DATA_TYPES.PODKLADKA_ALKOHOLOWA]: "#e167a9",
  [PROMOTION_DATA_TYPES.ODLICZANIE]: "#54c4ff",
  [PROMOTION_DATA_TYPES.ODLICZANIE_WSO]: "#f17b00",
  [PROMOTION_DATA_TYPES.MENUBOARD]: "#96db9c",
  [PROMOTION_DATA_TYPES.KATALOG]: "#e5d200",
  [PROMOTION_DATA_TYPES.KATALOG_WSO]: "#6c6c6c",
  [PROMOTION_DATA_TYPES.HAPPY_HOURS]: "#437a43",
  [PROMOTION_DATA_TYPES.GAZETKA]: "#754198",
  [PROMOTION_DATA_TYPES.GAZETKA_WSO]: "#5a65d9",
  [PROMOTION_DATA_TYPES.APLIKACJA]: "#c7483a",
  [PROMOTION_DATA_TYPES.AKCJA_POLECAMY]: "#43b649",
  [PROMOTION_DATA_TYPES.UNKNOWN_PROMOTION]: "#00eeff",
  [PROMOTION_DATA_TYPES.WIELOSZTUKA]: "#fac748",
  [PROMOTION_DATA_TYPES.WIELOSZTUKA_SUPER_OFERTA]: "#a288e3",
  [PROMOTION_DATA_TYPES.WIELOSZTUKA_APLIKACYJNA]: "#e3a288",
  [PROMOTION_DATA_TYPES.APLIKACJA_WSO]: "#ff3399",
  [PROMOTION_DATA_TYPES.APLIKACJA_WSO_MULTIPACK]: "#ff3399",
  [PROMOTION_DATA_TYPES.ZESTAWY_APLIKACJA]: "#1426c4"
};

export const PROMOTIONS_ENABLED_PERIODS = [
  PERIOD_TYPE.DAYS,
  PERIOD_TYPE.PROMO_WEEKS,
  PERIOD_TYPE.RANKING
] as Periods[];

export const COLOR = {
  UI_3: "#74859f",
  UI_9: "#43b649",
  UI_15: "#888889",
  UI_17: "#cad3e5",
  BAR_CHANGE_RED: "#e11d48",
  BAR_CHANGE_GREEN: "#22c55e"
};

export const PRODUCTION_URL = "acit.zabka.pl";
