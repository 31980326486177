import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import cn from "classnames";

import { Checkbox } from "components/molecules";

import s from "./multiSelect.module.scss";

export const MultiSelect = ({
  testId,
  label,
  options,
  selected,
  className = "",
  onClear,
  onChange
}: {
  testId: string;
  label: string;
  options: { label: string; value: string }[];
  selected: string[];
  className?: string;
  onClear: () => void;
  onChange: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);

  const labels = options
    .filter(option => selected.includes(option.value))
    .map(option => option.label);

  return (
    <div className={cn(s.multiselect, className)} data-testid={testId}>
      <label className={s.label} htmlFor="trigger">
        {label}
      </label>
      <div className={s.inputWrapper}>
        <input
          id="trigger"
          className={s.input}
          type="text"
          value={labels.join(", ")}
          onClick={() => setOpen(true)}
          placeholder="Wybierz"
          readOnly
        />
        {open && (
          <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <div id="content" className={s.content}>
              <div className={s.contentHeader}>
                <p className={s.headerSummary}>
                  {`Wybrano ${selected.length} z ${options.length}`}
                </p>
                <button className={s.headerButton} onClick={onClear}>
                  Wyczyść
                </button>
              </div>
              <div className={s.contentBody}>
                {options.map(option => (
                  <Checkbox
                    key={option.value}
                    className={{
                      base: s.checkboxBase,
                      checkbox: s.checkboxInput,
                      label: s.checkboxLabel
                    }}
                    label={option.label}
                    isChecked={selected.includes(option.value)}
                    onChange={() => onChange(option.value)}
                  />
                ))}
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
};
