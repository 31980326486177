import cn from "classnames";

import { ArrowDown, ArrowUp, TooltipIcon } from "components/atoms/Icon";
import { Nullable } from "utils/types";

import s from "./summaryColumn.module.scss";

type Dataset = {
  first: string;
  second: Nullable<string>;
  third: string;
  change: number;
};

const Arrow = ({ trend }: { trend: number }) => {
  if (!trend || trend < 0) {
    return (
      <ArrowDown className={cn(s.arrow, trend < 0 ? s.negative : s.neutral)} />
    );
  }
  return <ArrowUp className={cn(s.arrow, s.positive)} />;
};

export const SummaryColumn = ({
  label,
  tooltip = "",
  primary,
  secondary
}: {
  label: string;
  tooltip?: string;
  primary: Dataset;
  secondary: Dataset;
}) => {
  const showBottomDetails = [
    secondary.second,
    secondary.third,
    secondary.change
  ].some(Boolean);
  return (
    <div className={s.container}>
      <div className={s.labelWrapper}>
        <p className={s.label}>{label}</p>
        {tooltip.length > 0 && (
          <div className={s.tooltip} data-tooltip={tooltip}>
            <TooltipIcon />
          </div>
        )}
      </div>
      <div className={s.baseWrapper}>
        <p className={s.base}>{primary.first}</p>
        {secondary.first.length > 0 && (
          <p className={s.baseSecondary}>{secondary.first}</p>
        )}
      </div>
      <div className={s.details}>
        <Arrow trend={primary.change} />
        <p className={s.detail}>{primary.second}</p>
        <span className={s.separator} />
        <p className={s.detail}>{primary.third}</p>
        {showBottomDetails && (
          <>
            <Arrow trend={secondary.change} />
            <p className={s.detail}>{secondary.second}</p>
            <span className={s.separator} />
            <p className={s.detail}>{secondary.third}</p>
          </>
        )}
      </div>
    </div>
  );
};
