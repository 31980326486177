import { PERIOD_TYPE, PERIOD_TYPE_PL } from "utils/constants";

export const getPolishLabelForTimePeriod = (period: string) => {
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return PERIOD_TYPE_PL.DAY;
    case PERIOD_TYPE.WEEKS:
      return PERIOD_TYPE_PL.WEEK;
    case PERIOD_TYPE.PROMO_WEEKS:
      return PERIOD_TYPE_PL.PROMO_WEEK;
    case PERIOD_TYPE.MONTHS:
      return PERIOD_TYPE_PL.MONTH;
    case PERIOD_TYPE.QUARTER:
      return PERIOD_TYPE_PL.QUARTER;
    case PERIOD_TYPE.YEAR:
      return PERIOD_TYPE_PL.YEAR;
    case PERIOD_TYPE.TOTAL:
      return PERIOD_TYPE_PL.TOTAL;
    default:
      return "Okres";
  }
};
