import { ChartDataTypes } from "pages/Reports/types/chart";
import { pickWs } from "pages/Reports/utils/pickWs";
import { CHART_DATA_TYPE } from "utils";

import { createDataTypeMap } from "./createDataTypeMap";

// this "prettier-ignore" is needed to keep map keys in a single line for better readability
// prettier-ignore
const keysMap = {
  ...createDataTypeMap(CHART_DATA_TYPE.VALUE, "worthSum", true),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY, "quantitySum", true),
  ...createDataTypeMap(CHART_DATA_TYPE.VALUE_PER_SHOP, "worthSumPerShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY_PER_SHOP, "quantitySumPerShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.AVG_PRICE, "priceAvg"),
  ...createDataTypeMap(CHART_DATA_TYPE.NET_QUANTITY_SALES, "netQuantitySales", true),
  ...createDataTypeMap(CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP, "avgNetQuantityPerShop"),

  ...createDataTypeMap(CHART_DATA_TYPE.VALUE_SHARES, "worthShare"),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY_SHARES, "quantityShare"),
  ...createDataTypeMap(CHART_DATA_TYPE.NET_QUANTITY_SHARE, "netQuantityShare"),

  ...createDataTypeMap(CHART_DATA_TYPE.VALUE_DYNAMICS, "worthChange"),
  ...createDataTypeMap(CHART_DATA_TYPE.QUANTITY_DYNAMICS, "quantityChange"),
  ...createDataTypeMap(CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE, "netQuantityChange"),

  ...createDataTypeMap(CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY, "shareInReceiptsCategory"),
  ...createDataTypeMap(CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP, "shareInReceiptsTotalShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.ITEMS_PER_RECEIPT, "itemsPerReceipt"),
  ...createDataTypeMap(CHART_DATA_TYPE.WORTH_PER_RECEIPT, "worthPerReceipt"),
  ...createDataTypeMap(CHART_DATA_TYPE.RECEIPTS_PER_SHOP, "receiptsPerShop"),
  ...createDataTypeMap(CHART_DATA_TYPE.RECEIPTS_QUANTITY, "receiptsQuantity", true),
  ...createDataTypeMap(CHART_DATA_TYPE.NET_QUANTITY_PER_RECEIPT, "netQuantityPerReceipt"),

  [CHART_DATA_TYPE.AVG_SHOPS_COUNT]: "avgShopsCount",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT_CHANGE]: "avgShopsCountChange",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT_CHANGE_PERCENTAGE]: "avgShopsCountChangePercentage",
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT_LAST_YEAR]: "avgShopsCountLastYear",

  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT]: "avgPlanogramShopsCount",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT_CHANGE]: "avgPlanogramShopsCountChange",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT_CHANGE_PERCENTAGE]: "avgPlanogramShopsCountChangePercentage",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT_LAST_YEAR]: "avgPlanogramShopsCountLastYear",

  [CHART_DATA_TYPE.DISTRIBUTION_RANGE]: "distributionRange",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE_CHANGE]: "distributionRangeChange",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE_CHANGE_PERCENTAGE]: "distributionRangeChangePercentage",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE_LAST_YEAR]: "distributionRangeLastYear",

  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE]: "planogramDistributionRange",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_CHANGE]: "planogramDistributionRangeChange",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_CHANGE_PERCENTAGE]: "planogramDistributionRangeChangePercentage",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_LAST_YEAR]: "planogramDistributionRangeLastYear",

  [CHART_DATA_TYPE.TOTAL_SUPPLY]: "totalSupply",
  [CHART_DATA_TYPE.SHOPS_SUPPLY]: "shopsSupply",
  [CHART_DATA_TYPE.WPZ_SHOPS_7]: "wpzShops7",
  [CHART_DATA_TYPE.INCOMING_SUPPLY]: "incomingSupply",
  [CHART_DATA_TYPE.CL_TOTAL_SUPPLY]: "clTotalSupply",
  [CHART_DATA_TYPE.KM_SUPPLY]: "kmSupply",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_7]: "wpzClTotal7",
  [CHART_DATA_TYPE.BED_SUPPLY]: "bedSupply",
  [CHART_DATA_TYPE.WPZ_BED_7]: "wpzBed7",
  [CHART_DATA_TYPE.NAD_SUPPLY]: "nadSupply",
  [CHART_DATA_TYPE.WPZ_NAD_7]: "wpzNad7",
  [CHART_DATA_TYPE.PLE_SUPPLY]: "pleSupply",
  [CHART_DATA_TYPE.WPZ_PLE_7]: "wpzPle7",
  [CHART_DATA_TYPE.SZA_SUPPLY]: "szaSupply",
  [CHART_DATA_TYPE.WPZ_SZA_7]: "wpzSza7",
  [CHART_DATA_TYPE.TYCH_SUPPLY]: "tychSupply",
  [CHART_DATA_TYPE.WPZ_TYCH_7]: "wpzTych7",
  [CHART_DATA_TYPE.TYN_SUPPLY]: "tynSupply",
  [CHART_DATA_TYPE.RADZ_SUPPLY]: "radzSupply",
  [CHART_DATA_TYPE.WPZ_TYN_7]: "wpzTyn7",
  [CHART_DATA_TYPE.WPZ_RADZ_7]: "wpzRadz7",
  [CHART_DATA_TYPE.WPZ_KM_7]: "wpzKm7",
  [CHART_DATA_TYPE.WPZ_KM_30]: "wpzKm30",
  [CHART_DATA_TYPE.WPZ_KM_90]: "wpzKm90",
  [CHART_DATA_TYPE.WPZ_SHOPS_30]: "wpzShops30",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_30]: "wpzClTotal30",
  [CHART_DATA_TYPE.WPZ_BED_30]: "wpzBed30",
  [CHART_DATA_TYPE.WPZ_NAD_30]: "wpzNad30",
  [CHART_DATA_TYPE.WPZ_PLE_30]: "wpzPle30",
  [CHART_DATA_TYPE.WPZ_SZA_30]: "wpzSza30",
  [CHART_DATA_TYPE.WPZ_TYCH_30]: "wpzTych30",
  [CHART_DATA_TYPE.WPZ_TYN_30]: "wpzTyn30",
  [CHART_DATA_TYPE.WPZ_RADZ_30]: "wpzRadz30",
  [CHART_DATA_TYPE.WPZ_SHOPS_90]: "wpzShops90",
  [CHART_DATA_TYPE.WPZ_CL_TOTAL_90]: "wpzClTotal90",
  [CHART_DATA_TYPE.WPZ_BED_90]: "wpzBed90",
  [CHART_DATA_TYPE.WPZ_NAD_90]: "wpzNad90",
  [CHART_DATA_TYPE.WPZ_PLE_90]: "wpzPle90",
  [CHART_DATA_TYPE.WPZ_SZA_90]: "wpzSza90",
  [CHART_DATA_TYPE.WPZ_TYCH_90]: "wpzTych90",
  [CHART_DATA_TYPE.WPZ_TYN_90]: "wpzTyn90",
  [CHART_DATA_TYPE.WPZ_RADZ_90]: "wpzRadz90",
  [CHART_DATA_TYPE.KWRO_SUPPLY]: "kwroSupply",
  [CHART_DATA_TYPE.WPZ_KWRO_7]: "wpzKwro7",
  [CHART_DATA_TYPE.WPZ_KWRO_30]: "wpzKwro30",
  [CHART_DATA_TYPE.WPZ_KWRO_90]: "wpzKwro90",

  [CHART_DATA_TYPE.AVG_TEMP]: "avgTemp",
  [CHART_DATA_TYPE.AVG_TEMP_LAST_YEAR]: "avgTempLastYear",

  [CHART_DATA_TYPE.AVG_RAINFALL]: "avgRainfall",
  [CHART_DATA_TYPE.AVG_RAINFALL_LAST_YEAR]: "avgRainfallLastYear",

  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT]: "loyaltyNewClientsCount",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT_CHANGE]: "loyaltyNewClientsCountChange",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT_CHANGE_PERCENTAGE]: "loyaltyNewClientsCountChangePercentage",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT_LAST_YEAR]: "loyaltyNewClientsCountLastYear",

  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT]: "loyaltyNewClientsPercent",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT_CHANGE]: "loyaltyNewClientsPercentChange",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT_CHANGE_PERCENTAGE]: "loyaltyNewClientsPercentChangePercentage",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT_LAST_YEAR]: "loyaltyNewClientsPercentLastYear",

  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT]: "loyaltyBackPercent",
  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT_CHANGE]: "loyaltyBackPercentChange",
  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT_CHANGE_PERCENTAGE]: "loyaltyBackPercentChangePercentage",
  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT_LAST_YEAR]: "loyaltyBackPercentLastYear",

  [CHART_DATA_TYPE.ALL_RETURNS_COUNT]: "loyaltyBackCount",
  [CHART_DATA_TYPE.ALL_RETURNS_COUNT_CHANGE]: "loyaltyBackCountChange",
  [CHART_DATA_TYPE.ALL_RETURNS_COUNT_CHANGE_PERCENTAGE]: "loyaltyBackCountChangePercentage",
  [CHART_DATA_TYPE.ALL_RETURNS_COUNT_LAST_YEAR]: "loyaltyBackCountLastYear",

  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT]: "loyaltyBackShare1w",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT_CHANGE]: "loyaltyBackShare1wChange",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT_CHANGE_PERCENTAGE]: "loyaltyBackShare1wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT_LAST_YEAR]: "loyaltyBackShare1wLastYear",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT]: "loyaltyBackShare2w",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT_CHANGE]: "loyaltyBackShare2wChange",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT_CHANGE_PERCENTAGE]: "loyaltyBackShare2wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare2wLastYear",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT]: "loyaltyBackShare4w",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT_CHANGE]: "loyaltyBackShare4wChange",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT_CHANGE_PERCENTAGE]: "loyaltyBackShare4wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare4wLastYear",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT]: "loyaltyBackShare6w",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT_CHANGE]: "loyaltyBackShare6wChange",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT_CHANGE_PERCENTAGE]: "loyaltyBackShare6wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare6wLastYear",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT]: "loyaltyBackShare8w",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT_CHANGE]: "loyaltyBackShare8wChange",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT_CHANGE_PERCENTAGE]: "loyaltyBackShare8wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT_LAST_YEAR]: "loyaltyBackShare8wLastYear",

  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE]: "loyaltyBackAbsolute1w",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE_CHANGE]: "loyaltyBackAbsolute1wChange",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE_CHANGE_PERCENTAGE]: "loyaltyBackAbsolute1wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute1wLastYear",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute2w",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE_CHANGE]: "loyaltyBackAbsolute2wChange",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE]: "loyaltyBackAbsolute2wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute2wLastYear",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute4w",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE_CHANGE]: "loyaltyBackAbsolute4wChange",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE]: "loyaltyBackAbsolute4wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute4wLastYear",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute6w",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE_CHANGE]: "loyaltyBackAbsolute6wChange",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE]: "loyaltyBackAbsolute6wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute6wLastYear",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE]: "loyaltyBackAbsolute8w",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE_CHANGE]: "loyaltyBackAbsolute8wChange",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE_CHANGE_PERCENTAGE]: "loyaltyBackAbsolute8wChangePercentage",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE_LAST_YEAR]: "loyaltyBackAbsolute8wLastYear",

  [CHART_DATA_TYPE.VALUE_WS]: pickWs("worthSumWs", "worthSumWsWsa"),
  [CHART_DATA_TYPE.VALUE_WS_CHANGE]: pickWs("worthSumWsChange", "worthSumWsWsaChange"),
  [CHART_DATA_TYPE.VALUE_WS_CHANGE_PERCENTAGE]: pickWs("worthSumWsChangePercentage", "worthSumWsWsaChangePercentage"),
  [CHART_DATA_TYPE.VALUE_WS_LAST_YEAR]: pickWs("worthSumWsLastYear", "worthSumWsWsaLastYear"),

  [CHART_DATA_TYPE.VALUE_WSO]: "worthSumWso",
  [CHART_DATA_TYPE.VALUE_WSO_CHANGE]: "worthSumWsoChange",
  [CHART_DATA_TYPE.VALUE_WSO_CHANGE_PERCENTAGE]: "worthSumWsoChangePercentage",
  [CHART_DATA_TYPE.VALUE_WSO_LAST_YEAR]: "worthSumWsoLastYear",

  [CHART_DATA_TYPE.VALUE_WSA]: "worthSumWsa",
  [CHART_DATA_TYPE.VALUE_WSA_CHANGE]: "worthSumWsaChange",
  [CHART_DATA_TYPE.VALUE_WSA_CHANGE_PERCENTAGE]: "worthSumWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_WSA_LAST_YEAR]: "worthSumWsaLastYear",

  [CHART_DATA_TYPE.VALUE_WS_WSO]: "worthSumWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_WS_WSO_CHANGE]: "worthSumWsWsoWsaChange",
  [CHART_DATA_TYPE.VALUE_WS_WSO_CHANGE_PERCENTAGE]: "worthSumWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_WS_WSO_LAST_YEAR]: "worthSumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA]: "worthSumWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA_CHANGE]: "worthSumWsWsoWsaChange",
  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA_CHANGE_PERCENTAGE]: "worthSumWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA_LAST_YEAR]: "worthSumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_WS]: pickWs("quantitySumWs", "quantitySumWsWsa"),
  [CHART_DATA_TYPE.QUANTITY_WS_CHANGE]: pickWs("quantitySumWsChange", "quantitySumWsWsaChange"),
  [CHART_DATA_TYPE.QUANTITY_WS_CHANGE_PERCENTAGE]: pickWs("quantitySumWsChangePercentage", "quantitySumWsWsaChangePercentage"),
  [CHART_DATA_TYPE.QUANTITY_WS_LAST_YEAR]: pickWs("quantitySumWsLastYear", "quantitySumWsWsaLastYear"),

  [CHART_DATA_TYPE.QUANTITY_WSO]: "quantitySumWso",
  [CHART_DATA_TYPE.QUANTITY_WSO_CHANGE]: "quantitySumWsoChange",
  [CHART_DATA_TYPE.QUANTITY_WSO_CHANGE_PERCENTAGE]: "quantitySumWsoChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_WSO_LAST_YEAR]: "quantitySumWsoLastYear",

  [CHART_DATA_TYPE.QUANTITY_WSA]: "quantitySumWsa",
  [CHART_DATA_TYPE.QUANTITY_WSA_CHANGE]: "quantitySumWsaChange",
  [CHART_DATA_TYPE.QUANTITY_WSA_CHANGE_PERCENTAGE]: "quantitySumWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_WSA_LAST_YEAR]: "quantitySumWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_WS_WSO]: "quantitySumWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_CHANGE]: "quantitySumWsWsoWsaChange",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_CHANGE_PERCENTAGE]: "quantitySumWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_LAST_YEAR]: "quantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA]: "quantitySumWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA_CHANGE]: "quantitySumWsWsoWsaChange",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA_CHANGE_PERCENTAGE]: "quantitySumWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA_LAST_YEAR]: "quantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS]: pickWs("worthSumPerShopWs", "worthSumPerShopWsWsa"),
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_CHANGE]: pickWs("worthSumPerShopWsChange", "worthSumPerShopWsWsaChange"),
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_CHANGE_PERCENTAGE]: pickWs("worthSumPerShopWsChangePercentage", "worthSumPerShopWsWsaChangePercentage"),
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_LAST_YEAR]: pickWs("worthSumPerShopWsLastYear", "worthSumPerShopWsWsaLastYear"),

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO]: "worthSumPerShopWso",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO_CHANGE]: "worthSumPerShopWsoChange",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO_CHANGE_PERCENTAGE]: "worthSumPerShopWsoChangePercentage",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO_LAST_YEAR]: "worthSumPerShopWsoLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA]: "worthSumPerShopWsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA_CHANGE]: "worthSumPerShopWsaChange",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA_CHANGE_PERCENTAGE]: "worthSumPerShopWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA_LAST_YEAR]: "worthSumPerShopWsaLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO]: "worthSumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_CHANGE]: "worthSumPerShopWsWsoWsaChange",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE]: "worthSumPerShopWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_LAST_YEAR]: "worthSumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA]: "worthSumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA_CHANGE]: "worthSumPerShopWsWsoWsaChange",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE]: "worthSumPerShopWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA_LAST_YEAR]: "worthSumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS]: pickWs("quantitySumPerShopWs", "quantitySumPerShopWsWsa"),
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_CHANGE]: pickWs("quantitySumPerShopWsChange", "quantitySumPerShopWsWsaChange"),
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_CHANGE_PERCENTAGE]: pickWs("quantitySumPerShopWsChangePercentage", "quantitySumPerShopWsWsaChangePercentage"),
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_LAST_YEAR]: pickWs("quantitySumPerShopWsLastYear", "quantitySumPerShopWsWsaLastYear"),

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO]: "quantitySumPerShopWso",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO_CHANGE]: "quantitySumPerShopWsoChange",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO_CHANGE_PERCENTAGE]: "quantitySumPerShopWsoChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO_LAST_YEAR]: "quantitySumPerShopWsoLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA]: "quantitySumPerShopWsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA_CHANGE]: "quantitySumPerShopWsaChange",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA_CHANGE_PERCENTAGE]: "quantitySumPerShopWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA_LAST_YEAR]: "quantitySumPerShopWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO]: "quantitySumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_CHANGE]: "quantitySumPerShopWsWsoWsaChange",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE]: "quantitySumPerShopWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR]: "quantitySumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA]: "quantitySumPerShopWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE]: "quantitySumPerShopWsWsoWsaChange",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE]: "quantitySumPerShopWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR]: "quantitySumPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WS]: pickWs("worthShareTotalWs", "worthShareTotalWsWsa"),
  [CHART_DATA_TYPE.VALUE_SHARES_WS_CHANGE]: pickWs("worthShareTotalWsChange", "worthShareTotalWsWsaChange"),
  [CHART_DATA_TYPE.VALUE_SHARES_WS_CHANGE_PERCENTAGE]: pickWs("worthShareTotalWsChangePercentage", "worthShareTotalWsWsaChangePercentage"),
  [CHART_DATA_TYPE.VALUE_SHARES_WS_LAST_YEAR]: pickWs("worthShareTotalWsLastYear", "worthShareTotalWsWsaLastYear"),

  [CHART_DATA_TYPE.VALUE_SHARES_WSO]: "worthShareTotalWso",
  [CHART_DATA_TYPE.VALUE_SHARES_WSO_CHANGE]: "worthShareTotalWsoChange",
  [CHART_DATA_TYPE.VALUE_SHARES_WSO_CHANGE_PERCENTAGE]: "worthShareTotalWsoChangePercentage",
  [CHART_DATA_TYPE.VALUE_SHARES_WSO_LAST_YEAR]: "worthShareTotalWsoLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WSA]: "worthShareTotalWsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WSA_CHANGE]: "worthShareTotalWsaChange",
  [CHART_DATA_TYPE.VALUE_SHARES_WSA_CHANGE_PERCENTAGE]: "worthShareTotalWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_SHARES_WSA_LAST_YEAR]: "worthShareTotalWsaLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO]: "worthShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_CHANGE]: "worthShareTotalWsWsoWsaChange",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_CHANGE_PERCENTAGE]: "worthShareTotalWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_LAST_YEAR]: "worthShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA]: "worthShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA_CHANGE]: "worthShareTotalWsWsoWsaChange",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA_CHANGE_PERCENTAGE]: "worthShareTotalWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA_LAST_YEAR]: "worthShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS]: pickWs("quantityShareTotalWs", "quantityShareTotalWsWsa"),
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_CHANGE]: pickWs("quantityShareTotalWsChange", "quantityShareTotalWsWsaChange"),
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_CHANGE_PERCENTAGE]: pickWs("quantityShareTotalWsChangePercentage", "quantityShareTotalWsWsaChangePercentage"),
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_LAST_YEAR]: pickWs("quantityShareTotalWsLastYear", "quantityShareTotalWsWsaLastYear"),

  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO]: "quantityShareTotalWso",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO_CHANGE]: "quantityShareTotalWsoChange",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO_CHANGE_PERCENTAGE]: "quantityShareTotalWsoChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO_LAST_YEAR]: "quantityShareTotalWsoLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA]: "quantityShareTotalWsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA_CHANGE]: "quantityShareTotalWsaChange",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA_CHANGE_PERCENTAGE]: "quantityShareTotalWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA_LAST_YEAR]: "quantityShareTotalWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO]: "quantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_CHANGE]: "quantityShareTotalWsWsoWsaChange",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_CHANGE_PERCENTAGE]: "quantityShareTotalWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_LAST_YEAR]: "quantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA]: "quantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA_CHANGE]: "quantityShareTotalWsWsoWsaChange",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA_CHANGE_PERCENTAGE]: "quantityShareTotalWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA_LAST_YEAR]: "quantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS]: pickWs("netQuantityShareTotalWs", "netQuantityShareTotalWsWsa"),
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_CHANGE]: pickWs("netQuantityShareTotalWsChange", "netQuantityShareTotalWsWsaChange"),
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_CHANGE_PERCENTAGE]: pickWs("netQuantityShareTotalWsChangePercentage", "netQuantityShareTotalWsWsaChangePercentage"),
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_LAST_YEAR]: pickWs("netQuantityShareTotalWsLastYear", "netQuantityShareTotalWsWsaLastYear"),

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO]: "netQuantityShareTotalWso",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO_CHANGE]: "netQuantityShareTotalWsoChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO_CHANGE_PERCENTAGE]: "netQuantityShareTotalWsoChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO_LAST_YEAR]: "netQuantityShareTotalWsoLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA]: "netQuantityShareTotalWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA_CHANGE]: "netQuantityShareTotalWsaChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA_CHANGE_PERCENTAGE]: "netQuantityShareTotalWsaChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA_LAST_YEAR]: "netQuantityShareTotalWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO]: "netQuantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_CHANGE]: "netQuantityShareTotalWsWsoWsaChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_CHANGE_PERCENTAGE]: "netQuantityShareTotalWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_LAST_YEAR]: "netQuantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA]: "netQuantityShareTotalWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA_CHANGE]: "netQuantityShareTotalWsWsoWsaChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA_CHANGE_PERCENTAGE]: "netQuantityShareTotalWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA_LAST_YEAR]: "netQuantityShareTotalWsWsoWsaLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WS]: pickWs("priceAvgWs", "priceAvgWsWsa"),
  [CHART_DATA_TYPE.PRICE_AVG_WS_CHANGE]: pickWs("priceAvgWsChange", "priceAvgWsWsaChange"),
  [CHART_DATA_TYPE.PRICE_AVG_WS_CHANGE_PERCENTAGE]: pickWs("priceAvgWsChangePercentage", "priceAvgWsWsaChangePercentage"),
  [CHART_DATA_TYPE.PRICE_AVG_WS_LAST_YEAR]: pickWs("priceAvgWsLastYear", "priceAvgWsWsaLastYear"),

  [CHART_DATA_TYPE.PRICE_AVG_WSO]: "priceAvgWso",
  [CHART_DATA_TYPE.PRICE_AVG_WSO_CHANGE]: "priceAvgWsoChange",
  [CHART_DATA_TYPE.PRICE_AVG_WSO_CHANGE_PERCENTAGE]: "priceAvgWsoChangePercentage",
  [CHART_DATA_TYPE.PRICE_AVG_WSO_LAST_YEAR]: "priceAvgWsoLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WSA]: "priceAvgWsa",
  [CHART_DATA_TYPE.PRICE_AVG_WSA_CHANGE]: "priceAvgWsaChange",
  [CHART_DATA_TYPE.PRICE_AVG_WSA_CHANGE_PERCENTAGE]: "priceAvgWsaChangePercentage",
  [CHART_DATA_TYPE.PRICE_AVG_WSA_LAST_YEAR]: "priceAvgWsaLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO]: "priceAvgWsWsoWsa",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_CHANGE]: "priceAvgWsWsoWsaChange",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_CHANGE_PERCENTAGE]: "priceAvgWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_LAST_YEAR]: "priceAvgWsWsoWsaLastYear",

  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA]: "priceAvgWsWsoWsa",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA_CHANGE]: "priceAvgWsWsoWsaChange",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA_CHANGE_PERCENTAGE]: "priceAvgWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA_LAST_YEAR]: "priceAvgWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS]: pickWs("netQuantitySumWs", "netQuantitySumWsWsa"),
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_CHANGE]: pickWs("netQuantitySumWsChange", "netQuantitySumWsWsaChange"),
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_CHANGE_PERCENTAGE]: pickWs("netQuantitySumWsChangePercentage", "netQuantitySumWsWsaChangePercentage"),
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_LAST_YEAR]: pickWs("netQuantitySumWsLastYear", "netQuantitySumWsWsaLastYear"),

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO]: "netQuantitySumWso",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO_CHANGE]: "netQuantitySumWsoChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO_CHANGE_PERCENTAGE]: "netQuantitySumWsoChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO_LAST_YEAR]: "netQuantitySumWsoLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA]: "netQuantitySumWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA_CHANGE]: "netQuantitySumWsaChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA_CHANGE_PERCENTAGE]: "netQuantitySumWsaChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA_LAST_YEAR]: "netQuantitySumWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO]: "netQuantitySumWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_CHANGE]: "netQuantitySumWsWsoWsaChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_CHANGE_PERCENTAGE]: "netQuantitySumWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_LAST_YEAR]: "netQuantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA]: "netQuantitySumWsWsoWsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA_CHANGE]: "netQuantitySumWsWsoWsaChange",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA_CHANGE_PERCENTAGE]: "netQuantitySumWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA_LAST_YEAR]: "netQuantitySumWsWsoWsaLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS]: pickWs("avgNetQuantityPerShopWs", "avgNetQuantityPerShopWsWsa"),
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_CHANGE]: pickWs("avgNetQuantityPerShopWsChange", "avgNetQuantityPerShopWsWsaChange"),
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_CHANGE_PERCENTAGE]: pickWs("avgNetQuantityPerShopWsChangePercentage", "avgNetQuantityPerShopWsWsaChangePercentage"),
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_LAST_YEAR]: pickWs("avgNetQuantityPerShopWsLastYear", "avgNetQuantityPerShopWsWsaLastYear"),

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO]: "avgNetQuantityPerShopWso",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO_CHANGE]: "avgNetQuantityPerShopWsoChange",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO_CHANGE_PERCENTAGE]: "avgNetQuantityPerShopWsoChangePercentage",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO_LAST_YEAR]: "avgNetQuantityPerShopWsoLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA]: "avgNetQuantityPerShopWsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA_CHANGE]: "avgNetQuantityPerShopWsaChange",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA_CHANGE_PERCENTAGE]: "avgNetQuantityPerShopWsaChangePercentage",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA_LAST_YEAR]: "avgNetQuantityPerShopWsaLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO]: "avgNetQuantityPerShopWsWsoWsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_CHANGE]: "avgNetQuantityPerShopWsWsoWsaChange",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_CHANGE_PERCENTAGE]: "avgNetQuantityPerShopWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_LAST_YEAR]: "avgNetQuantityPerShopWsWsoWsaLastYear",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA]: "avgNetQuantityPerShopWsWsoWsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE]: "avgNetQuantityPerShopWsWsoWsaChange",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_CHANGE_PERCENTAGE]: "avgNetQuantityPerShopWsWsoWsaChangePercentage",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA_LAST_YEAR]: "avgNetQuantityPerShopWsWsoWsaLastYear"
};

export const valueByDataType = (dataType: ChartDataTypes, dataObject: any) => {
  if (!(dataType in keysMap)) return null;
  const key = keysMap[dataType];
  return key in dataObject ? dataObject[key] : null;
};
